<template>
  <b-modal
    id="add-edit-gallery-category"
    size="xl"
    centered
    @hide="onHideModal"
    no-close-on-backdrop
    title="Add/Edit Category"
  >
    <form id="add-edit-gallery-category-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Gallery name (Thai)
            <input type="text" class="form-control" v-model="form.title.th" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Gallery name (Eng)
            <input type="text" class="form-control" v-model="form.title.en" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-4">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th width="80">Sort</th>
                  <th>Images</th>
                  <th>Last Update</th>
                  <th>Published</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(img, index) in form.images" :key="index">
                  <td>
                    <b-form-select
                      name
                      id
                      :value="index + 1"
                      @change="(value) => sortingFiles(value, index, img)"
                    >
                      <option
                        v-for="i in form.images.length"
                        :value="i"
                        :key="i"
                      >
                        {{ i }}
                      </option>
                    </b-form-select>
                  </td>
                  <td>
                    <ImagesUpload
                      :images="[img]"
                      :className="'wrapper-gallery-upload'"
                      :requiredName="false"
                    />
                  </td>
                  <td>{{ renderDate(img.updatedAt) }}</td>
                  <td>
                    <toggle-button
                      :labels="true"
                      :value="getPublished(img)"
                      @change="(value) => onChangeStatus(value, index, img)"
                      color="#0074d9"
                    />
                  </td>
                  <td>
                    <b-btn
                      size="sm"
                      variant="outline-danger"
                      class="btn-group-space"
                      v-on:click="deleteImage(index)"
                      >Delete</b-btn
                    >
                  </td>
                </tr>
                <tr v-if="form.images.length === 0">
                  <td colspan="5">
                    <div class="text-center">
                      <i>Empty data.</i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper-btn-primary-upload">
            <FilesUpload
              :multiple="true"
              :title="'Upload Files'"
              :instantUpload="true"
              :onFileUploaded="onUploaded"
              :files="newFile"
              :className="'wrapper-files file-upload-gallery'"
              :allowImagePreview="false"
            />
          </div>
          <p class="col-12 text-center">Picture Gallery size 1920x1080 pixel</p>
        </div>
      </div>
    </form>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="add-edit-gallery-category-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import format from 'date-fns/format'
import ImagesUpload from '../ImagesUpload'
import FilesUpload from '../FilesUpload'
export default {
  props: {
    form: Object,
    onUpdateGallery: Function,
    onAddGallery: Function,
    onFetchGalleryList: Function,
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    ImagesUpload,
    FilesUpload
  },
  data: () => ({
    newFile: [],
    newFileInterval: 0,
    tempFiles: [],
    firstTiggleUpload: true
  }),
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : '-'
    },
    getPublished (image) {
      return image.status ? image.status.toLowerCase() === 'active' : false
    },
    onChangeStatus (value, index, gallery) {
      this.form.images[index].status = value.value ? 'Active' : 'Inactive'
      this.form.images[index].updatedAt = this.renderDate(new Date())
      this.onUpdateGallery({ id: this.form.id, data: this.form })
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length - 1 : start + perPage
      const items = data.slice(start, end)
      return items
    },
    submit (e) {
      const data = this.form
      if (!data.id) {
        this.onAddGallery(data).then((resp) => {
          if (resp.success) {
            this.$bvModal.hide('add-edit-gallery-category')
            this.onFetchGalleryList()
          }
        })
      } else {
        this.onUpdateGallery({ id: data.id, data }).then((resp) => {
          if (resp.success) {
            this.$bvModal.hide('add-edit-gallery-category')
            this.onFetchGalleryList()
          }
        })
      }
    },
    deleteImage (index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.form.images = this.form.images.filter((img, i) => index !== i)
            this.newFile = []
          }
        })
    },
    onUploaded (data) {
      if (this.firstTiggleUpload) {
        this.onSetTempFiles()
        this.firstTiggleUpload = false
      }
      this.form.images = this.tempFiles.concat(data)
      clearInterval(this.newFileInterval)
      this.newFileInterval = setInterval(() => {
        if (data.length > 0) {
          if (this.form.images.length === data.length + this.tempFiles.length) {
            this.newFile = []
            this.onSetTempFiles()
            clearInterval(this.newFileInterval)
          }
        }
      }, 5000)
    },
    sortingFiles (newIndex, oldIndex, img) {
      this.loading(true)
      const data = []

      this.form.images.forEach((each, i) => {
        if (i !== oldIndex) {
          data.push(each)
        }
      })
      data.splice(newIndex - 1, 0, img)
      const newImg = data.map((each, i) => {
        return {
          ...each,
          orderNum: i + 1
        }
      })

      this.form.images = []

      setTimeout(() => {
        this.form.images = newImg
        this.loading(false)
      }, 50)
    },
    cancel () {
      this.onCancel()
      this.$bvModal.hide('add-edit-gallery-category')
    },
    onSetTempFiles () {
      this.tempFiles = JSON.parse(JSON.stringify(this.form.images))
    },
    onHideModal () {
      this.tempFiles = []
      this.firstTiggleUpload = true
    },
    getOrderNum (img, index) {
      return img.orderNum || index + 1
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}

.image-picker-group > .form-group {
  margin-left: 20px;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
</style>

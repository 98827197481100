<template>
  <b-modal
    id="form-select-edit"
    size="xl"
    centered
    title="Edit Select Option"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    @show="onShow"
  >
    <div class="container">
      <form id="form-select-edit-form" @submit.prevent="submit">
        <div v-for="(item, index) in options" :key="index" class="row">
          <div>
            <b-form-select
              v-model="item.ordering"
              :sync="true"
              @change="(to) => onChangeOrdering(index, to)"
            >
              <option
                v-for="order in options.length"
                :key="order"
                :value="order"
                class="d-block"
              >
                {{ order }}
              </option>
            </b-form-select>
          </div>
          <div class="col-sm-12 col-lg-4">
            <b-form-group label-cols-sm="2" label="Thai" label-align-sm="right">
              <div class="input-group">
                <b-form-input
                  v-model="item.translations.find(({ lang }) => lang === 'th').name"
                />
              </div>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-lg-4">
            <b-form-group label-cols-sm="2" label="English" label-align-sm="right">
              <div class="input-group">
                <b-form-input
                  v-model="item.translations.find(({ lang }) => lang === 'en').name"
                />
              </div>
            </b-form-group>
          </div>
          <div class="col-sm-12 text-sm-right col-lg-2">
            <b-button-group>
              <b-btn
                size="sm"
                variant="outline-danger"
                class="btn-group-space"
                @click="onDelete(index)"
              >
                Delete
              </b-btn>
            </b-button-group>
          </div>
        </div>
        <hr />
        <b-button
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="addOption"
        >
          Add List
        </b-button>
      </form>
    </div>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="form-select-edit-form"
      >
        Save
      </b-button>
      <b-button size="sm" variant="danger" @click="close">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ActionView } from '@/store/actionTypes'

export default {
  props: {
    refName: String,
    refId: [String, Number]
  },
  data () {
    return {
      options: []
    }
  },
  computed: {
    ...mapGetters('lead', ['formInput'])
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    ...mapActions('lead', ['updateRegisterForm', 'setFormInput', 'unsetFormInput', 'updateOptions']),
    onShow () {
      const { options } = this.formInput || {}

      this.options = [
        ...(options || []).map((o, index) => Object.assign(o, { index })).sort((a, b) => (a.ordering || 0) - (b.ordering || 0))
      ]

      this.options.forEach((option, index) => {
        if (!option.translations) {
          Object.assign(option, { translations: [], index })
        }

        ['th', 'en'].forEach((lang) => {
          const translation = option.translations.find((t) => t.lang === lang)
          if (!translation) {
            option.translations.push({
              lang,
              name: null
            })
          }
        })
      })
    },
    onChangeOrdering (index, to) {
      this.options.splice(to - 1, 0, this.options.splice(index, 1)[0])
      this.computeOrder()
    },
    onDelete (index) {
      this.options.splice(index, 1)
      this.computeOrder()
    },
    computeOrder () {
      this.options.forEach((option, index) => {
        Object.assign(option, { ordering: index + 1 })
      })
    },
    addOption () {
      this.options.push({
        translations: [
          { lang: 'th', name: null },
          { lang: 'en', name: null }
        ],
        ordering: this.options.length + 1
      })

      this.computeOrder()
    },
    async submit () {
      this.loading(true)
      if (!this.formInput.id) {
        const data = await this.updateRegisterForm({
          ref: this.refName,
          refId: this.refId,
          body: [{
            op: 'add',
            path: '/0',
            value: this.formInput
          }]
        })

        data.sort((a, b) => b.id - a.id)

        this.setFormInput(data[0])
      }

      const body = []

      body.push(
        ...this.options.filter(({ id }) => id).map((o) => ({
          op: 'replace',
          path: `/${this.formInput.options.find(({ id }) => o.id === id).index}`,
          value: {
            id: o.id,
            ordering: o.ordering,
            translations: o.translations
          }
        })),
        ...this.formInput.options
          .filter(({ id }) => !this.options.find((o) => o.id === id))
          .sort(({ index: a }, { index: b }) => a > b ? -1 : 1)
          .map((o) => ({
            op: 'remove',
            path: `/${o.index}`
          })),
        ...this.options.filter(({ id }) => !id).map((o) => ({
          op: 'add',
          path: '/0',
          value: {
            id: o.id,
            ordering: o.ordering,
            translations: o.translations
          }
        }))
      )

      await this.updateOptions({
        id: this.formInput.id,
        body
      })

      this.loading(false)
      this.$emit('saved')
      this.close()
    },
    close () {
      this.unsetFormInput()
      this.$bvModal.hide('form-select-edit')
    }
  }
}
</script>

<template>
  <div class="p-3">
    <b-form-group label="API Key" class="mb-4">
      <b-form-input
        v-model="form.apiKey"
        required
        placeholder="Input API Key"
      />
    </b-form-group>

    <b-tabs class="mt-3">
      <b-tab key="0" title="ภาษาไทย">
        <b-form class="p-3">
          <b-form-group
            label="Tag Title (This will be displayed in the title bar of your Browser.)"
          >
            <b-form-input
              v-model="form.title.th"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group
            label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
          >
            <b-form-input
              v-model="form.metaTitle.th"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.th"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.th"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <br />
          <label class="label"
            >*Basic Google Config for all pages in Website</label
          >
          <br />
        </b-form>
      </b-tab>
      <b-tab key="1" title="English">
        <b-form class="p-3">
          <b-form-group
            label="Tag Title (This will be displayed in the title bar of your Browser.)"
          >
            <b-form-input
              v-model="form.title.en"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group
            label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
          >
            <b-form-input
              v-model="form.metaTitle.en"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.en"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.en"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <br />
          <label class="label"
            >*Basic Google Config for all pages in Website</label
          >
          <br />
        </b-form>
      </b-tab>
    </b-tabs>
    <b-button variant="primary" v-on:click="update">Update</b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    form: {}
  }),
  methods: {
    ...mapActions({
      getConfig: ActionFetch.SEO_GOOGLE.GET_CONFIG,
      updateConfig: ActionFetch.SEO_GOOGLE.UPDATE_CONFIG
    }),
    loadConfig () {
      this.getConfig().then(($res) => {
        if ($res.success) {
          this.form = $res.data
        }
      })
    },
    update () {
      this.updateConfig(this.form).then(($res) => {
        if ($res.success) {
          this.loadConfig()
        }
      })
    }
  },
  created () {
    this.loadConfig()
  }
}
</script>

<style>
</style>

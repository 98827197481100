<template>
  <div id="app">
    <div
      :class="{
        [this.className]: true,
        'upload-error': uploadImageErrorRequired,
      }"
    >
      <div v-if="header" class="col-form-label">{{ header }}</div>
      <div v-for="(image, index) in images" :key="index">
        <ItemUpload
          name="image-upload"
          :title="title"
          :multiple="multiple"
          :acceptedFileTypes="acceptedFileTypes"
          :maxFileSize="maxFileSize"
          :files="image.source ? [image] : []"
          :onUploaded="onUploaded"
          :index="index"
          :onRemoveItemFile="onRemoveImage"
        />
        <label v-if="subject" class="label">{{ subject }}</label>
        <div v-if="showAlt" class="form-group form-group-row">
          Alt text
          <input
            class="form-control"
            placeholder="Descriptions of an image"
            :required="requiredName"
            type="text"
            ref="textAlt"
            :value="image.name"
            @change="textAltChange(index, $event.target.value)"
          />
        </div>
      </div>
      <b-button
        block
        variant="outline-primary"
        @click="addImage"
        v-if="enableAddRow"
        >+ Add</b-button
      >
      <div v-if="uploadImageErrorRequired" class="text-error">
        * Image(s) is required.
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import ItemUpload from './ItemUpload'

export default {
  props: {
    type: {
      type: String,
      default: 'image'
    },
    enableAddRow: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpg, image/jpeg, image/png'
    },
    maxFileSize: {
      type: String,
      default: '2MB'
    },
    header: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default:
        '<div class="image-input-btn large"><i class="fal fa-plus"></i></div>'
    },
    subject: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: 'image-uploader-container'
    },
    onAltChange: {
      type: Function,
      default: () => {}
    },
    requiredName: {
      type: Boolean,
      default: true
    },
    uploadImageErrorRequired: {
      type: Boolean,
      default: false
    },
    onRemoveImage: {
      type: Function,
      default: (index) => {}
    }
  },
  data: () => ({
    showAlt: true
  }),
  methods: {
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : '-'
    },
    textAltChange: function (index, value) {
      this.images[index].name = value
      this.images[index].updatedAt = this.renderDate(new Date())
    },
    onUploaded: function (index, data) {
      const d = this.renderDate(new Date())
      if (data.length > 0) {
        this.images[index].source = data[0].source
        this.images[index].updatedAt = d
      } else {
        this.images[index] = {
          source: null,
          updatedAt: d
        }
      }
    },
    addImage () {
      this.images.push({ source: null })
    }
  },
  components: {
    ItemUpload
  }
}
</script>
<style>
.filepond--drop-label label {
  font-size: 1.5em;
}
.image-uploader-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1.5em;
}
.text-error {
  color: red;
  margin-bottom: 15px;
}
.upload-error {
  border: 1px solid red;
}
</style>

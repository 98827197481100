<template>
  <div id="floor-category-table" class="mb-3">
    <div
      class="d-flex justify-content-between align-content-end align-items-center mb-3"
    >
      <h5 class="mb-0">Floor Plan List</h5>
      <b-button size="md" variant="primary" v-on:click="addEditFloor()"
        >Add New Item</b-button
      >
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Building</th>
            <th>Floor</th>
            <th>Last Update</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in floors" :key="index">
            <td>
              <b-form-select
                :value="item.orderNum"
                @change="
                  (value) => onChangeOrder(item.id, value, item.orderNum)
                "
              >
                <option v-for="i in floors.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>
              <span>{{
                item.buildingId.title && item.buildingId.title.en
              }}</span>
            </td>
            <td>
              <span>{{
                item.attributeId.title && item.attributeId.title.en
              }}</span>
            </td>
            <td>{{ renderDate(item.updatedAt) }}</td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  v-on:click="addEditFloor(item)"
                  class="btn-group-space"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  @click="deleteFloor(item.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="floors.length === 0">
            <td colspan="5">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <edit-floor-plan-modal
      :projectID="projectID"
      :attrBuildings="attrBuildings"
      :attrFloors="attrFloors"
      :onFetchFloorsList="onFetchFloorsList"
      :onUpdateFloors="onUpdateFloors"
      :onAddFloors="onAddFloors"
      :form="floor"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'
import EditFloorPlanModal from '../modals/EditFloorPlan.modal'

export default {
  props: {
    projectID: Number
  },
  components: {
    EditFloorPlanModal
  },
  data: () => ({
    attrBuildings: [],
    attrFloors: [],
    floors: [],
    floor: {}
  }),
  methods: {
    ...mapActions({
      getFloorsList: ActionFetch.PROJECTS.FLOORS.INDEX,
      onUpdateFloors: ActionFetch.PROJECTS.FLOORS.UPDATE,
      onDeleteFloors: ActionFetch.PROJECTS.FLOORS.DELETE,
      onAddFloors: ActionFetch.PROJECTS.FLOORS.ADD,
      onSortFloors: ActionFetch.PROJECTS.FLOORS.SORT,
      getAttributeList: ActionFetch.ATTRIBUTES.INDEX
    }),
    fetachAttrBuilding () {
      this.getAttributeList({
        moduleName: 'building',
        toStoreData: false
      }).then((resp) => {
        this.attrBuildings = resp.success && resp.data
      })
    },
    fetachAttrFloor () {
      this.getAttributeList({
        moduleName: 'floorPlan',
        toStoreData: false
      }).then((resp) => {
        this.attrFloors = resp.success && resp.data
      })
    },
    renderDate (date) {
      return date && date !== null ? format(date, 'YYYY-MM-DD') : '-'
    },
    onFetchFloorsList () {
      this.floors = []
      this.getFloorsList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.floors) {
          this.floors = this.$store.state.project.floors
        }
      })
    },
    handleFloorsList () {
      if (this.$store.state.project && this.$store.state.project.floors) {
        this.floors = this.$store.state.project.floors
      } else {
        this.onFetchFloorsList()
      }
    },
    buildingLabel (id) {
      const obj = this.attrBuildings.find((each) => each.id === id)
      return (obj && obj.title && obj.title.en) || '-'
    },
    floorsLabel (id) {
      const obj = this.attrFloors.find((each) => each.id === id)
      return (obj && obj.title && obj.title.en) || '-'
    },
    addEditFloor (data) {
      if (!data) {
        data = {
          projectId: this.projectID,
          buildingId: null,
          attributeId: null,
          images: {
            source: null,
            name: ''
          }
        }
      } else {
        data = {
          ...data,
          buildingId: data.buildingId.id,
          attributeId: data.attributeId.id
        }
      }
      this.floor = data
      this.fetachAttrFloor()
      this.$bvModal.show('edit-floor-plan')
    },
    deleteFloor (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteFloors(id).then((resp) => {
              if (resp.success) {
                this.onFetchFloorsList()
              }
            })
          }
        })
    },
    onChangeOrder (id, newValue, oldValue) {
      // this.$emit("update:data", []);
      this.onSortFloors({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.onFetchFloorsList()
        }
      })
    }
  },
  created () {
    this.fetachAttrBuilding()
    this.fetachAttrFloor()
    this.handleFloorsList()
  }
}
</script>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Advance Search</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <price-range
          :data="priceRangeList"
          :paginate="paginate"
          :onCreatePriceRange="createPriceRange"
          :onUpdatePriceRange="updatePriceRange"
          :onDeletePriceRange="deletePriceRange"
          :onPaginateChange="paginationChange"
          :onLimitChange="limitChange"
          :onUpdateSortPriceRange="updateSortPriceRange"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import PriceRangeTable from '@/components/tables/PriceRangeTable'

export default {
  components: {
    'price-range': PriceRangeTable
  },
  data: () => ({
    enable: true,
    priceRangeList: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.SEARCH_PRICE.LIST,
      createData: ActionFetch.SEARCH_PRICE.CREATE,
      updateData: ActionFetch.SEARCH_PRICE.UPDATE,
      updateSort: ActionFetch.SEARCH_PRICE.UPDATESORT,
      deleteData: ActionFetch.SEARCH_PRICE.DELETE
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchPriceRanges () {
      try {
        const condition = {
          orderBy: 'orderNum',
          direction: 'asc',
          limit: this.paginate.limit,
          page: this.paginate.page
        }

        this.getList(condition).then(($res) => {
          if ($res.success) {
            this.paginate.totalPage = $res.dataLimit
              ? $res.dataLimit.totalPage
              : 1
            this.paginate.totalRow = $res.dataLimit
              ? $res.dataLimit.totalData
              : 0

            if ($res.data) {
              this.priceRangeList = $res.data.map(($data) => {
                $data.status = $data.status.toLowerCase() === 'active'

                return $data
              })
            }
          }
        })
      } catch (error) {
        this.loading = false
      }
    },
    async createPriceRange ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'
      this.createData(dataSave).then(($res) => {
        this.fetchPriceRanges()
      })
    },
    async updatePriceRange ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'

      this.updateData(dataSave).then(($res) => {
        if ($res.success) {
          this.fetchPriceRanges()
        }
      })
    },
    async updateSortPriceRange ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchPriceRanges()
        }
      })
    },
    async deletePriceRange ($id) {
      this.deleteData($id).then(($res) => {
        if ($res.success) {
          this.fetchPriceRanges()
        }
      })
    },
    paginationChange ($paginate) {
      this.paginate.page = $paginate
      this.fetchPriceRanges()
    },
    limitChange ($value) {
      this.paginate.limit = $value

      this.fetchPriceRanges()
    }
  },
  created () {
    this.fetchPriceRanges()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

<template>
  <b-modal id="add-video-360" size="lg" centered title="Add/Edit 360">
    <form id="add-video-360-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Thai title
            <input
              type="text"
              :class="{
                'form-control': true,
                'has-error': this.$v.form.title.th.$error,
              }"
              v-model="form.title.th"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Eng title
            <input
              type="text"
              :class="{
                'form-control': true,
                'has-error': this.$v.form.title.en.$error,
              }"
              v-model="form.title.en"
            />
          </div>
        </div>
      </div>
      <div class="form-group form-group-row">
        URL Link
        <input
          type="text"
          :class="{
            'form-control': true,
            'has-error': this.$v.form.embedded.url.$error,
          }"
          v-model="form.embedded.url"
        />
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="add-video-360-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { required, url as validateUrl } from 'vuelidate/lib/validators'
export default {
  props: {
    form: {
      type: Object,
      default: () => ({
        title: {
          th: '',
          en: ''
        }
      })
    },
    onUpdatePanoramas: Function,
    onAddPanoramas: Function,
    handlePanoramasList: Function
  },
  data: () => ({
    modalName: 'add-video-360'
  }),
  validations: {
    form: {
      title: {
        th: {
          required
        },
        en: {
          required
        }
      },
      embedded: {
        url: {
          required,
          validateUrl
        }
      }
    }
  },
  methods: {
    submit (e) {
      this.$v.form.$touch()
      if (!this.$v.form.$error) {
        const data = this.form

        if (!data.id) {
          this.onAddPanoramas(data).then((resp) => {
            if (resp.success) {
              this.$bvModal.hide(this.modalName)
              this.handlePanoramasList()
              this.$v.form.$reset()
            }
          })
        } else {
          this.onUpdatePanoramas({ id: data.id, data }).then((resp) => {
            if (resp.success) {
              this.$bvModal.hide(this.modalName)
              this.handlePanoramasList()
              this.$v.form.$reset()
            }
          })
        }

        this.$bvModal.hide(this.modalName)
      }
    },
    cancel () {
      this.$bvModal.hide(this.modalName)
    }
  }
}
</script>

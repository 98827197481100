<template>
  <div>
    <div class="table-responsive mb-3">
      <div
        class="d-flex justify-content-between align-content-end align-items-center mb-3"
      >
        <h5 class="mb-0">Content List</h5>
        <b-button size="md" v-on:click="addEditContent()" variant="primary"
          >Add New Item</b-button
        >
      </div>
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Layout</th>
            <th>Content</th>
            <th>Images</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>
              <img :src="imageLayout(list.designLayout)" width="100px" alt />
            </td>
            <td>
              <div class="text-left" v-html="list.content.th"></div>
            </td>
            <td>
              <img :src="imageSource(list)" alt class="banner" />
            </td>
            <td>
              <toggle-button
                :value="getPublished(list)"
                @change="(value) => onChangeStatus(value, list)"
                :labels="true"
              />
            </td>
            <td>
              <b-button-group>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="btn-group-space"
                  v-on:click="addEditContent(index, list)"
                  >Edit</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="deleteContent(list.id)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="data.length === 0">
            <td colspan="7">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddConceptModal
      :projectID="projectID"
      :onAddContent="addContent"
      :onUpdateContent="updateContent"
      :data.sync="content"
      :onCancel="onCancelModal"
      :projectDataTemplate="projectDataTemplate"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'
import AddConceptModal from '../modals/AddConceptModal'
import { getDefaultValue } from '@/services/projectConcept.service'

export default {
  components: {
    AddConceptModal
  },
  props: {
    projectID: Number,
    projectDataTemplate: {
      type: String,
      default: '00'
    },
    data: {
      type: Array,
      default: () => []
    },
    onAddContent: Function,
    onUpdateContent: Function,
    onFetchContentList: Function,
    onDeleteContent: Function,
    onSorting: Function
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    // perPageOptions: [
    //   { value: 10, text: "10 / page" },
    //   { value: 20, text: "20 / page" },
    //   { value: 30, text: "30 / page" }
    // ],
    content: {},
    dataContentTemp: null,
    dataContentTempIndex: null
  }),
  methods: {
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)
      return items
    },
    imageSource (data) {
      const image = data.pcImages
      return `${
        /^http.+/.test(image.source) ? '' : process.env.VUE_APP_WEBSITE_ENDPOINT
      }${image.source}`
    },
    imageLayout (id) {
      return require(`@/assets/template-0${id}.svg`)
    },
    getPublished (data) {
      return data.status.toLowerCase() === 'active'
    },
    onChangeStatus (value, data) {
      data.status = value.value ? 'Active' : 'Inactive'
      this.updateContent(data.id, data)
    },
    updateContent (id, data) {
      this.onUpdateContent({ id, data }).then((resp) => {
        if (resp.success) {
          this.$bvModal.hide('add-concept')
          this.onFetchContentList()
          this.$bvToast.toast(resp.message, {
            title: 'Complete',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else {
          alert(resp)
        }
      })
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSorting({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.onFetchContentList()
        }
      })
    },
    deleteContent (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteContent(id).then((resp) => {
              if (resp.success) {
                this.onFetchContentList()
              }
            })
          }
        })
    },
    addContent (data) {
      this.onAddContent(data).then((resp) => {
        if (resp.success) {
          this.$bvModal.hide('add-concept')
          this.onFetchContentList()
          this.$bvToast.toast(resp.message, {
            title: 'Complete',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else {
          alert(resp)
        }
      })
    },
    addEditContent (index, data) {
      if (!data) {
        this.content = { ...getDefaultValue(this.projectID) }
      } else {
        this.dataContentTemp = JSON.parse(JSON.stringify(data))
        this.dataContentTempIndex = index
        this.content = data
      }
      this.$bvModal.show('add-concept')
    },
    onCancelModal () {
      this.data[this.dataContentTempIndex] = JSON.parse(
        JSON.stringify(this.dataContentTemp)
      )
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  },
  created () {
    this.content = getDefaultValue(this.projectID)
  }
}
</script>
<style>
p {
  color: #000000;
}
</style>

<template>
  <div>
    <div class="table-responsive">
      <div class="flex-row align-center justify-between">
        <h5 class="page-title" style="font-weight: 600">Inbox Message</h5>
        <div class="flex-row">
          <b-input
            type="text"
            class="searchbox"
            placeholder="Search by name , email, phone"
            @change="onUpdateSearchbox"
          ></b-input>
        </div>
      </div>

      <br />
      <table class="table">
        <thead>
          <tr>
            <th width="100">Date</th>
            <th width="150">Name</th>
            <th width="200">Email</th>
            <th width="150">Phone</th>
            <th width="150">Subject</th>
            <th width="150">Message</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <p class="start_stop_date">{{ renderDate(list.createdAt) }}</p>
            </td>
            <td>{{ list.name }} {{ list.surname }}</td>
            <td>{{ list.email }}</td>
            <td>{{ list.phone }}</td>
            <td>{{ list.subject }}</td>
            <td width="150" class="text-wrap">
              <div class="text-truncate" style="width: 150px">
                {{ list.message }}
              </div>
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="primary"
                  @click="view(list.id)"
                  class="mx-1"
                  >View</b-btn
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    onUpdateSearchbox: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    }
  },
  components: {},
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ]
  }),
  methods: {
    view (id) {
      this.$router.push(`/contact/message/${id}/view`)
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      return this.data
    },
    DeleteUser (id, index) {
      if (confirm('Are you sure?')) {
      }
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#table-pagination .pagination {
  margin-right: 10px;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

.form-control:focus {
  box-shadow: 0 0 0 0;
}

.searchbox {
  min-width: 300px;
}
</style>

<template>
  <b-modal
    id="add-concept"
    size="xl"
    centered
    no-close-on-backdrop
    @hide="onHideModal"
    @show="onShowModal"
    @shown="onShownModal"
    @hidden="onHiddenModal"
    title="Add/Edit Content"
  >
    <form id="add-concept-form" @submit.prevent="submit">
      <p class="col-form-label">Design Layout</p>
      <br />
      <div class="row" style="text-align: left">
        <div class="col-md-3">
          <div class="template-item">
            <input type="radio" value="1" v-model="data.designLayout" />
            <img src="./../../assets/template-01.svg" alt />
          </div>
        </div>
        <div class="col-md-3">
          <div class="template-item">
            <input type="radio" value="2" v-model="data.designLayout" />
            <img src="./../../assets/template-02.svg" width="70%" alt />
          </div>
        </div>
        <div class="col-md-3">
          <div class="template-item">
            <input type="radio" value="3" v-model="data.designLayout" />
            <img
              src="./../../assets/template-03.svg"
              width="70%"
              v-b-tooltip.hover
              alt
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="template-item">
            <input type="radio" value="4" v-model="data.designLayout" />
            <img src="./../../assets/template-04.svg" width="70%" alt />
          </div>
        </div>
      </div>
      <br />
      <div class="container">
        <div class="row">
          <div :class="['container-content', 'col-md-12', this.layoutClass]">
            <div class="row">
              <div :class="['wrapper-image', 'top', this.layoutGridClass(4)]">
                <!-- {{ data.pcImages }}
                <ImagesUpload class="upload-img" :multiple="false" :requiredName="false" :images="[data.pcImages]" size="lg" :uploadImageErrorRequired="uploadImageError"></ImagesUpload> -->

                <ImageUploadNew
                  :dataImage="{ ...data.pcImages }"
                  :uploadSetting="{ ...uploadSetting }"
                  :isRequired="false"
                  :showError="uploadImageError"
                  :maxFile="1"
                  :onFileUploaded="onFileUploaded"
                />
                <small>
                  Project image size:
                  <span>{{ labelImgUpload }}</span>
                </small>
              </div>
              <div :class="['wrapper-content', this.layoutGridClass(8)]">
                <b-tabs>
                  <b-tab
                    title="ภาษาไทย"
                    @click="tab = 'th'"
                    :active="tab === 'th'"
                  >
                    <div class="content-editor">
                      <TextEditor
                        :modalShown="modalShown"
                        :modal="modal"
                        :content.sync="data.content.th"
                        :onUpdateInfo="onUpdateContentTH"
                      />
                    </div>
                  </b-tab>
                  <b-tab
                    title="English"
                    @click="tab = 'en'"
                    :active="tab === 'en'"
                  >
                    <div class="content-editor">
                      <TextEditor
                        :modalShown="modalShown"
                        :modal="modal"
                        :content.sync="data.content.en"
                        :onUpdateInfo="onUpdateContentEN"
                      />
                    </div>
                  </b-tab>
                  <div v-if="textAreaError" class="text-error">
                    * Content th/en is required.
                  </div>
                </b-tabs>
              </div>
              <div
                :class="['wrapper-image', 'bottom', this.layoutGridClass(4)]"
              >
                <!-- <ImagesUpload class="upload-img" :multiple="false" :requiredName="false" :images="[data.pcImages]" size="lg" :uploadImageErrorRequired="uploadImageError"></ImagesUpload> -->
                <ImageUploadNew
                  :dataImage="{ ...data.pcImages }"
                  :uploadSetting="{ ...uploadSetting }"
                  :isRequired="false"
                  :maxFile="1"
                  :onFileUploaded="onFileUploaded"
                />
                <small>
                  Project image size:
                  <span>{{ labelImgUpload }}</span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        type="button"
        @click="submit"
        form="add-concept-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="closeModal">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
import froalaConfig from '@/services/froalaConfig'
import TextEditor from '../text-editor/TextEditor'
export default {
  components: {
    TextEditor,
    ImageUploadNew
  },
  props: {
    projectID: Number,
    projectDataTemplate: String,
    data: {
      type: Object,
      default: () => ({
        projectId: 0,
        title: {
          th: '',
          en: ''
        },
        content: {
          th: null,
          en: ''
        },
        designLayout: 1,
        pcImages: { source: null },
        mobileImages: { source: null },
        visitor: null,
        status: 'Active',
        orderNum: 0
      })
    },
    onAddContent: Function,
    onUpdateContent: Function,
    onCancel: Function
  },
  data: () => ({
    editor_mode: 'editor',
    tab: 'th',
    uploadImageError: false,
    textAreaError: false,
    editorConfig: froalaConfig,
    dataContentTmp: null,
    content: {
      th: '',
      en: ''
    },
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    modalShown: false,
    modal: true
  }),
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    onShownModal () {
      this.modalShown = true
    },
    onHiddenModal () {
      this.modalShown = false
    },
    setLayout (index) {
      this.data.designLayout = index
    },
    onUpdateContentTH ($value) {
      this.data.content.th = $value
    },
    onUpdateContentEN ($value) {
      this.data.content.en = $value
    },
    onFileUploaded ($value) {
      this.data.pcImages = $value
    },
    layoutGridClass (size) {
      return Number(this.data.designLayout) === 1 ||
        Number(this.data.designLayout) === 2
        ? `col-md-${size}`
        : 'col-md-12'
    },
    submit () {
      this.uploadImageError = false
      this.textAreaError = false

      if (!this.data.pcImages.source) {
        this.uploadImageError = true
      }
      if (this.data.content.th === '' || this.data.content.en === '') {
        this.textAreaError = true
      }
      if (this.uploadImageError || this.textAreaError) {
        return
      }

      if (this.data.id) {
        this.onUpdateContent(this.data.id, this.data)
      } else {
        this.onAddContent(this.data)
      }
    },
    onHideModal () {
      this.uploadImageError = false
      this.textAreaError = false
    },
    closeModal () {
      this.onCancel()
      this.$bvModal.hide('add-concept')
    },
    onShowModal () {
      this.loading(true)
    }
  },
  computed: {
    layoutClass () {
      return `theme-${this.data.designLayout}`
    },
    labelImgUpload () {
      let label = '-'
      switch (this.projectDataTemplate) {
        case 't01':
          label = 'SENSE OF FREEDOM (Blue T01) : Size (800x600)pixel'
          break
        case 't02':
          label = 'SENSE OF BEGINNING (Denim T02) : Size(750x500)pixel'
          break
        case 't03':
          label = 'SENSE OF DELIGHT (Ciela & Lapis T03) : Size(950x700)pixel'
          break
        case 't04':
        case 't05':
          label =
            'SENSE OF FULFILMENT (Mazarine & Kara T04 T05) : Size(1920x865)(800x600)pixel'
          break
        case 't06':
          label = 'SENSE OF ACHIEVEMENT (Anil) : Size(1920x865)(800x800)pixel'
          break
        default:
          break
      }
      return label
    }
  }
}
</script>

<style scoped lang="scss">
.template-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.template-item > img {
  width: 100px;
  height: 80px;
  margin-top: 20px;
  border: 1px solid #666;
}
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
.layout-card.active {
  border-color: green;
}
.layout-card.active .btn {
  background-color: #2699fb;
}
.content-editor {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-top: none;
}
.container-content {
  border: 1px dashed rgba(0, 0, 0, 0.2);
  padding: 15px;
  background-color: rgba(230, 233, 238, 0.3);
  border-radius: 5px;
}
.theme-2 .wrapper-image.top,
.theme-1 .wrapper-image.bottom,
.theme-3 .wrapper-image.bottom,
.theme-4 .wrapper-image.top {
  display: none;
}
.wrapper-image,
.wrapper-content {
  margin-bottom: 15px;
}
.upload-img .filepond--drop-label {
  background-color: red;
}
.error {
  color: red;
}
</style>

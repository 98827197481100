<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Contact</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="flex-row align-center justify-between">
          <div class="flex-col" style="flex: 1">
            <h5 class="page-title">Inbox Message</h5>
          </div>
        </div>

        <hr />

        <div class="col-8 left-label">
          <b-form inline class="mb-3">
            <label class="col-2">Name</label>
            <b-form-input
              class="col-8"
              type="text"
              :value="form.name"
              disabled
            ></b-form-input>
          </b-form>
          <b-form inline class="mb-3">
            <label class="col-2">Lastname</label>
            <b-form-input
              class="col-8"
              type="text"
              :value="form.surname"
              disabled
            ></b-form-input>
          </b-form>
          <b-form inline class="mb-3">
            <label class="col-2">Email</label>
            <b-form-input
              class="col-8"
              type="text"
              :value="form.email"
              disabled
            ></b-form-input>
          </b-form>
          <b-form inline class="mb-3">
            <label class="col-2">Phone</label>
            <b-form-input
              class="col-8"
              type="text"
              :value="form.phone"
              disabled
            ></b-form-input>
          </b-form>
          <b-form inline class="mb-3">
            <label class="col-2">Subject</label>
            <b-form-input
              class="col-8"
              type="text"
              :value="form.subject"
              disabled
            ></b-form-input>
          </b-form>
          <b-form inline class="mb-3">
            <label class="col-2">Message</label>
            <b-form-textarea
              class="col-8"
              type="text"
              rows="5"
              disabled
              :value="form.message"
            ></b-form-textarea>
          </b-form>
        </div>

        <hr />

        <b-btn size="sm" variant="primary" class="mx-1" @click="goBack"
          >Back</b-btn
        >
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    form: {}
  }),
  methods: {
    ...mapActions({
      getMailInfo: ActionFetch.CONFIG.MESSAGE.DETAIL
    }),
    fetchMailInfo () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getMailInfo(params).then(($res) => {
          if ($res.success) {
            this.form = $res.data
          }
        })
      }
    },
    goBack () {
      this.$router.push(`/contact/message`)
    }
  },
  created () {
    this.fetchMailInfo()
  }
}
</script>
<style>
.left-label label {
  text-align: left;
  display: block;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-banner","size":"xl","centered":"","no-close-on-backdrop":"","title":"Add/Edit Banner List"},on:{"close":_vm.onHideModal}},[_c('form',{attrs:{"id":"add-banner-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group form-group-row"},[_c('span',{staticClass:"pr-2"},[_vm._v("Date Start Slide")]),_c('date-picker',{class:{
              'date-picker': true,
              'date-required': _vm.requiredStartDate,
            },attrs:{"lang":"en","disabledDays":_vm.disableStartDate,"placeholder":"Select Date"},model:{value:(_vm.data.start_date),callback:function ($$v) {_vm.$set(_vm.data, "start_date", $$v)},expression:"data.start_date"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group form-group-row"},[_c('span',{staticClass:"pr-2"},[_vm._v("Date Stop Slide")]),_c('date-picker',{class:{
              'date-picker': true,
              'date-required': _vm.requiredEndDate,
            },attrs:{"lang":"en","disabledDays":_vm.disableEndDate,"placeholder":"Select Date"},model:{value:(_vm.data.end_date),callback:function ($$v) {_vm.$set(_vm.data, "end_date", $$v)},expression:"data.end_date"}})],1)])]),_c('hr'),_c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"col-md-6"},[_c('ImageUploadNew',{attrs:{"uploadSetting":Object.assign({}, _vm.uploadSetting),"dataImage":Object.assign({}, _vm.imagePC),"showError":_vm.uploadImgPcError,"isRequired":false,"maxFile":1,"subject":'Picture Slide (PC) (1920 x 800 Pixel)',"onFileUploaded":_vm.onFileUploadPcImage}})],1),_c('div',{staticClass:"col-md-6"},[_c('ImageUploadNew',{attrs:{"uploadSetting":Object.assign({}, _vm.uploadSetting),"dataImage":Object.assign({}, _vm.imageMobile),"showError":_vm.uploadImgMobileError,"isRequired":false,"maxFile":1,"subject":'Picture Slide (Mobile) (800 x 800 Pixel)',"onFileUploaded":_vm.onFileUploadMobileImage}})],1)]),_c('hr'),_c('h6',{staticClass:"pb-3"},[_vm._v("Please fill link for click to")]),_c('div',{staticClass:"flex-row align-center"},[_c('div',{staticClass:"checkbox-wrapper"},[_c('p-check',{staticClass:"p-icon p-smooth p-bigger",staticStyle:{"margin-right":"20px"},attrs:{"name":"check","color":"primary","checked":_vm.data.link_type === 'url'},on:{"change":function (e) { return _vm.swithType(e, 'url'); }}},[_c('i',{staticClass:"icon fa fa-check",attrs:{"slot":"extra"},slot:"extra"}),_vm._v(" URL Link ")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.url),expression:"data.url"}],class:{
          'form-controlsss': true,
          'form-control': true,
          'has-error': !_vm.urlValidator.url,
        },attrs:{"type":"text","disabled":_vm.data.link_type !== 'url',"placeholder":"Example - https://www.grandunity.co.th/directory"},domProps:{"value":(_vm.data.url)},on:{"change":function (e) { return _vm.onvalidateUrl(e.target.value); },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "url", $event.target.value)}}})]),_c('br'),_c('div',{staticClass:"flex-row align-center"},[_c('div',{staticClass:"checkbox-wrapper"},[_c('p-check',{staticClass:"p-icon p-smooth p-bigger",staticStyle:{"margin-right":"20px"},attrs:{"name":"check","checked":_vm.data.link_type === 'youtube',"color":"primary"},on:{"change":function (e) { return _vm.swithType(e, 'youtube'); }}},[_c('i',{staticClass:"icon fa fa-check",attrs:{"slot":"extra"},slot:"extra"}),_vm._v(" Youtube Link ")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.youtube),expression:"data.youtube"}],class:{
          'form-control': true,
          'has-error': !_vm.urlValidator.youtube,
        },attrs:{"type":"text","disabled":_vm.data.link_type !== 'youtube',"placeholder":"Example - https://www.youtube.com/path"},domProps:{"value":(_vm.data.youtube)},on:{"change":function (e) { return _vm.onValidateYoutube(e.target.value); },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "youtube", $event.target.value)}}})])]),_c('template',{slot:"modal-footer"},[_c('b-button',{attrs:{"size":"sm","variant":"primary","type":"submit","form":"add-banner-form"}},[_vm._v("Save")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.onHideModal()}}},[_vm._v("Cancel")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="register-setting-page">
    <div class="page-name">
      <span class="page-title">Contact</span>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <h5 class="mb-3">Set receiver email</h5>
        <hr />
        <config-receiver-email />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import ConfigReceiverEmail from '@/components/contact-config-form/ConfigReceiverEmail'

export default {
  components: {
    ConfigReceiverEmail
  },
  data: () => ({})
}
</script>
<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

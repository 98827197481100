<template>
  <div>
    <div
      class="d-flex justify-content-between align-content-end align-items-center mb-3"
    >
      <h5 class="mb-0">Create Gallery Category</h5>
      <b-button size="md" v-on:click="addEditGallery()" variant="primary"
        >Add New Item</b-button
      >
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Gallery Category</th>
            <th>Last Update</th>
            <th>Amount</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in data" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>{{ list.title.en }}</td>
            <td>{{ renderDate(list.createdAt) }}</td>
            <td>{{ list.images.length }}</td>
            <td>
              <toggle-button
                :labels="true"
                :value="getPublished(list)"
                @change="(value) => onChangeStatus(value, list)"
                color="#0074d9"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  v-on:click="addEditGallery(index, list)"
                  variant="primary"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  v-on:click="deleteGallery(list.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="data.length === 0">
            <td colspan="6">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddEditGalleryCategory
      v-if="form"
      :form="form"
      :onUpdateGallery="onUpdateGallery"
      :onAddGallery="onAddGallery"
      :onFetchGalleryList="onFetchGalleryList"
      :onSorting="onSorting"
      :onCancel="onCancelModal"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'

import AddEditGalleryCategory from '../modals/AddEditGalleryCategory.modal'

export default {
  props: {
    projectID: Number,
    data: Array,
    onUpdateGallery: Function,
    onAddGallery: Function,
    onDeleteGallery: Function,
    onFetchGalleryList: Function,
    onSorting: Function
  },
  components: {
    AddEditGalleryCategory
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    form: null,
    tempForm: null,
    idTempRow: null
  }),
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.form = {
        projectId: this.projectID,
        title: {
          th: '',
          en: ''
        },
        images: [],
        visitor: null,
        status: 'Active'
      }
      this.setTempData()
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)
      return items
    },
    getPublished (gallery) {
      return gallery.status.toLowerCase() === 'active'
    },
    onChangeStatus (value, gallery) {
      gallery.status = value.value ? 'Active' : 'Inactive'
      this.onUpdateGallery({ id: gallery.id, data: gallery })
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSorting({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.onFetchGalleryList()
        }
      })
    },
    addEditGallery (index, data) {
      if (data) {
        this.form = JSON.parse(JSON.stringify(data))
        this.idTempRow = index
        this.setTempData()
      } else {
        this.initForm()
      }
      this.$bvModal.show('add-edit-gallery-category')
    },
    deleteGallery (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteGallery(id).then((resp) => {
              if (resp.success) {
                this.onFetchGalleryList()
              }
            })
          }
        })
    },
    setTempData () {
      this.tempForm = JSON.parse(JSON.stringify(this.form))
    },
    onCancelModal () {
      this.data[this.idTempRow] = JSON.parse(JSON.stringify(this.tempForm))
      this.$emit('update:data', this.data)
    }
  }
}
</script>

<style>
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

/* th {
  text-align: center;
}
td {
  text-align: center;
} */
</style>

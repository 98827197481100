<template>
  <div>
    <div class="flex-row align-center justify-between">
      <h5 class="my-3">Create Progress List</h5>
      <div class="flex-row">
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          v-on:click="addEditProgress()"
          >Add New List</b-btn
        >
      </div>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Year/Month</th>
            <th>Last Update</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in progressList" :key="index">
            <td>{{ list.year }} / {{ list.month }}</td>
            <!-- <td>{{ `${list.progress}%` }}</td> -->
            <td>{{ renderDate((i = list.updatedAt)) }}</td>
            <td>
              <toggle-button
                :labels="true"
                :value="getPublished(list)"
                @change="(value) => onChangeStatus(value, list)"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="primary"
                  class="btn-group-space"
                  v-on:click="addEditProgress(list)"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="deleteProgress(list.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="progressList.length === 0">
            <td colspan="4">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddProgress
      :projectID="projectID"
      :attrBuildings="attrBuildings"
      :attrProgress="attrProgress"
      :onFetchProgressList="onFetchProgressList"
      :onUpdateProgress="onUpdateProgress"
      :onAddProgress="onAddProgress"
      :form.sync="progress"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'
import AddProgress from '../modals/AddProgress.modal'

export default {
  props: {
    projectID: Number
  },
  components: {
    AddProgress
  },
  data: () => ({
    progressList: [],
    attrBuildings: [],
    attrProgress: [],
    progress: {
      projectId: 0,
      buildingId: 0,
      year: new Date().getFullYear(),
      month: null,
      content: []
    }
  }),
  methods: {
    ...mapActions({
      getProgressList: ActionFetch.PROJECTS.PROGRESS.INDEX,
      onUpdateProgress: ActionFetch.PROJECTS.PROGRESS.UPDATE,
      onDeleteProgress: ActionFetch.PROJECTS.PROGRESS.DELETE,
      onAddProgress: ActionFetch.PROJECTS.PROGRESS.ADD,
      getAttributeList: ActionFetch.ATTRIBUTES.INDEX
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    onFetchProgressList (activeID) {
      this.getProgressList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.progress) {
          this.progressList = this.$store.state.project.progress

          if (activeID) {
            this.progress = this.progressList.find(
              (each) => each.id === activeID
            )
          }
        }
      })
    },
    handleProgressList () {
      if (this.$store.state.project && this.$store.state.project.progress) {
        this.progressList = this.$store.state.project.progress
      } else {
        this.onFetchProgressList()
      }
    },
    getPublished (data) {
      return data.status.toLowerCase() === 'active'
    },
    onChangeStatus (value, data) {
      data.status = value.value ? 'Active' : 'Inactive'
      this.onUpdateProgress({
        id: data.id,
        data: {
          ...data,
          buildingId: data.buildingId.id
        }
      })
    },
    fetachAttrBuilding () {
      this.getAttributeList({
        moduleName: 'building',
        toStoreData: false
      }).then((resp) => {
        this.attrBuildings = resp.success && resp.data
      })
    },
    fetachAttrProgress () {
      this.getAttributeList({
        moduleName: 'progress',
        toStoreData: false
      }).then((resp) => {
        this.attrProgress = resp.success && resp.data
      })
    },
    addEditProgress (data) {
      if (!data) {
        data = {
          projectId: this.projectID,
          buildingId: null,
          year: null,
          month: null,
          content: [
            {
              attributeId: Number(process.env.VUE_APP_OVERALL_PROGRESS_ID),
              percent: 0,
              status: 'Active'
            }
          ],
          images: []
        }
      } else {
        data = {
          ...data,
          buildingId: data.buildingId.id
        }
      }
      this.progress = data
      this.$bvModal.show('add-progress')
    },
    deleteProgress (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteProgress(id).then((resp) => {
              if (resp.success) {
                this.onFetchProgressList()
              }
            })
          }
        })
    }
  },
  created () {
    this.fetachAttrBuilding()
    this.fetachAttrProgress()
    this.handleProgressList()
  }
}
</script>

<style>
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <span class="table-title">Register Setting</span>
    </div>
    <div class="page-container">
      <b-card>
        <div class="form-group form-group-row">
          <p class="mr-2">
            Campaign :
          </p>
          <div class="col-8 col-lg-4 col-xl-4 custom-auto-dropdown">
            <b-form-select
              v-model="selected"
              :options="campaigns"
              value-field="id"
              text-field="name.th"
              class="flex-fill"
              @change="reloadRegisterForm"
            />
          </div>
        </div>
        <hr />
        <div>
          <div v-if="campaign" class="col-12 col-lg-8 col-xl-8">
            <div class="pb-3">
              Enable / Disable Register section in Page:
              <toggle-button
                v-model="campaign.showRegister"
                color="#0074d9"
                :sync="true"
                :labels="true"
                @change="onChangeRegisterStatus"
              />
            </div>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th>Form List</th>
                <th>Required</th>
                <th>Show/Hide</th>
                <th/>
              </tr>
            </thead>
            <tbody v-if="form.length">
              <tr v-for="(item, index) in masters" :key="index">
                <td>{{ item | masterLabel }}</td>
                <td>
                  <toggle-button
                    v-if="item.requirable !== false"
                    v-model="form.find(({ masterId }) => masterId === item.id).required"
                    :sync="true"
                    :labels="true"
                    @change="({ value }) => onChangeRegisterForm(item, 'required', value)"
                  />
                </td>
                <td>
                  <toggle-button
                    v-model="form.find(({ masterId }) => masterId === item.id).published"
                    :sync="true"
                    :labels="true"
                    @change="({ value }) => onChangeRegisterForm(item, 'published', value)"
                  />
                </td>
                <td style="text-align: center">
                  <b-btn
                    v-if="item.editable"
                    size="sm"
                    variant="outline-info"
                    class="btn-group-space"
                    @click="editOptions(form.find(({ masterId }) => masterId === item.id))"
                  >
                    Edit
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
      <br />
    </div>
    <FormSelectEditModal
      v-if="campaign"
      ref-name="campaign"
      :ref-id="campaign.id"
      @saved="reloadRegisterForm"
    />
    <RegisterSettingEditor
      v-if="campaign"
      ref-name="campaign"
      :ref-id="campaign.id"
      @saved="reloadRegisterForm"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import FormSelectEditModal from '@/components/modals/FormSelectEdit.modal'
import RegisterSettingEditor from '@/components/modals/RegisterSettingEditor.modal'

export default {
  components: {
    FormSelectEditModal,
    RegisterSettingEditor
  },
  filters: {
    masterLabel (master) {
      if (!master || !master.translations || master.translations.length === 0) {
        return null
      }

      return master.translations.map(({ name }) => name).join(' / ')
    }
  },
  data: () => ({
    selected: null,
    registerForm: [],
    form: []
  }),
  computed: {
    ...mapGetters('lead', ['masters']),
    ...mapGetters('campaign', ['campaigns']),
    campaign () {
      return this.campaigns.find(({ id }) => this.selected === id)
    }
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    ...mapActions('lead', ['getMasters', 'getRegisterForm', 'updateRegisterForm', 'setFormInput']),
    ...mapActions('campaign', ['getCampaigns', 'updateProjectField']),
    setDefaultForm () {
      this.form = this.masters.map(({ id, type }) => {
        const form = {
          masterId: id,
          type,
          required: false,
          published: false
        }

        if (['dropdown', 'checkbox', 'radio'].includes(type)) {
          form.options = []
        }

        return form
      })

      // console.log({ defaultForm: this.form })
    },
    async reloadRegisterForm () {
      this.loading(true)

      this.setDefaultForm()

      this.registerForm = await this.getRegisterForm({
        ref: 'campaigns',
        refId: this.selected
      })

      this.registerForm.forEach((data) => {
        const form = this.form.find((f) => f.masterId === data.masterId)

        if (!form) {
          return
        }

        Object.assign(form, data)
      })

      this.loading(false)
    },
    async onChangeRegisterStatus () {
      this.loading(true)

      await this.updateProjectField({
        id: this.selected,
        field: 'showRegister',
        value: this.campaign.showRegister
      })

      this.loading(false)
    },
    async onChangeRegisterForm (master, field, value) {
      this.loading(true)

      const form = this.form.find(({ masterId }) => masterId === master.id)
      const index = this.registerForm.findIndex(({ masterId }) => masterId === master.id)
      // console.log({ master, form })

      await this.updateRegisterForm({
        ref: 'campaigns',
        refId: this.selected,
        body: [{
          op: form.id ? 'replace' : 'add',
          path: form.id ? `/${index}/${field}` : '/0',
          value: form.id ? value : {
            masterId: master.id,
            [field]: value
          }
        }]
      })

      this.reloadRegisterForm()

      this.loading(false)
    },
    editOptions (input) {
      this.setFormInput(input)
      this.$bvModal.show(
        input.type === 'editor' ? 'register-setting-editor' : 'form-select-edit'
      )
    }
  },
  async mounted () {
    this.loading(true)

    if (this.masters.length === 0) {
      await this.getMasters()
    }

    this.setDefaultForm()

    await this.getCampaigns()
    this.selected = this.campaigns.length ? this.campaigns[0].id : null
    this.reloadRegisterForm()

    this.loading(false)
  }
}
</script>

export const templates = [
  {
    id: 't01',
    name: 'Project U Condo',
    url: '/img/project-template/blue.jpg',
    primaryColorTheme: '31,55,116',
    secondaryTheme: '255,255,255',
    subColorTheme1: '0,0,0',
    subColorTheme2: null,
    subColorTheme3: null,
    subColorTheme4: null
  },
  {
    id: 't02',
    name: 'Project Denim',
    url: '/img/project-template/denim.jpg',
    primaryColorTheme: '0,62,106',
    secondaryTheme: '255,255,255',
    subColorTheme1: '0,0,0',
    subColorTheme2: null,
    subColorTheme3: null,
    subColorTheme4: null
  },
  {
    id: 't03',
    name: 'Project Ciela',
    url: '/img/project-template/ciela.jpg',
    primaryColorTheme: '106,160,184',
    secondaryTheme: '255,255,255',
    subColorTheme1: '0,0,0',
    subColorTheme2: null,
    subColorTheme3: null,
    subColorTheme4: null
  },
  {
    id: 't04',
    name: 'Project Mazarine',
    url: '/img/project-template/mazarine.jpg',
    primaryColorTheme: '0,35,47',
    secondaryTheme: '255,255,255',
    subColorTheme1: '0,0,0',
    subColorTheme2: null,
    subColorTheme3: null,
    subColorTheme4: null
  },
  {
    id: 't05',
    name: 'Project Kara',
    url: '/img/project-template/kara.jpg',
    primaryColorTheme: '31,79,110',
    secondaryTheme: '255,255,255',
    subColorTheme1: '160,160,160',
    subColorTheme2: '100,100,100',
    subColorTheme3: '64,64,64',
    subColorTheme4: '227, 227, 227'
  },
  {
    id: 't06',
    name: 'Project Anil',
    url: '/img/project-template/anil.jpg',
    primaryColorTheme: '11,28,43',
    secondaryTheme: '255,255,255',
    subColorTheme1: '0,0,0',
    subColorTheme2: null,
    subColorTheme3: null,
    subColorTheme4: null
  }
]

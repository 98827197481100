<template>
  <b-modal
    id="add-banner"
    size="xl"
    centered
    no-close-on-backdrop
    @close="onHideModal"
    title="Add/Edit Banner List"
  >
    <form id="add-banner-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Start Slide</span>
            <date-picker
              lang="en"
              :disabledDays="disableStartDate"
              placeholder="Select Date"
              :class="{
                'date-picker': true,
                'date-required': requiredStartDate,
              }"
              v-model="data.start_date"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Stop Slide</span>
            <date-picker
              lang="en"
              :disabledDays="disableEndDate"
              placeholder="Select Date"
              :class="{
                'date-picker': true,
                'date-required': requiredEndDate,
              }"
              v-model="data.end_date"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <ImageUploadNew
            :uploadSetting="{ ...uploadSetting }"
            :dataImage="{ ...imagePC }"
            :showError="uploadImgPcError"
            :isRequired="false"
            :maxFile="1"
            :subject="'Picture Slide (PC) (1920 x 800 Pixel)'"
            :onFileUploaded="onFileUploadPcImage"
          />
        </div>
        <div class="col-md-6">
          <ImageUploadNew
            :uploadSetting="{ ...uploadSetting }"
            :dataImage="{ ...imageMobile }"
            :showError="uploadImgMobileError"
            :isRequired="false"
            :maxFile="1"
            :subject="'Picture Slide (Mobile) (800 x 800 Pixel)'"
            :onFileUploaded="onFileUploadMobileImage"
          />
        </div>
      </div>
      <hr />
      <h6 class="pb-3">Please fill link for click to</h6>
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            color="primary"
            :checked="data.link_type === 'url'"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'url')"
          >
            <i slot="extra" class="icon fa fa-check"></i> URL Link
          </p-check>
        </div>
        <input
          type="text"
          v-model="data.url"
          :disabled="data.link_type !== 'url'"
          :class="{
            'form-controlsss': true,
            'form-control': true,
            'has-error': !urlValidator.url,
          }"
          @change="(e) => onvalidateUrl(e.target.value)"
          placeholder="Example - https://www.grandunity.co.th/directory"
        />
      </div>
      <br />
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            :checked="data.link_type === 'youtube'"
            color="primary"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'youtube')"
          >
            <i slot="extra" class="icon fa fa-check"></i> Youtube Link
          </p-check>
        </div>
        <input
          type="text"
          v-model="data.youtube"
          :disabled="data.link_type !== 'youtube'"
          :class="{
            'form-control': true,
            'has-error': !urlValidator.youtube,
          }"
          @change="(e) => onValidateYoutube(e.target.value)"
          placeholder="Example - https://www.youtube.com/path"
        />
      </div>
    </form>
    <template slot="modal-footer">
      <b-button size="sm" variant="primary" type="submit" form="add-banner-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="onHideModal()"
        >Cancel</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import ImageUploadNew from '../ImageUploadNew'

export default {
  props: {
    data: Object,
    onSubmitForm: Function,
    onFetch: Function
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    requiredStartDate: false,
    requiredEndDate: false,
    uploadImgPcError: false,
    uploadImgMobileError: false,
    urlValidator: {
      url: true,
      youtube: true
    },
    imagePC: {
      source: '',
      name: ''
    },
    imageMobile: {
      source: '',
      name: ''
    }
  }),
  methods: {
    onHideModal () {
      this.uploadImgPcError = false
      this.uploadImgMobileError = false
      this.urlValidator.url = true
      this.urlValidator.youtube = true
      this.onFetch()
      this.$bvModal.hide('add-banner')
    },
    submit (e) {
      let canSave = false
      this.urlValidator.url = true
      this.urlValidator.youtube = true

      this.uploadImgPcError = !this.data.image.filename
      this.uploadImgMobileError = !this.data.image_mobile.filename

      this.requiredStartDate = !!(!this.data.start_date && this.data.end_date)
      this.requiredEndDate = !!(!this.data.end_date && this.data.start_date)

      if (this.data.link_type === 'url' && !this.data.url) {
        this.urlValidator.url = false
      } else if (this.data.link_type === 'youtube' && !this.data.youtube) {
        this.urlValidator.youtube = false
      } else {
        if (this.data.link_type === 'url') {
          this.onvalidateUrl(this.data.url)
        } else if (this.data.link_type === 'youtube') {
          this.onValidateYoutube(this.data.youtube)
        }
        canSave = true
      }

      if (
        canSave &&
        this.urlValidator.url &&
        this.urlValidator.youtube &&
        !this.uploadImgPcError &&
        !this.uploadImgMobileError &&
        !this.requiredStartDate &&
        !this.requiredEndDate
      ) {
        this.onSubmitForm(this.data)
        this.$bvModal.hide('add-banner')
      }

      e.preventDefault()
    },
    onvalidateUrl ($value) {
      try {
        if ($value) {
          // eslint-disable-next-line no-new
          new URL($value)
        }

        this.urlValidator.url = true
      } catch ($ex) {
        this.urlValidator.url = false
        this.$bvToast.toast(`URL invalid`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }
    },
    onValidateYoutube ($value) {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      this.urlValidator.urlYoutube = true

      if ($value) {
        let match = $value.match(regExp)

        if (!match || match[2].length !== 11) {
          this.urlValidator.urlYoutube = false

          this.$bvToast.toast(`youtube invalid`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      }
    },
    disableStartDate (date) {
      const end = new Date(this.data.end_date)
      return this.data.end_date && date >= end
    },
    disableEndDate (date) {
      const start = new Date(this.data.start_date)
      return date < start
    },
    onFileUploadPcImage ($value) {
      this.data.image.filename = $value.source
      this.data.image.alt = $value.name

      this.imagePC = $value
    },
    onFileUploadMobileImage ($value) {
      this.data.image_mobile.filename = $value.source
      this.data.image_mobile.alt = $value.name

      this.imageMobile = $value
    },
    swithType (value, type) {
      this.data.link_type = this.data.link_type === type ? null : type
    }
  },
  watch: {
    'data.image': function (value) {
      this.imagePC = {
        source: value.filename,
        name: value.alt
      }
    },
    'data.image_mobile': function (value) {
      this.imageMobile = {
        source: value.filename,
        name: value.alt
      }
    }
  }
}
</script>

<style scoped>
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
.p-icon {
  min-width: 100px;
}
</style>

<template>
  <div>
    <div class="table-responsive">
      <div class="text-right mb-3">
        <b-button size="md" v-on:click="addEditBanner(0)" variant="primary">
          Add New Item
        </b-button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Images</th>
            <th>Last Update</th>
            <th>Type</th>
            <th>Published</th>
            <th>Start Auto Published</th>
            <th>Stop Auto Published</th>
            <th>Open New Window</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>
              <img :src="imageSource(list)" alt class="banner" />
            </td>
            <td>{{ renderDate(list.updatedAt) }}</td>
            <td>{{ getType(list) }}</td>
            <td>
              <b-form-checkbox
                v-model="list.status"
                value="Active"
                unchecked-value="InActive"
                switch
                :class="{
                  'checkbox-banner-status-active': list.status === 'Active',
                  'checkbox-banner-status-inactive': list.status !== 'Active',
                }"
                @change="(value) => onChangeStatus(value, list)"
                :disabled="validateDisable(list)"
              />
            </td>
            <td>
              <p class="start_stop_date">
                start:
                {{ (list.startDate && renderDate(list.startDate)) || "-" }}
              </p>
            </td>
            <td>
              <p class="start_stop_date">
                end: {{ (list.endDate && renderDate(list.endDate)) || "-" }}
              </p>
            </td>
            <td>
              <div v-if="getType(list) == 'image'">
                <b-form-checkbox
                  v-model="list.isOpenNewWindow"
                  value="Y"
                  unchecked-value="N"
                  switch
                  :class="{
                    'checkbox-banner-status-active': list.status === 'Active',
                    'checkbox-banner-status-inactive': list.status !== 'Active',
                  }"
                  @change="(value) => onChangeOpenNewWindow(value, list)"
                />
              </div>
              <div v-else>N/A</div>
            </td>
            <td>
              <b-button-group>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="btn-group-space"
                  v-on:click="addEditBanner(list.id)"
                  >Edit</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="deleteBanner(list.id, index)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="data.length === 0">
            <td colspan="7">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination" v-if="data.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="perPage"
        :options="perPageOptions"
      ></b-form-select>
    </div>
    <AddBannerModal
      :banner="banner"
      @onSubmit="onSubmitAddEditBanner"
      :vuelidate="this.$v"
    />
  </div>
</template>

<script>
import { requiredIf, url as validateUrl } from 'vuelidate/lib/validators'
import format from 'date-fns/format'
import AddBannerModal from '@/components/modals/AddBannerModal'
import BannerService from '@/services/banner.service.js'

export default {
  props: {
    projectID: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => []
    },
    onFetchBannerList: Function,
    page: String,
    onGetDetail: Function,
    onAddBanner: Function,
    onUpdateBanner: Function,
    onDeleteBanner: Function,
    onSorting: Function
  },
  components: {
    AddBannerModal
  },
  created () {
    this.setBannerForm(BannerService.prepareBannerForForm())
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    banner: {}
  }),
  validations () {
    return {
      banner: {
        url: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.fileType === 'url' && !nestedModel.url.url
          }),
          url:
            this.fileType === 'url'
              ? {
                validateUrl
              }
              : {}
        },
        urlYoutube: {
          required: requiredIf((nestedModel) => {
            return (
              nestedModel.fileType === 'youtube' && !nestedModel.urlYoutube.url
            )
          }),
          url:
            this.fileType === 'youtube'
              ? {
                validateUrl
              }
              : {}
        }
      }
    }
  },
  methods: {
    validateDisable ($data) {
      return !!$data.startDate && !!$data.endDate
    },
    setBannerForm (data) {
      this.banner = data
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)
      return items
    },
    deleteBanner (id, index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteBanner(id).then((resp) => {
              if (resp && resp.success) {
                this.onFetchBannerList()
              }
            })
          }
        })
    },
    onSubmitAddEditBanner () {
      this.$v.banner.$touch()
      if (!this.$v.banner.$error) {
        const data = BannerService.prepareBannerToCommandAPI(this.banner)
        data.module = this.page

        if (this.projectID > 0) {
          data.projectId = this.projectID
        }

        if (data.startDate && data.endDate) {
          data.status = 'InActive'
        }

        if (this.banner.id === 0) {
          this.onAddBanner(data).then((resp) => {
            if (resp && resp.success) {
              this.$bvModal.hide('add-banner')
              this.onFetchBannerList()
            } else {
              alert(resp)
            }
          })
        } else {
          this.updateBanner(this.banner.id, data)
        }
      }
    },
    updateBanner (id, data) {
      data.startDate =
        (data.startDate && this.renderDate(data.startDate)) || null
      data.endDate = (data.endDate && this.renderDate(data.endDate)) || null
      data.status = data.endDate ? ' InActive' : data.status

      this.onUpdateBanner({ id, data }).then((resp) => {
        if (resp && resp.success) {
          this.$bvModal.hide('add-banner')
          this.onFetchBannerList()
        } else {
          alert(resp)
        }
      })
    },
    addEditBanner (id) {
      if (id === 0) {
        this.setBannerForm(BannerService.prepareBannerForForm(null, this.page))
        this.$bvModal.show('add-banner')
      } else {
        this.onGetDetail(id).then((resp) => {
          if (resp && resp.success) {
            const banner = BannerService.prepareBannerForForm(
              resp.data,
              this.page
            )
            this.setBannerForm(banner)
            this.$bvModal.show('add-banner')
            this.onFetchBannerList()
          }
        })
      }
    },
    onChangeStatus (value, banner) {
      banner.status = value
      this.updateBanner(banner.id, banner)
    },
    onChangeOpenNewWindow (value, banner) {
      banner.isOpenNewWindow = value
      this.updateBanner(banner.id, banner)
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSorting({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp && resp.success) {
          this.onFetchBannerList()
        }
      })
    },
    imageSource (banner) {
      const image = banner.pcImages
      return `${
        /^http.+/.test(image.source) ? '' : process.env.VUE_APP_WEBSITE_ENDPOINT
      }${image.source}`
    },
    getType (banner) {
      let resp = '-'

      if (
        (banner.url && banner.url.url) ||
        banner.pcImages.source ||
        banner.mobileImages.source
      ) {
        resp = 'image'
      }

      if (banner.urlYoutube && banner.urlYoutube.url) {
        resp = 'vdo'
      }
      return resp
    },
    getPublished (banner) {
      let ispublic = banner.status.toLowerCase() === 'active'
      if (ispublic) {
        ispublic = this.isBetweenDate(banner)
      }
      return ispublic
    },
    isBetweenDate (banner) {
      const now = this.renderDate(new Date())
      const startDate = this.renderDate(new Date(banner.startDate))
      const endDate = this.renderDate(new Date(banner.endDate))
      return now >= startDate && (!banner.endDate || now <= endDate)
    },
    getOpenNewWindow (banner) {
      return banner.isOpenNewWindow === 'Y'
    }
  },
  computed: {
    rows () {
      return this.data.length
    },
    fileType () {
      return this.banner.fileType
    },
    urlValue () {
      return (this.banner.url && this.banner.url.url) || ''
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
.vue-js-switch.disabled {
  pointer-events: inherit !important;
  cursor: not-allowed;
}
.custom-control-input ~ .custom-control-label::before {
  line-height: 15px;
  font-size: 7px;
  margin-right: 35px;
  font-weight: 600;
  width: 2rem;
  padding-left: 0.2rem;
  content: "off";
  text-align: right;
  padding-right: 0.3rem;
  color: #ffffff;
  border-color: #bfcad9;
  background-color: #bfcad9;
}
.custom-control-input ~ .custom-control-label::after {
  left: calc(-2.25rem + 2px);
  background-color: #ffffff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(117, 199, 145) !important;
  border-color: rgb(117, 199, 145);
  content: "on";
  text-align: left;
}
.custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-2.25rem + 6px);
}
</style>

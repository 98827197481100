<template>
  <div class="p-3">
    <b-form-group label="Project" class="mb-4">
      <b-form-select
        v-model="form.project"
        :options="projects"
        v-on:change="changeProject"
      />
    </b-form-group>

    <b-tabs class="mt-3">
      <b-tab key="0" title="ภาษาไทย">
        <b-form class="p-3">
          <b-form-group label="og:url">
            <b-form-input
              v-model="form.url.th"
              required
              placeholder="Input og url"
            />
          </b-form-group>
          <b-form-group label="og:title">
            <b-form-input
              v-model="form.metaKeyword.th"
              required
              placeholder="Input og title"
            />
          </b-form-group>
          <b-form-group label="og:description">
            <b-form-input
              v-model="form.metaDescription.th"
              required
              placeholder="Input og description"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <br />
          <label class="label"
            >*Basic Facebook Config for all pages in Website</label
          >
          <br />
        </b-form>
      </b-tab>
      <b-tab key="1" title="English">
        <b-form class="p-3">
          <b-form-group label="og:url">
            <b-form-input
              v-model="form.url.en"
              required
              placeholder="Input og url"
            />
          </b-form-group>
          <b-form-group label="og:title">
            <b-form-input
              v-model="form.metaKeyword.en"
              required
              placeholder="Input og title"
            />
          </b-form-group>
          <b-form-group label="og:description">
            <b-form-input
              v-model="form.metaDescription.en"
              required
              placeholder="Input og description"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <br />
          <label class="label"
            >*Basic Facebook Config for all pages in Website</label
          >
          <br />
        </b-form>
      </b-tab>
    </b-tabs>

    <b-button variant="primary" v-on:click="saveSeoThankyou">Update</b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    projects: [
      { value: null, text: 'Select Project' },
      { value: 'a', text: 'Project 1' },
      { value: 'b', text: 'Project 1' }
    ],
    form: {
      project: null
    },
    projectSelect: null
  }),
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.REGISTERSETTING.PROJECTLIST,
      getList: ActionFetch.SEO_FACEBOOK.GET_THANKYOU,
      saveThankyou: ActionFetch.SEO_FACEBOOK.SAVE_THANKYOU
    }),
    loadProjectList () {
      this.getProjectList().then(($res) => {
        if ($res.success) {
          this.projectList = $res.data
          this.projects = $res.data.map(($data) => {
            return { value: $data.id, text: $data.name.th }
          })
        }
        // this.form.project = $res.data[0].id;
        // this.changeProject();
      })
    },
    getThankyou () {
      this.getList(this.projectSelect).then(($res) => {
        if ($res.success && $res.data) {
          this.form = { ...this.form, ...$res.data }
          this.form.project = this.projectSelect
        } else {
          this.form = {
            project: this.form.project,
            metaDescription: {
              th: '',
              en: ''
            },
            metaKeyword: {
              th: '',
              en: ''
            },
            tagInHeader: {
              th: '',
              en: ''
            },
            url: {
              th: '',
              en: ''
            },
            tagInBody: {
              th: '',
              en: ''
            },
            visitor: '127.0.0.1',
            status: 'Active',
            updatedBy: ''
          }
        }
      })
    },
    changeProject () {
      this.projectSelect = this.form.project
      this.getThankyou()
    },
    saveSeoThankyou () {
      this.saveThankyou(this.form).then(($res) => {
        if ($res.success) {
          this.getThankyou()
        }
      })
    }
  },
  created () {
    this.form = {
      project: null,
      metaDescription: {
        th: '',
        en: ''
      },
      metaKeyword: {
        th: '',
        en: ''
      },
      url: {
        th: '',
        en: ''
      },
      tagInHeader: {
        th: '',
        en: ''
      },
      tagInBody: {
        th: '',
        en: ''
      },
      visitor: '127.0.0.1',
      status: 'Active',
      updatedBy: ''
    }
    this.loadProjectList()
  }
}
</script>

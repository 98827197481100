<template>
  <div id="floor-category-table" class="mb-3">
    <div
      class="d-flex justify-content-between align-content-end align-items-center mb-3"
    >
      <h5 class="mb-0">Room layout</h5>
      <b-button size="md" variant="primary" v-on:click="addEditUnit()"
        >Add New Item</b-button
      >
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Images</th>
            <th>Building</th>
            <th>Room Layout</th>
            <th>Type</th>
            <th>Last Update</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in units" :key="index">
            <td>
              <b-form-select
                :value="item.orderNum"
                @change="
                  (value) => onChangeOrder(item.id, value, item.orderNum)
                "
              >
                <option v-for="i in units.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>
              <img
                :src="imageSource(item)"
                :alt="item.images.name"
                class="img"
              />
            </td>
            <td>
              <span>{{
                item.buildingId &&
                item.buildingId.title &&
                item.buildingId.title.en
              }}</span>
            </td>
            <td>
              <span>{{
                item.attributeId &&
                item.attributeId.title &&
                item.attributeId.title.en
              }}</span>
            </td>
            <td>{{ item.type.en }}</td>
            <td>{{ renderDate(item.updatedAt) }}</td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  v-on:click="addEditUnit(item)"
                  class="btn-group-space"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  @click="deleteUnit(item.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="units.length === 0">
            <td colspan="7">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <edit-room-type-modal
      :projectID="projectID"
      :attrUnits="attrUnits"
      :attrBuildings="attrBuildings"
      :onFetchUnitsList="onFetchUnitsList"
      :onUpdateUnits="onUpdateUnits"
      :onAddUnits="onAddUnits"
      :form="unit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'
import EditRoomTypeModal from '../modals/EditRoomType.modal'

export default {
  props: {
    projectID: Number
  },
  components: {
    EditRoomTypeModal
  },
  data: () => ({
    attrBuildings: [],
    attrUnits: [],
    units: [],
    unit: {
      projectId: 0,
      buildingId: 0,
      attributeId: 0,
      type: {
        th: '',
        en: ''
      },
      images: {
        source: null,
        name: ''
      }
    }
  }),
  methods: {
    ...mapActions({
      getUnitsList: ActionFetch.PROJECTS.UNITS.INDEX,
      onUpdateUnits: ActionFetch.PROJECTS.UNITS.UPDATE,
      onDeleteUnits: ActionFetch.PROJECTS.UNITS.DELETE,
      onAddUnits: ActionFetch.PROJECTS.UNITS.ADD,
      onSorting: ActionFetch.PROJECTS.UNITS.SORT,
      getAttributeList: ActionFetch.ATTRIBUTES.INDEX
    }),
    renderDate (date) {
      return date && date !== null ? format(date, 'YYYY-MM-DD') : '-'
    },
    onFetchUnitsList () {
      this.units = []
      this.getUnitsList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.units) {
          this.units = this.$store.state.project.units
        }
      })
    },
    handleUnitsList () {
      if (this.$store.state.project && this.$store.state.project.units) {
        this.units = this.$store.state.project.units
      } else {
        this.onFetchUnitsList()
      }
    },
    onChangeOrder (id, newValue, oldValue) {
      this.units = []
      this.onSorting({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.onFetchUnitsList()
        }
      })
    },
    addEditUnit (data) {
      if (!data) {
        data = {
          projectId: this.projectID,
          buildingId: null,
          attributeId: null,
          type: {
            th: '',
            en: ''
          },
          images: {
            source: null,
            name: ''
          }
        }
      } else {
        data = {
          ...data,
          buildingId: data.buildingId.id,
          attributeId: data.attributeId.id
        }
      }
      this.unit = data
      this.$bvModal.show('edit-room-type')
    },
    fetachAttrUnit () {
      this.getAttributeList({
        moduleName: 'unitPlan',
        toStoreData: false
      }).then((resp) => {
        this.attrUnits = resp.success && resp.data
      })
    },
    fetachAttrBuilding () {
      this.getAttributeList({
        moduleName: 'building',
        toStoreData: false
      }).then((resp) => {
        this.attrBuildings = resp.success && resp.data
      })
    },
    imageSource (data) {
      const image = data.images
      return `${
        /^http.+/.test(image.source) ? '' : process.env.VUE_APP_WEBSITE_ENDPOINT
      }${image.source}`
    },
    deleteUnit (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteUnits(id).then((resp) => {
              if (resp.success) {
                this.onFetchUnitsList()
              }
            })
          }
        })
    }
  },
  created () {
    this.fetachAttrBuilding()
    this.fetachAttrUnit()
    this.handleUnitsList()
  }
}
</script>
<style scoped>
.img {
  max-width: 200px;
  max-height: 50px;
}
</style>

<template>
  <div id>
    <div class="page-name">
      <div class="page-title">Home Page</div>
    </div>

    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Grand Unity Concept</h5>
            </div>
          </div>

          <hr />

          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="{ ...form.bg_image }"
                    :showError="uploadError"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="(e) => onImageUpload(e, 'bg_image')"
                  />
                </div>
                <div class="flex-1">
                  <b-form-group
                    style="font-weight: 600"
                    label="Background Image (1920 x 550 pixel)"
                  >
                    <div class="flex-row">
                      <p
                        style="margin-right: 5px; flex: 1 10%"
                        class="alt-thumb"
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        v-model="form.bg_image.name"
                        type="text"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="{ ...form.logo_image }"
                    :showError="uploadLogoError"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="(e) => onImageUpload(e, 'logo_image')"
                  />
                </div>
                <div class="flex-1">
                  <b-form-group
                    style="font-weight: 600"
                    label="LOGO (width 300px, height follow width ratio, use png file type)"
                  >
                    <div class="flex-row">
                      <p
                        style="margin-right: 5px; flex: 1 10%"
                        class="alt-thumb"
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        v-model="form.logo_image.name"
                        type="text"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
              <b-form-group style="font-weight: 600" label="Title (H1)">
                <b-form-input
                  v-model="form.title.th"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle (H2)">
                <b-form-input
                  v-model="form.subtitle.th"
                  type="text"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.detail.th"
                :onUpdateInfo="(e) => onUpdateContent(e, 'th')"
              />
            </b-tab>
            <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
              <b-form-group style="font-weight: 600" label="Title (H1)">
                <b-form-input
                  v-model="form.title.en"
                  type="text"
                  placeholder="Input Title"
                ></b-form-input>
              </b-form-group>
              <b-form-group style="font-weight: 600" label="Subtitle (H2)">
                <b-form-input
                  v-model="form.subtitle.en"
                  type="text"
                  placeholder="Input Subtitle"
                ></b-form-input>
              </b-form-group>
              <div class="form-group form-group-row">
                <span>Details</span>
              </div>
              <TextEditor
                :content="form.detail.en"
                :onUpdateInfo="(e) => onUpdateContent(e, 'en')"
              />
            </b-tab>
          </b-tabs>

          <hr />

          <div class="form-group form-group-row">
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              type="button"
              class="btn mx-1 btn-secondary btn-sm"
              @click="fetchInfo"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import ImageUploadNew from '@/components/ImageUploadNew'
import TextEditor from '@/components/text-editor/TextEditor'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    tab: 'th',
    form: {
      bg_image: {
        source: '',
        name: ''
      },
      logo_image: {
        source: '',
        name: ''
      },
      title: {
        th: '',
        en: ''
      },
      subtitle: {
        th: '',
        en: ''
      },
      detail: {
        th: '',
        en: ''
      }
    },
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    uploadError: false,
    uploadLogoError: false
  }),
  components: {
    ImageUploadNew,
    TextEditor
  },
  methods: {
    ...mapActions({
      getInfo: ActionFetch.HOME.DETAIL,
      updateData: ActionFetch.HOME.UPDATE
    }),
    fetchInfo () {
      this.getInfo().then(($res) => {
        this.form = $res.data
      })
    },
    onImageUpload ($data, $props) {
      this.form[$props].source = $data.source
    },
    onUpdateContent ($value, $lang) {
      this.form.detail[$lang] = $value
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (!this.form.bg_image.source) {
        this.uploadError = true
      } else {
        this.uploadError = false

        if (!this.form.title.th) {
          this.$bvToast.toast(`Please input thai title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!this.form.subtitle.th) {
          this.$bvToast.toast(`Please input thai subtitle`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!this.form.title.en) {
          this.$bvToast.toast(`Please input english title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!this.form.subtitle.en) {
          this.$bvToast.toast(`Please input english title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else {
          this.updateData(this.form).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Update Grand Unity Concept successful`, {
                title: 'Grand Unity Concept updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            }
          })
        }
      }
    }
  },
  created () {
    this.fetchInfo()
  }
}
</script>

<style>
.inline-zone {
  margin-right: 10px;
  font-weight: 600;
  min-width: 25%;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
  min-width: 120px;
  max-width: 200px;
}

.table.custom-news td {
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  vertical-align: middle;
}

.table.custom-news tr.footer td {
  vertical-align: top;
}

.add-u-btn {
  margin-top: 20px;
}

.alt-thumb {
  margin: auto;
}

.url-error {
  border-color: #ff0000;
}

.url-error:focus {
  border-color: #ff0000;
  box-shadow: 0 0 0 0rem rgba(255, 0, 0, 0.25);
}

.hidden {
  opacity: 0;
}
.w-70px {
  width: 70px;
}
td {
  vertical-align: middle !important;
}
.gallery-img-container {
  max-width: 250px;
}
.orderBox {
  width: 80px;
}
</style>

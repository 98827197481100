<template>
  <b-modal
    id="add-video"
    size="lg"
    centered
    no-close-on-backdrop
    title="Add/Edit Video"
  >
    <form id="add-video-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Thai name
            <input
              type="text"
              :class="{
                'form-control': true,
                'has-error': this.$v.form.title.th.$error,
              }"
              v-model="form.title.th"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Eng name
            <input
              type="text"
              :class="{
                'form-control': true,
                'has-error': this.$v.form.title.en.$error,
              }"
              v-model="form.title.en"
            />
          </div>
        </div>
      </div>
      <div class="form-group form-group-row">
        Youtube Link
        <input
          type="text"
          :class="{
            'form-control': true,
            'has-error': this.$v.form.video.$error,
          }"
          v-model="form.video.url"
        />
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button size="sm" variant="primary" type="submit" form="add-video-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { required, url as validateUrl } from 'vuelidate/lib/validators'

const youtube = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}
const validateYoutube = (value) => {
  const resp = (value && youtube(value)) || false
  return resp !== false
}
export default {
  props: {
    form: {
      type: Object,
      default: () => ({
        title: {
          th: '',
          en: ''
        }
      })
    },
    onUpdateVideo: Function,
    onAddVideo: Function,
    onFetchVideoList: Function
  },
  validations: {
    form: {
      title: {
        th: {
          required
        },
        en: {
          required
        }
      },
      video: {
        url: {
          required,
          validateUrl,
          validateYoutube
        }
      }
    }
  },
  methods: {
    cancel () {
      this.$bvModal.hide('add-video')
    },
    youtubeParser (url) {
      return youtube(url)
    },
    submit (e) {
      this.$v.form.$touch()
      if (!this.$v.form.$error) {
        const data = this.form
        data.video = {
          url: data.video.url,
          id: this.youtubeParser(data.video.url)
        }
        if (!data.id) {
          this.onAddVideo(data).then((resp) => {
            if (resp.success) {
              this.$bvModal.hide('add-video')
              this.onFetchVideoList()
              this.$v.form.$reset()
            }
          })
        } else {
          this.onUpdateVideo({ id: data.id, data }).then((resp) => {
            if (resp.success) {
              this.$bvModal.hide('add-video')
              this.$v.form.$reset()
              this.onFetchVideoList()
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}

.image-picker-group > .form-group {
  margin-left: 20px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Advance Search</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <location
          :data="locationList"
          :paginate="paginate"
          :onCreateLocation="createLocation"
          :onUpdateLocation="updateLocation"
          :onDeleteLocation="deleteLocation"
          :onPaginateChange="paginationChange"
          :onLimitChange="limitChange"
          :onUpdateSortLocation="updateSortLocation"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import Location from '@/components/tables/location'

export default {
  components: {
    location: Location
  },
  data: () => ({
    enable: true,
    locationList: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.SEARCH_LOCATION.LIST,
      createData: ActionFetch.SEARCH_LOCATION.CREATE,
      updateData: ActionFetch.SEARCH_LOCATION.UPDATE,
      updateSort: ActionFetch.SEARCH_LOCATION.UPDATESORT,
      deleteData: ActionFetch.SEARCH_LOCATION.DELETE
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchLocation () {
      const condition = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: this.paginate.page
      }

      this.getList(condition).then(($res) => {
        if ($res.success) {
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0

          if ($res.data) {
            this.locationList = $res.data.map(($data) => {
              $data.status = $data.status.toLowerCase() === 'active'

              return $data
            })
          }
        }
      })
    },
    async createLocation ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'
      this.createData(dataSave).then(($res) => {
        this.fetchLocation()
      })
    },
    async updateLocation ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'

      this.updateData(dataSave).then(($res) => {
        if ($res.success) {
          this.fetchLocation()
        }
      })
    },
    async deleteLocation ($id) {
      this.deleteData($id).then(($res) => {
        if ($res.success) {
          this.fetchLocation()
        }
      })
    },
    paginationChange ($paginate) {
      this.paginate.page = $paginate
      this.fetchLocation()
    },
    limitChange ($value) {
      this.paginate.limit = $value

      this.fetchLocation()
    },
    async updateSortLocation ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchLocation()
        }
      })
    }
  },
  created () {
    this.fetchLocation()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

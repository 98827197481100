<template>
  <b-modal
    id="edit-room-type"
    size="lg"
    centered
    title="Add/Edit Room Type List"
  >
    <div class="container">
      <form id="edit-room-type-form" @submit.prevent="submit">
        <div class="row" style="text-align: left">
          <div class="col-12">
            <b-form-group
              label-cols-sm="2"
              label-cols-md="3"
              label="Building"
              label-align-sm="left"
            >
              <b-form-select :required="true" v-model="form.buildingId">
                <template v-slot:first>
                  <option :value="null" disabled>
                    -- Please select building --
                  </option>
                </template>
                <option
                  v-for="(selectOption, indexOpt) in buildingList"
                  :key="indexOpt"
                  :value="selectOption.id"
                  :selected="form.buildingId === selectOption.id"
                >
                  {{ selectOption.title.en }}
                </option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label-cols-sm="2"
              label-cols-md="3"
              label="Select Room type"
              label-align-sm="left"
            >
              <div class="input-group">
                <b-form-select :required="true" v-model="form.attributeId">
                  <template v-slot:first>
                    <option :value="null" disabled>
                      -- Please select unit type --
                    </option>
                  </template>
                  <option
                    v-for="(selectOption, indexOpt) in unitList"
                    :key="indexOpt"
                    :value="selectOption.id"
                    :selected="form.attributeId === selectOption.id"
                  >
                    {{ selectOption.title.en }}
                  </option>
                </b-form-select>
              </div>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
              label-cols-sm="2"
              label-cols-md="3"
              label="Type [TH]"
              label-align-sm="left"
            >
              <div class="input-group">
                <b-form-input
                  v-model="form.type.th"
                  required
                  placeholder="Example - 1B-1 32-45 ตารางเมตร"
                ></b-form-input>
              </div>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
              label-cols-sm="2"
              label-cols-md="3"
              label="Type [EN]"
              label-align-sm="left"
            >
              <div class="input-group">
                <b-form-input
                  v-model="form.type.en"
                  required
                  placeholder="Example - 1B-1 32-45 SQ.M."
                ></b-form-input>
              </div>
            </b-form-group>
          </div>
        </div>
        <hr />
        <div id="logo-section" class="flex-row">
          <div class="flex-col flex-1" style="padding-right: 40px">
            <ImageUploadNew
              :dataImage="{ ...form.images }"
              :uploadSetting="{ ...uploadSetting }"
              :isRequired="false"
              :showError="uploadImageError"
              :maxFile="1"
              :subject="'Picture (Denim,Mazarine 1200x800 pixel , Blue,Ciela,Kara,Anil 950x650 pixel)'"
              :onFileUploaded="onFileUploaded"
            />
          </div>
        </div>
      </form>
    </div>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="edit-room-type-form"
        >Save</b-button
      >
      <b-button size="sm" variant="outline-danger" @click="cancel()"
        >Cancel</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import ImageUploadNew from '../ImageUploadNew'

export default {
  props: {
    projectID: Number,
    onFetchUnitsList: Function,
    onUpdateUnits: Function,
    onAddUnits: Function,
    attrBuildings: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => ({
        projectId: 0,
        buildingId: null,
        attributeId: null,
        type: {
          th: '',
          en: ''
        },
        images: {
          source: null,
          name: ''
        }
      })
    },
    attrUnits: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadImageError: false,
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    }
  }),
  methods: {
    onFileUploaded ($value) {
      this.form.images = $value
    },
    submit (e) {
      e.preventDefault()
      if (!this.form.images.source) {
        this.uploadImageError = true
        return
      }

      this.uploadImageError = false
      const data = this.form
      if (data.id) {
        this.onUpdateUnits({ id: data.id, data }).then((resp) => {
          this.onFetchUnitsList()
          this.$bvModal.hide('edit-room-type')
          this.$bvToast.toast(resp.message, {
            title: 'Update',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        })
      } else {
        this.onAddUnits(data).then((resp) => {
          this.onFetchUnitsList()
          this.$bvModal.hide('edit-room-type')
          this.$bvToast.toast(resp.message, {
            title: 'Add',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        })
      }
    }
  },
  computed: {
    buildingList () {
      return [...this.attrBuildings].sort(($first, $next) => {
        if ($first.orderNum > $next.orderNum) {
          return 1
        } else if ($first.orderNum < $next.orderNum) {
          return -1
        }

        return 0
      })
    },
    unitList () {
      return [...this.attrUnits].sort(($first, $next) => {
        if ($first.orderNum > $next.orderNum) {
          return 1
        } else if ($first.orderNum < $next.orderNum) {
          return -1
        }

        return 0
      })
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

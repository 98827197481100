<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('location', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('location', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Location</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <form @submit.prevent="submit">
        <h5 class="my-3">Title Section</h5>
        <div class="row" style="text-align: left">
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Thai title
              <input
                type="text"
                class="form-control"
                required
                v-model="section.title.th"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Eng title
              <input
                type="text"
                class="form-control"
                required
                v-model="section.title.en"
              />
            </div>
          </div>
        </div>
        <hr class="bold" />
        <p class="page-title" style="font-weight: 600; color: black">
          Graphic Map (Blue 1200x700 pixel , Denim,Kara,Mazarine,Anil 1920x860
          pixel , Ciela 800x800 pixel)
        </p>

        <ImageUploadNew
          :dataImage="{ ...location.graphicMap }"
          :uploadSetting="{ ...uploadSetting }"
          :isRequired="false"
          :showError="showImageError"
          :maxFile="1"
          :onFileUploaded="onFileUploaded"
        />
        <br />
        <!-- <p class="col-form-label bold">Graphic Map (AAA x BBB pixel)</p>
        <br>

        <div class="form-group form-group-row">
          ALT text
          <input type="text" class="form-control">
        </div>-->
        <br />
        <p class="page-title" style="font-weight: 600; color: black">
          Google Map
        </p>
        <br />

        <div class="row" style="text-align: left">
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Lattitude:
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'has-error': this.$v.location.lattitude.$error,
                }"
                v-model="location.lattitude"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Longtitude:
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'has-error': this.$v.location.longtitude.$error,
                }"
                v-model="location.longtitude"
              />
            </div>
          </div>
        </div>
        <googlemaps-map
          ref="map"
          class="map"
          :center.sync="center"
          :zoom.sync="zoom"
        >
          <googlemaps-marker
            :position="position"
            :icon="{ url: pinPath }"
            :draggable="true"
            @dragend="updateCoordinates"
          ></googlemaps-marker>
        </googlemaps-map>
        <p class="page-title my-3">Address</p>
        <b-tabs>
          <b-tab
            title="ภาษาไทย"
            @click="aadressTab = 'th_address'"
            :active="aadressTab === 'th'"
          >
            <b-form-textarea
              id="textarea"
              v-model="location.address.th"
              placeholder="Enter thai location project..."
              rows="3"
              max-rows="10"
            ></b-form-textarea>
          </b-tab>
          <b-tab
            title="English"
            @click="aadressTab = 'en_address'"
            :active="aadressTab === 'en'"
          >
            <b-form-textarea
              id="textarea"
              v-model="location.address.en"
              placeholder="Enter english location project..."
              rows="3"
              max-rows="10"
            ></b-form-textarea>
          </b-tab>
          <!-- <div v-if="showAddressError && (location.address.th === '' || location.address.en === '')" class="text-error">* Address th/en is required.</div> -->
        </b-tabs>
        <p class="page-title my-3">Detail</p>
        <b-tabs>
          <b-tab
            title="ภาษาไทย"
            @click="tab = 'th_detail'"
            :active="tab === 'th'"
          >
            <div class="content-editor">
              <TextEditor
                :content.sync="location.detail.th"
                :onUpdateInfo="onUpdateLocationTH"
              />
            </div>
          </b-tab>
          <b-tab
            title="English"
            @click="tab = 'en_detail'"
            :active="tab === 'en'"
          >
            <div class="content-editor">
              <TextEditor
                :content.sync="location.detail.en"
                :onUpdateInfo="onUpdateLocationEN"
              />
            </div>
          </b-tab>
          <!-- <div
            v-if="showDetailError && (location.detail.th === '' || location.detail.en === '')"
            class="text-error"
          >* Detail th/en is required.</div> -->
        </b-tabs>
        <br />
        <b-button-group>
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            type="submit"
            >Save</b-btn
          >
          <b-btn
            size="sm"
            variant="outline-danger"
            class="btn-group-space"
            type="button"
            @click="cancel"
            >Cancel</b-btn
          >
        </b-button-group>
      </form>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, decimal } from 'vuelidate/lib/validators'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
import TextEditor from '../text-editor/TextEditor'

export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    ImageUploadNew,
    TextEditor
  },
  data: () => ({
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    showImageError: false,
    aadressTab: 'th',
    tab: 'th',
    editor_mode: 'editor',
    enable: true,
    location: {
      title: {
        th: '',
        en: ''
      },
      address: {
        th: '',
        en: ''
      },
      detail: {
        th: '',
        en: ''
      },
      lattitude: null,
      longtitude: null,
      graphicMap: {
        source: null
      }
    },
    position: {
      lat: 13.74336,
      lng: 100.54581
    },
    center: {
      lat: 13.74336,
      lng: 100.54581
    },
    zoom: 18,
    showAddressError: false,
    showDetailError: false,
    tempData: null
  }),
  created () {
    this.handleLocationList()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const {
          coords: { latitude, longitude }
        } = pos
        if (latitude > 0 && longitude > 0) {
          this.center = {
            lat: latitude,
            lng: longitude
          }
        }
      })
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  },
  validations: {
    location: {
      lattitude: {
        required,
        decimal
      },
      longtitude: {
        required,
        decimal
      },
      graphicMap: {
        source: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getProjectLocation: ActionFetch.PROJECTS.LOCATION.INDEX,
      onUpdateProjectLocation: ActionFetch.PROJECTS.LOCATION.UPDATE,
      onCreateProjectLocation: ActionFetch.PROJECTS.LOCATION.ADD
    }),
    polylineClicked () {
      const options = {
        strokeOpacity: Math.random()
      }
      this.options = options
    },
    onFileUploaded ($value) {
      this.location.graphicMap = $value
    },
    onFetchLocationList () {
      this.getProjectLocation(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.location) {
          this.location = this.$store.state.project.location
          this.setTempData()
        } else {
          this.location = {
            projectId: this.projectID,
            title: {
              th: '',
              en: ''
            },
            detail: {
              th: '',
              en: ''
            },
            address: {
              th: '',
              en: ''
            },
            graphicMap: {
              source: null,
              name: '',
              status: 'Active'
            },
            lattitude: null,
            longtitude: null,
            status: 'Active'
          }
          this.setTempData()
        }
      })
    },
    handleLocationList () {
      if (this.$store.state.project && this.$store.state.project.location) {
        this.location = this.$store.state.project.location
        this.setTempData()
      } else {
        this.onFetchLocationList()
      }

      if (this.location.lattitude > 0 && this.location.longtitude > 0) {
        this.center = {
          lat: Number(this.location.lattitude),
          lng: Number(this.location.longtitude)
        }
      }
    },
    updateCoordinates (location) {
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.position = {
        lat,
        lng
      }
      this.location.lattitude = lat
      this.location.longtitude = lng
    },
    onUpdateLocationTH ($value) {
      this.location.detail.th = $value
    },
    onUpdateLocationEN ($value) {
      this.location.detail.en = $value
    },
    submit (e) {
      e.preventDefault()

      this.showDetailError = false
      this.showAddressError = false

      if (!this.location.graphicMap.source) {
        this.showImageError = true
      }

      // if (this.location.detail.th === "" || this.location.detail.en === "") {
      //   this.showDetailError = true;
      // }
      // if (this.location.address.th === "" || this.location.address.en === "") {
      //   this.showAddressError = true;
      // }

      if (
        this.showDetailError ||
        this.showAddressError ||
        this.showImageError
      ) {
        return
      }
      this.$v.location.$touch()
      if (!this.$v.location.$error) {
        this.updateSection(this.section)
        if (this.location.id) {
          this.onUpdateProjectLocation({
            id: this.location.id,
            data: this.location
          }).then((resp) => {
            if (resp.success) {
              this.$bvToast.toast(resp.message, {
                title: 'Update',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'info',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            } else {
              alert(resp)
            }
          })
        } else {
          this.onCreateProjectLocation(this.location).then((resp) => {
            if (resp.success) {
              this.$bvToast.toast(resp.message, {
                title: 'Create',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'info',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
              this.onFetchLocationList()
            } else {
              alert(resp)
            }
          })
        }
      }
    },
    setTempData () {
      this.tempData = JSON.parse(JSON.stringify(this.location))
    },
    cancel () {
      this.location = JSON.parse(JSON.stringify(this.tempData))
    }
  },
  computed: {
    pinPath () {
      return require('@/assets/pin.png')
    }
  },
  watch: {
    'location.lattitude': function (val) {
      this.position = {
        ...this.position,
        lat: Number(val)
      }

      if (val > 0) {
        this.center = this.position
      }
    },
    'location.longtitude': function (val) {
      this.position = {
        ...this.position,
        lng: Number(val)
      }
      if (val > 0) {
        this.center = this.position
      }
    }
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}

.map {
  width: 100%;
  height: 480px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Register List</div>
    </div>
    <b-container fluid>
      <b-row class="d-flex align-content-end align-items-end py-2">
        <b-col lg="12" xl="4">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              Select Project
            </label>
            <b-form-select
              v-model="project"
              :options="[{ id: null, name: { th: 'All Project' } }, ...projects]"
              value-field="id"
              text-field="name.th"
              class="flex-fill"
              @change="onProjectChanged"
            />
          </b-form>
        </b-col>
        <b-col lg="12" xl="4">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              Select Type
            </label>
            <b-form-select
              class="flex-fill"
              v-model="type"
              @change="onTypeChanged"
            >
              <option :value="null">All Type</option>
              <option value="microsite">Microsite</option>
              <option value="brand">Brand</option>
              <option value="campaign">Campaign</option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col lg="12" xl="4">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              {{ campaignLabel }}
            </label>
            <b-form-select
              class="flex-fill"
              v-model="campaign"
              :options="[
                { id: null, name: { th: campaignPlaceholder } },
                ...campaignList
              ]"
              :disabled="!['brand', 'campaign'].includes(type)"
              value-field="id"
              text-field="name.th"
              @change="onCampaignChanged"
            />
          </b-form>
        </b-col>
      </b-row>
      <b-row class="d-flex align-content-end align-items-end">
        <b-col lg="12" xl="4">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              Select Date
            </label>
            <date-picker
              lang="en"
              placeholder="Start Date"
              class="date-picker flex-fill mr-0 my-1 my-sm-0 mr-sm-1"
              v-model="startAt"
              :not-after="endAt || today"
              @change="onStartAtChanged"
            />
          </b-form>
        </b-col>
        <b-col lg="12" xl="4">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              Select Date
            </label>
            <date-picker
              v-model="endAt"
              :not-before="startAt"
              :not-after="today"
              lang="en"
              placeholder="End Date"
              class="date-picker flex-fill ml-0 my-1 my-sm-0"
              @change="onEndAtChanged"
            />
          </b-form>
        </b-col>
        <b-col lg="12" xl="4" class="text-right">
          <b-button
            size="md"
            variant="primary"
            v-b-modal.form-select-edit
            v-on:click="exportExcel"
            >Download CSV</b-button
          >
          <b-button
            size="md"
            variant="primary"
            class="btn-last"
            v-b-modal.form-select-edit
            :disabled="sendingCrm"
            @click="onClickResendAllCrm"
          >
            <b-spinner v-if="sendingCrm" small />
            Send All CRM
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div class="page-container">
      <b-card class="w-100">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#No.</th>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Project</th>
                <th>Mobile No.</th>
                <th>UTM</th>
                <th>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in registers" :key="index">
                <td>{{ pagination.from + index + 1 }}</td>
                <td>{{ item.createdAt | dateFormat('YYYY-MM-DD') }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.projects.length ? item.projects[0].name : '-' }}</td>
                <td>{{ item.contact }}</td>
                <td>{{ item.trackings | trackingName(['utm_source']) }}</td>
                <td>{{ item.template }}</td>
                <td>
                  <b-btn
                    size="sm"
                    variant="outline-info"
                    @click="showRegister(item)"
                  >
                    View
                  </b-btn>
                  <template v-if="item.statusCrm === false">
                    <b-btn
                      :id="`crm-button-${item.id}`"
                      :disabled="item.sending"
                      size="sm"
                      variant="outline-info"
                      class="ml-1"
                      @click="onClickSendCrm(item)"
                    >
                      <b-spinner v-if="item.sending" small />
                      Send CRM
                    </b-btn>
                    <b-tooltip v-if="item.crmLogs && item.crmLogs.length" :target="`crm-button-${item.id}`" triggers="hover">
                      <ul>
                        <li v-for="log in item.crmLogs" :key="log.id">
                          {{ log.message }} @{{ log.createdAt }}
                        </li>
                      </ul>
                    </b-tooltip>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="table-pagination">
          <b-pagination
            v-if="pagination.lastPage > 1"
            v-model="pagination.currentPage"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            aria-controls="my-table"
            @change="onPageChanged"
          />
          <b-form-select
            class="col-2 mx-2"
            v-model="pagination.perPage"
            :options="perPageOptions"
            @change="onPerPageChanged"
          />
        </div>
      </b-card>
    </div>
    <b-modal
      v-if="register"
      :visible="register !== null"
      size="xl"
      title="Register Detail"
      ok-title="Close"
      ok-only
      centered
      @hidden="register = null"
    >
      <div class="container">
        <div class="row">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              Date :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ register.createdAt | dateFormat('YYYY-MM-DD (HH:mm:ss)') }}</label>
          </div>
        </div>
        <div class="row" v-for="(item, index) in register.raw" :key="`raw-${index}`">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              {{ item.label }} :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ item.value }}</label>
          </div>
        </div>
        <div class="row" v-for="(item, index) in trackings" :key="`tracking-${index}`">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              {{ item.label }} :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ register.trackings | trackingValueByName(item.name) }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              Type :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ register.template }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              Brand Name / Campaign Name :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ getCampaignName(register.ref, register.refId) }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-lg-4">
            <label class="text-right fullwidth">
              PDPA Consent :
            </label>
          </div>
          <div class="col-6 col-lg-6">
            <label>{{ register.isConsent ? 'ยินยอม' : 'ไม่ยินยอม' }}</label>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import * as XlsxPopulate from 'xlsx-populate'
import { ActionView } from '@/store/actionTypes'

const MASTER = {
  CURRENT: 5,
  AGE: 7,
  LINE: 8,
  STATUS: 9,
  OCCUPATION: 10,
  INCOME: 11,
  FAMILY_INCOME: 12,
  FREE_TIME: 13,
  KNOW_FROM: 14,
  ROOM_TYPE: 15,
  BRAND: 16,
  PROJECT: 17,
  LOCATION: 18,
  PRICE: 19,
  DICISION: 20,
  VISIT_DATE: 21,
  VISIT_TIME: 22,
  CUSTOMER: 24,
  MOVE_IN: 25,
  PROMOTION: 26
}

const dateFormat = (date, format) => moment(date).format(format)

const trackingValueByName = (trackings, name) => {
  const tracking = trackings.find((t) => t.name === name)
  return tracking ? tracking.value : null
}

const getValueFromMasterId = (raw, masterId) => {
  const data = raw.find(({ id }) => id === masterId)
  return data ? data.value : null
}

export default {
  filters: {
    trackingName (trackings, names) {
      const filtered = trackings.filter(({ name }) => names.includes(name))
      return filtered.length ? filtered.map(({ value }) => value).join(', ') : '-'
    },
    trackingValueByName,
    dateFormat
  },
  data: () => ({
    registers: [],
    pagination: {
      currentPage: 1,
      from: 0,
      to: 0,
      lastPage: 1,
      perPage: 10,
      total: 0
    },
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    trackings: [
      { name: 'utm_source', label: 'UTM Source' },
      { name: 'utm_medium', label: 'UTM Medium' },
      { name: 'utm_campaign', label: 'UTM Campaign' },
      { name: 'utm_term', label: 'UTM Term' },
      { name: 'utm_content', label: 'UTM Content' },
      { name: 'fbclid', label: 'fbclid' },
      { name: 'gclid', label: 'gclid' }
    ],
    project: null,
    type: null,
    campaign: null,
    startAt: null,
    endAt: null,
    today: moment().toDate(),
    register: null,
    sendingCrm: false
  }),
  computed: {
    ...mapGetters('projects', ['projects']),
    ...mapGetters('campaign', ['campaigns', 'brands']),
    campaignLabel () {
      switch (this.type) {
        case 'brand':
          return 'Select Brand'
        default:
          return 'Select Campaign'
      }
    },
    campaignPlaceholder () {
      switch (this.type) {
        case 'brand':
          return 'All Brand'
        case 'campaign':
          return 'All Campaign'
        default:
          return ''
      }
    },
    campaignList () {
      switch (this.type) {
        case 'brand':
          return this.brands
        case 'campaign':
          return this.campaigns
        default:
          return []
      }
    }
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    ...mapActions('projects', ['getProjects']),
    ...mapActions('campaign', ['getCampaigns']),
    ...mapActions('lead', ['resendCrm', 'resendAllCrm']),
    getRegisters (params) {
      return this.axios.get('registers', {
        params,
        baseURL: process.env.VUE_APP_API_ENDPOINT_V3,
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('cmsUser')).token}`
        }
      })
    },
    showRegister (register) {
      this.register = register
    },
    async onClickSendCrm (register) {
      register.sending = true
      this.$forceUpdate()
      try {
        const { data } = await this.resendCrm(register.id)

        if (!data.crm) {
          throw new Error('fail')
        }

        register.statusCrm = false
        this.fetchRegisters()

        this.$bvToast.toast('ส่งข้อมูลไปยัง CRM สำเร็จ', {
          title: 'ส่งข้อมูลไปยัง CRM สำเร็จ',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } catch (e) {
        this.$bvToast.toast('กรุณาติดต่อผู้ดูแลระบบ', {
          title: 'ไม่สามารถส่งข้อมูลไปยัง CRM ได้',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } finally {
        register.sending = false
        this.$forceUpdate()
      }
    },
    onClickResendAllCrm () {
      this.registers.forEach((register) => {
        if (register.statusCrm === false) {
          register.sending = true
        }
      })

      this.$forceUpdate()
      this.sendingCrm = true
      this.resendAllCrm()
    },
    async fetchRegisters () {
      this.loading(true)

      try {
        const { data: { data, pagination } } = await this.getRegisters({
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          project: this.project,
          template: this.type,
          campaign: this.campaign,
          startAt: moment(this.startAt).toISOString(),
          endAt: moment(this.endAt).toISOString()
        })

        this.registers = data
        this.pagination = pagination
        window.scrollTo(0, 0)
      } catch (e) {
      } finally {
        this.loading(false)
      }
    },
    onPageChanged (page) {
      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onPerPageChanged (perPage) {
      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, per_page: perPage })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onProjectChanged () {
      this.pagination.currentPage = 1

      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, project: this.project })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onTypeChanged () {
      this.pagination.currentPage = 1
      this.campaign = null

      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, type: this.type, campaign: null })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onCampaignChanged () {
      this.pagination.currentPage = 1

      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, campaign: this.campaign })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onStartAtChanged () {
      this.pagination.currentPage = 1

      let startAt = moment(this.startAt)

      if (startAt.isValid()) {
        this.startAt = startAt.startOf('day')
        startAt = this.startAt.toISOString()
      } else {
        this.startAt = null
        startAt = null
      }

      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, startAt })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    onEndAtChanged () {
      this.pagination.currentPage = 1

      let endAt = moment(this.endAt)

      if (endAt.isValid()) {
        this.endAt = endAt.endOf('day')
        endAt = this.endAt.toISOString()
      } else {
        this.endAt = null
        endAt = null
      }

      const query = Object.assign({}, this.$route.query)
      Object.assign(query, { page: 1, endAt })

      this.$router.push({ query }).then(this.fetchRegisters)
    },
    getCampaignName (ref, refId) {
      if (ref !== 'campaigns') {
        return '-'
      }

      const campaign = [...this.campaigns, ...this.brands].find((c) => c.id === refId)

      if (!campaign || !campaign.name) {
        return '-'
      }

      return campaign.name.th || campaign.name.en
    },
    async exportExcel () {
      this.loading(true)

      let registers = []

      try {
        const response = await this.getRegisters({
          pagination: false,
          project: this.project,
          template: this.type,
          campaign: this.campaign,
          startAt: moment(this.startAt).toISOString(),
          endAt: moment(this.endAt).toISOString()
        })
        registers = response.data
      } catch (e) {
      }

      if (registers.length === 0) {
        this.loading(false)
        return false
      }

      const sheetName = 'Sheet1'
      const workbook = await XlsxPopulate.fromBlankAsync()
      const sheet = workbook.sheet(sheetName)

      const heading = [
        '#No.',
        'Project ID',
        'Project Name',
        'Date/Time',
        'Name',
        'Email',
        'Mobile No.',
        'Location',
        'Age',
        'Line ID',
        'Status',
        'Occupation',
        'Income per month',
        'Family income',
        'Free time',
        'Know from',
        'Room type',
        'Brand',
        'Interest project',
        'Interest Location',
        'Interest Price Rate',
        'Decision time',
        'Date of Visit',
        'Time of Visit',
        'Used to be customer',
        'Period of Residence',
        'Promotion',
        'Subscribe',
        'Source',
        'UTM Source',
        'UTM Medium',
        'UTM Campaign',
        'UTM Term',
        'UTM Content',
        'fbclid',
        'gclid',
        'type',
        'Brand Name / Campaign Name',
        'PDPA Consent'
      ].map((name, index) => {
        sheet
          .row(1)
          .cell(index + 1)
          .value(name)
      })

      const body = registers.reduce((carry, register, index) => {
        const row = sheet.row(index + 2)
        carry.push(
          row.cell(1).value(index + 1),
          row.cell(2).value(register.projects.length ? register.projects[0].id : '-'),
          row.cell(3).value(register.projects.length ? register.projects[0].name : '-'),
          row.cell(4).value(moment(register.createdAt).format('YYYY-MM-DD HH:mm:ss')),
          row.cell(5).value(register.name || '-'),
          row.cell(6).value(register.email || '-'),
          row.cell(7).value(register.contact || '-'),
          row.cell(8).value(getValueFromMasterId(register.raw, MASTER.CURRENT) || '-'),
          row.cell(9).value(getValueFromMasterId(register.raw, MASTER.AGE) || '-'),
          row.cell(10).value(getValueFromMasterId(register.raw, MASTER.LINE) || '-'),
          row.cell(11).value(getValueFromMasterId(register.raw, MASTER.STATUS) || '-'),
          row.cell(12).value(getValueFromMasterId(register.raw, MASTER.OCCUPATION) || '-'),
          row.cell(13).value(getValueFromMasterId(register.raw, MASTER.INCOME) || '-'),
          row.cell(14).value(getValueFromMasterId(register.raw, MASTER.FAMILY_INCOME) || '-'),
          row.cell(15).value(getValueFromMasterId(register.raw, MASTER.FREE_TIME) || '-'),
          row.cell(16).value(getValueFromMasterId(register.raw, MASTER.KNOW_FROM) || '-'),
          row.cell(17).value(getValueFromMasterId(register.raw, MASTER.ROOM_TYPE) || '-'),
          row.cell(18).value(getValueFromMasterId(register.raw, MASTER.BRAND) || '-'),
          row.cell(19).value(getValueFromMasterId(register.raw, MASTER.PROJECT) || '-'),
          row.cell(20).value(getValueFromMasterId(register.raw, MASTER.LOCATION) || '-'),
          row.cell(21).value(getValueFromMasterId(register.raw, MASTER.PRICE) || '-'),
          row.cell(22).value(getValueFromMasterId(register.raw, MASTER.DICISION) || '-'),
          row.cell(23).value(getValueFromMasterId(register.raw, MASTER.VISIT_DATE) || '-'),
          row.cell(24).value(getValueFromMasterId(register.raw, MASTER.VISIT_TIME) || '-'),
          row.cell(25).value(getValueFromMasterId(register.raw, MASTER.CUSTOMER) || '-'),
          row.cell(26).value(getValueFromMasterId(register.raw, MASTER.MOVE_IN) || '-'),
          row.cell(27).value(getValueFromMasterId(register.raw, MASTER.PROMOTION) || '-'),
          row.cell(28).value(getValueFromMasterId(register.raw, MASTER.SUBSCRIBE) || '-'),
          row.cell(29).value('356'),
          row.cell(30).value(trackingValueByName(register.trackings, 'utm_source') || '-'),
          row.cell(31).value(trackingValueByName(register.trackings, 'utm_medium') || '-'),
          row.cell(32).value(trackingValueByName(register.trackings, 'utm_campaign') || '-'),
          row.cell(33).value(trackingValueByName(register.trackings, 'utm_term') || '-'),
          row.cell(34).value(trackingValueByName(register.trackings, 'utm_content') || '-'),
          row.cell(35).value(trackingValueByName(register.trackings, 'fbclid') || '-'),
          row.cell(36).value(trackingValueByName(register.trackings, 'gclid') || '-'),
          row.cell(37).value(register.template || '-'),
          row.cell(38).value(this.getCampaignName(register.ref, register.refId) || '-'),
          row.cell(39).value(register.isConsent ? 'ยินยอม' : 'ไม่ยินยอม')
        )

        return carry
      }, [])

      await Promise.all([...heading, ...body])
      const blob = await workbook.outputAsync()

      this.loading(false)

      const filename = `Register_${moment().format('DDMMYYHHmmss')}.xls`

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(
          blob,
          filename
        )
      } else {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        document.body.appendChild(link)

        link.href = url
        link.download = filename

        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      }
    }
  },
  async mounted () {
    let {
      page: currentPage,
      per_page: perPage,
      project,
      type,
      campaign,
      startAt,
      endAt
    } = this.$route.query
    currentPage = isNaN(parseInt(currentPage, 10)) ? this.pagination.currentPage : parseInt(currentPage, 10)
    perPage = isNaN(parseInt(perPage, 10)) ? this.pagination.perPage : parseInt(perPage, 10)

    Object.assign(this.pagination, { currentPage, perPage })
    this.project = isNaN(project) ? null : parseInt(project, 10)
    this.type = type
    this.campaign = isNaN(campaign) ? null : parseInt(campaign, 10)
    this.startAt = startAt && moment(startAt).isValid() ? moment(startAt).toDate() : null
    this.endAt = endAt && moment(endAt).isValid() ? moment(endAt).toDate() : null

    await Promise.all([
      this.getProjects(),
      this.getCampaigns()
    ])

    this.fetchRegisters()
  }
}
</script>

<style scoped>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
.btn-last {
  margin-left: 10px;
}
.text-right {
  text-align: right;
}
.fullwidth {
  width: 100%;
}
label {
  font-weight: 700;
}
</style>

export default {
  events: {
  },
  key: process.env.VUE_APP_API_FROALA,
  attribution: false,
  toolbarButtons: [
    'fontFamily',
    'fontSize',
    'color',
    'inlineStyle',
    'paragraphStyle',
    '|',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'quote',
    '|',
    'emoticons',
    'specialCharacters',
    'insertHR',
    'selectAll',
    'clearFormatting',
    '|',
    'spellChecker',
    'help',
    '|',
    'undo',
    'redo'
  ],
  fontFamilySelection: true
}

<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('brochure', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('brochure', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Download Brochure</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <form @submit.prevent="submit">
        <h5 class="my-3">Title Section</h5>
        <div class="row" style="text-align: left">
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Thai title
              <input
                type="text"
                class="form-control"
                v-model="section.title.th"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Eng title
              <input
                type="text"
                class="form-control"
                v-model="section.title.en"
              />
            </div>
          </div>
        </div>
        <hr class="bold" />
        <p class="col-form-label bold">Cover Brochure (500 x 680 pixel)</p>
        <ImageUploadNew
          :dataImage="{ ...brochure.coverPictures }"
          :uploadSetting="{ ...uploadSetting }"
          :isRequired="false"
          :showError="uploadImgCoverError"
          :maxFile="1"
          :onFileUploaded="onImageUploaded"
        />
        <br />

        <!-- <div class="form-group form-group-row">
        ALT text
        <input type="text" class="form-control">
        </div>-->
        <div class="upload-pdf-file">
          <!-- <FilesUpload
            :files="brochure.upload ? [brochure.upload] : []"
            :title="'Upload PDF File'"
            :acceptedFileTypes="'application/pdf'"
            :instantUpload="false"
            :uploadFileErrorRequired="uploadBrochureError"
            :onFileUploaded="onFileUploaded"
            :maxFileSize="'10MB'"
          /> -->
          <FileUploadNew
            :dataPdf="brochure.upload"
            :onFileUploaded="onFileUploaded"
          />
        </div>
        <br />
        <b-button-group>
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            type="submit"
            >Save</b-btn
          >
          <b-button
            size="sm"
            variant="outline-danger"
            type="button"
            @click="cancel"
            >Cancel</b-button
          >
        </b-button-group>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
import FileUploadNew from '../FileUploadNew'

export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    FileUploadNew,
    ImageUploadNew
  },
  data: () => ({
    brochure: {},
    uploadImgCoverError: false,
    uploadBrochureError: false,
    tempData: null,
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    }
  }),
  methods: {
    ...mapActions({
      getBrochure: ActionFetch.PROJECTS.BROCHURE.INDEX,
      onUpdateBrochure: ActionFetch.PROJECTS.BROCHURE.UPDATE,
      onAddBrochure: ActionFetch.PROJECTS.BROCHURE.ADD
    }),
    onFetchBrochure () {
      this.getBrochure(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.brochure) {
          this.brochure = this.$store.state.project.brochure
          this.setTempData()
        }
      })
    },
    handleBrochure () {
      if (this.$store.state.project && this.$store.state.project.brochure) {
        this.brochure = this.$store.state.project.brochure
        this.setTempData()
      } else {
        this.onFetchBrochure()
      }
    },
    onFileUploaded (data) {
      this.brochure.upload = data && data[0]
    },
    onImageUploaded ($value) {
      this.brochure.coverPictures = $value
    },
    submit (e) {
      e.preventDefault()
      this.uploadImgCoverError = false
      this.uploadBrochureError = false
      if (!this.brochure.coverPictures.source) {
        this.uploadImgCoverError = true
      }

      if (!this.brochure.upload) {
        this.uploadBrochureError = true
      }

      if (this.uploadImgCoverError || this.uploadBrochureError) {
        return
      }
      this.updateSection(this.section)
      if (this.brochure.id) {
        this.onUpdateBrochure({
          id: this.brochure.id,
          data: this.brochure
        }).then((resp) => {
          if (resp.success) {
            this.brochure = resp.data
            this.$bvToast.toast(resp.message, {
              title: 'Update',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          } else {
            alert(resp)
          }
        })
      } else {
        this.onAddBrochure(this.brochure).then((resp) => {
          if (resp.success) {
            this.$bvToast.toast(resp.message, {
              title: 'Add',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'info',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          } else {
            alert(resp)
          }
        })
      }
    },
    setTempData () {
      this.tempData = JSON.parse(JSON.stringify(this.brochure))
    },
    cancel () {
      this.brochure = JSON.parse(JSON.stringify(this.tempData))
    }
  },
  created () {
    this.brochure = {
      projectId: this.projectID,
      title: {
        th: '',
        en: ''
      },
      coverPictures: {
        source: null
      },
      upload: null,
      status: 'Active'
    }
    this.handleBrochure()
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
</style>

<template>
  <b-modal
    id="add-sub-domain"
    :title="this.getTitle()"
    size="lg"
    centered
    no-close-on-backdrop
  >
    <form id="add-sub-domain-form" @submit.prevent="submit">
      <b-form-group>
        <label for="input-formatter">Thai brand Name</label>
        <b-form-input
          v-model="category.title.th"
          type="text"
          placeholder="Category thai Name"
          required
        />
        <br />
        <label for="input-formatter">Eng brand Name</label>
        <b-form-input
          id="input-formatter"
          v-model="category.title.en"
          placeholder="Category english Name"
          aria-describedby="input-formatter-help"
          required
        />
      </b-form-group>
      <hr class="bold my-4" />
      <b-form-group>
        <label for class="label">
          URL
          <span class="red"> (use only non-capital letters, no space ) </span>
        </label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">{{ `${domain}/${lang}` }}</div>
          </div>
          <b-form-input
            v-model="category.subDomain"
            type="text"
            placeholder="subdomain name"
            required
          />
        </div>
      </b-form-group>
      <p
        v-if="category.subDomain && category.subDomain.length > 0"
        id="sub-domain-result"
      >
        URL :
        <a :href="`${domain}/${lang}/${category.subDomain}`" target="_blank">
          {{ `${domain}/${lang}/${category.subDomain}` }}
        </a>
      </p>

      <p id="sub-domain-result">Brand logo (use on sitemap)</p>

      <div class="row">
        <div class="col-md-12">
          <ImageUploadNew
            :dataImage="{ ...this.category.logo }"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :showError="uploadImgError"
            :maxFile="1"
            :onFileUploaded="onFileUploaded"
            :subject="getLogoSizeDetail"
          />
        </div>
      </div>

      <b-form-group label="SENSE">
        <b-form-select
          v-model="category.senseId"
          :options="senseList"
          class="col-12"
          required
        />
      </b-form-group>
    </form>

    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="add-sub-domain-form"
      >
        <span v-if="!category.id">Add New</span>
        <span v-else>Save</span>
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel()"> Cancel </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
export default {
  props: {
    domains: Array,
    category: {
      type: Object,
      default: () => ({
        title: { th: null, en: null }
      })
    },
    onSubmit: Function
  },
  data: () => ({
    logoSize: 'Width 100 px with proper height',
    uploadImgError: false,
    domain: process.env.VUE_APP_DOMAIN,
    lang: process.env.VUE_APP_LANG,
    uploadSetting: {
      showPreview: false,
      showDelete: true
    },
    senseList: []
  }),
  components: {
    ImageUploadNew
  },
  computed: {
    getLogoSizeDetail () {
      return `Logo (Recommented size ${this.logoSize} pixel)`
    }
  },
  methods: {
    ...mapActions({
      getList: ActionFetch.SENSE.LIST
    }),
    fetchList () {
      this.getList().then(($res) => {
        if ($res.success) {
          this.senseList = $res.data.map(($data) => {
            return { text: $data.title, value: $data.id }
          })
        }
      })
    },
    getTitle () {
      return `${this.category.id ? 'Edit' : 'Add new'} brand & URL`
    },
    submit () {
      const cat = this.domains.find(
        ({ id, subDomain }) =>
          this.category.id !== id && this.category.subDomain === subDomain
      )

      if (cat) {
        this.$bvModal.msgBoxOk(
          `SubDomain "${this.category.subDomain}" is duplicated.`
        )

        return false
      }

      if (!this.category.logo.source) {
        return false
      }

      this.onSubmit()
    },
    getLogoObj () {
      return [this.category.logo]
    },
    onFileUploaded ($value) {
      this.category.logo = $value
    }
  },
  created () {
    this.fetchList()
  },
  watch: {
    'category.subDomain' (val, oldValue) {
      let newVal = val.toLowerCase()
      newVal = newVal.replace(/[^A-Za-z0-9-]+/g, '-')
      this.category.subDomain = newVal
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

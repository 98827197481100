<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Contact</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="flex-row align-center justify-between">
          <div class="flex-col" style="flex: 1">
            <h5 class="page-title">Setting Contact Map</h5>
          </div>
        </div>

        <hr />

        <div class="form-group">
          <label>Google Map Headquater</label>
          <div class="row">
            <div class="col-5 left-label">
              <b-form inline class="mb-3">
                <label class="col-3">Latitude</label>
                <b-form-input
                  class="col-9"
                  type="text"
                  placeholder="Input latitude"
                  v-model="form.latitude"
                  required
                ></b-form-input>
              </b-form>
              <b-form inline class="mb-3">
                <label class="col-3">Longitude</label>
                <b-form-input
                  class="col-9"
                  type="text"
                  placeholder="Input longitude"
                  v-model="form.longtitude"
                  required
                ></b-form-input>
              </b-form>
              <div class="text-right">
                <b-btn
                  size="sm"
                  variant="primary"
                  class="mx-1"
                  @click="onUpdated"
                  >Update</b-btn
                >
              </div>
            </div>
            <div class="col-7">
              <googlemaps-map
                ref="map"
                class="map"
                :center.sync="center"
                :zoom.sync="zoom"
              >
                <googlemaps-marker
                  :position="position"
                  :draggable="true"
                  @dragend="updateCoordinates"
                ></googlemaps-marker>
              </googlemaps-map>
            </div>
          </div>
        </div>

        <hr />

        <div class="form-group">
          <label>Google Map Headquater</label>
          <div class="col-10 col-sm-10 col-xl-8">
            <div id="thumbnail-section">
              <div id="thumbs-1" class="thumb-item">
                <div class="flex-row">
                  <ImageUploadNew
                    :dataImage="{ ...graphicMap.th }"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="onFileUploadedTH"
                    :showError="uploadError.th"
                  />
                  <div class="flex-1">
                    <b-form-group
                      style="font-weight: bold; color: #666"
                      label="Graphic Map - TH (1900 x 800 pixel)"
                    >
                      <div class="flex-row col-10 no-padding">
                        <p
                          style="
                            margin-right: 5px;
                            flex: 1 30%;
                            line-height: 30px;
                          "
                        >
                          ALT Text
                        </p>
                        <b-form-input
                          class=""
                          v-model="form.graphicMap.th.name"
                          type="text"
                          placeholder="Typing Something..."
                        ></b-form-input>
                        <b-btn
                          size="sm"
                          variant="primary"
                          class="ml-2"
                          style="height: 35px"
                          @click="onUpdated"
                          >Update</b-btn
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div id="thumbnail-section2">
              <div id="thumbs-2" class="thumb-item">
                <div class="flex-row">
                  <ImageUploadNew
                    :dataImage="{ ...graphicMap.en }"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="onFileUploadedEN"
                    :showError="uploadError.en"
                  />
                  <div class="flex-1">
                    <b-form-group
                      style="font-weight: bold; color: #666"
                      label="Graphic Map - EN (1900 x 800 pixel)"
                    >
                      <div class="flex-row col-10 no-padding">
                        <p
                          style="
                            margin-right: 5px;
                            flex: 1 30%;
                            line-height: 30px;
                          "
                        >
                          ALT Text
                        </p>
                        <b-form-input
                          class=""
                          v-model="form.graphicMap.en.name"
                          type="text"
                          placeholder="Typing Something..."
                        ></b-form-input>
                        <b-btn
                          size="sm"
                          variant="primary"
                          class="ml-2"
                          style="height: 35px"
                          @click="onUpdated"
                          >Update</b-btn
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import ImageUploadNew from '@/components/ImageUploadNew'

export default {
  props: {},
  components: {
    ImageUploadNew
  },
  data: () => ({
    form: {
      graphicMap: {
        th: {
          source: '',
          name: ''
        },
        en: {
          source: '',
          name
        }
      }
    },
    position: {
      lat: 13.74336,
      lng: 100.54581
    },
    center: {
      lat: 13.74336,
      lng: 100.54581
    },
    zoom: 18,
    showAddressError: false,
    showDetailError: false,
    tempData: null,
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    graphicMap: {
      th: {
        source: '',
        name: ''
      },
      en: {
        source: '',
        name
      }
    },
    uploadError: {
      th: false,
      en: false
    }
  }),
  methods: {
    ...mapActions({
      getInfo: ActionFetch.CONFIG.MAP.LIST,
      updateMap: ActionFetch.CONFIG.MAP.UPDATE
    }),
    fetchMap () {
      this.getInfo().then(($res) => {
        if ($res.success) {
          this.form = $res.data

          if ($res.data.graphicMap) {
            this.graphicMap = $res.data.graphicMap
          } else {
            this.form.graphicMap = {
              th: {
                source: '',
                name: ''
              },
              en: {
                source: '',
                name
              }
            }
          }
        }
      })
    },
    updateCoordinates (location) {
      const lat = location.latLng.lat()
      const lng = location.latLng.lng()
      this.position = {
        lat,
        lng
      }
      this.form.latitude = lat
      this.form.longtitude = lng
    },
    onFileUploadedTH ($data) {
      this.graphicMap.th.source = $data.source
    },
    onFileUploadedEN ($data) {
      this.graphicMap.en.source = $data.source
    },
    onUpdated () {
      if (this.graphicMap.th.source) {
        this.form.graphicMap.th.source = this.graphicMap.th.source
        this.uploadError.th = false
      } else {
        this.uploadError.th = true
      }

      if (this.graphicMap.en.source) {
        this.form.graphicMap.en.source = this.graphicMap.en.source
        this.uploadError.en = false
      } else {
        this.uploadError.en = true
      }

      if (!this.form.latitude) {
        this.$bvToast.toast(`Please input latitude`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.longtitude) {
        this.$bvToast.toast(`Please input longitude`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.uploadError.th && !this.uploadError.en) {
        this.updateMap(this.form).then(($res) => {
          if ($res.success) {
            this.$bvToast.toast(`Update map successful`, {
              title: 'Map updated',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })

            this.form = $res.data
          }
        })
      }
    }
  },
  created () {
    this.fetchMap()
  },
  watch: {
    'form.latitude': function (val) {
      Object.assign(this.position, {
        lat: Number(val)
      })

      if (val > 0) {
        this.center = this.position
      }
    },
    'form.longtitude': function (val) {
      Object.assign(this.position, {
        lng: Number(val)
      })

      if (val > 0) {
        this.center = this.position
      }
    }
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
  min-width: 120px;
  max-width: 200px;
}

.map {
  width: 100%;
  height: 400px;
}

label {
  font-weight: bold;
}
.left-label label {
  text-align: left;
  display: block;
}
</style>

<template>
  <div class="image-input">
    <input
      type="file"
      hidden
      ref="input"
      @change="handleUpload"
      accept="image/*"
    />
    <div v-if="url && url.length > 0" @mouseover="showAction">
      <div class="image-wrapper large is-hover" v-if="size == 'lg'">
        <img :src="url" alt class="show-image large" />
        <div
          class="image-input-action"
          v-if="action_visible"
          @mouseleave="hideAction"
        >
          <a @click="refresh">
            <img src="/cms/img/icons/refresh.svg" alt class="action-icon" />
          </a>
          <a @click="clearImage">
            <img src="/cms/img/icons/delete.svg" alt class="action-icon" />
          </a>
        </div>
      </div>
      <div class="image-wrapper medium is-hover" v-else-if="size == 'md'">
        <img :src="url" alt class="show-image medium" />
        <div
          class="image-input-action"
          v-if="action_visible"
          @mouseleave="hideAction"
        >
          <a @click="refresh">
            <img src="/cms/img/icons/refresh.svg" alt class="action-icon" />
          </a>
          <a @click="clearImage">
            <img src="/cms/img/icons/delete.svg" alt class="action-icon" />
          </a>
        </div>
      </div>
      <div class="image-wrapper small is-hover" v-else-if="size == 'sm'">
        <img :src="url" alt class="show-image small" />
        <div
          class="image-input-action"
          v-if="action_visible"
          @mouseleave="hideAction"
        >
          <a @click="refresh">
            <img src="/cms/img/icons/refresh.svg" alt class="action-icon" />
          </a>
          <a @click="clearImage">
            <img src="/cms/img/icons/delete.svg" alt class="action-icon" />
          </a>
        </div>
      </div>
    </div>
    <div class="button-wrapper" v-else>
      <div
        @click="triggerUpload"
        class="image-input-btn large"
        v-if="size == 'lg'"
      >
        <i class="fal fa-plus"></i>
      </div>
      <div
        @click="triggerUpload"
        class="image-input-btn medium"
        v-else-if="size == 'md'"
      >
        <i class="fal fa-plus"></i>
      </div>
      <div
        @click="triggerUpload"
        class="image-input-btn small"
        v-else-if="size == 'sm'"
      >
        <i class="fal fa-plus"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onSelect: {
      type: Function,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'md'
    },
    url: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onRemove: {
      type: Function,
      default: () => ({})
    }
  },
  data: () => ({
    action_visible: false
  }),
  methods: {
    refresh () {
      this.triggerUpload()
    },
    clearImage () {
      this.action_visible = false
      this.onSelect({
        data: {
          url: '',
          name: ''
        },
        file: null
      })
    },
    hideAction () {
      setTimeout(() => {
        this.action_visible = false
      }, 300)
    },
    showAction () {
      if (!this.action_visible) {
        this.action_visible = true
      }
    },
    async handleUpload (e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const url = e.target.result
        this.onSelect({
          data: {
            url,
            name: file.name
          },
          file: file
        })
      }
      reader.readAsDataURL(file)
    },
    triggerUpload () {
      this.$refs.input.click()
    }
  }
}
</script>

<style scoped>
.image-input {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.image-input-action {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 1000;
}
.show-image {
  width: 100%;
  height: 100%;
}

i {
  color: #fff;
}

.small > i {
  font-size: 30px;
}

.medium > i {
  font-size: 40px;
}

.medium-image {
  max-height: 100px;
  max-width: 100px;
}

.large > i {
  font-size: 80px;
}
.large {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.image-wrapper.medium {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.image-wrapper.small {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.image-input-btn.medium {
  width: 100px;
  height: 100px;
}

.image-input-btn.small {
  width: 60px;
  height: 60px;
}
.image-input-btn:hover {
  cursor: pointer;
}
.image-input-btn {
  min-height: 50px;
  border: solid 1px #999999;
  border-style: dashed;
  background-color: #fff;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 90%;
  height: 90%;
}
.image-input-btn > i {
  color: #999999;
}
.button-wrapper {
  padding: 5px;
}

.facebook-cover .medium {
  min-width: 100px;
  min-width: 100px;
  width: auto;
  height: auto;
}
</style>

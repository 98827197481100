<template>
  <div id="register-setting-page">
    <div class="page-name">
      <span class="page-title">SEO</span>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <h5 class="my-3">Setting Google</h5>
        <b-tabs>
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.label"
            @click="selectedTab = tab.value"
            :active="selectedTab === tab.value"
          >
            <google-config v-if="tab.value === 'google_config'" />
            <tag-project-page v-else-if="tab.value === 'tag_project_page'" />
            <tag-thank-you-page v-else-if="tab.value === 'tag_thankyou_page'" />
          </b-tab>
        </b-tabs>
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import GoogleConfig from '@/components/google-seo-form/GoogleConfig'
import TagProjectPage from '@/components/google-seo-form/TagProjectPage'
import TagThankYouPage from '@/components/google-seo-form/TagThankYouPage'

export default {
  components: {
    GoogleConfig,
    TagProjectPage,
    TagThankYouPage
  },
  data: () => ({
    tabs: [
      {
        label: 'Google Config',
        value: 'google_config'
      },
      {
        label: 'Tag Project Page',
        value: 'tag_project_page'
      },
      {
        label: 'Tag Thankyou Page',
        value: 'tag_thankyou_page'
      }
    ],
    selectedTab: 'google_config'
  })
}
</script>
<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

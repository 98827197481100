<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('panorama', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('panorama', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">360/Panorama</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <h5 class="my-3">Title Section</h5>
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Thai title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="section.title.th"
              />
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Eng title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="section.title.en"
              />
            </div>
          </b-form-group>
        </div>
      </div>
      <hr class="bold" />
      <Video360Table
        :data="panoramas"
        :projectID="projectID"
        :onUpdatePanoramas="onUpdatePanoramas"
        :onAddPanoramas="onAddPanoramas"
        :onDeletePanoramas="onDeletePanoramas"
        :handlePanoramasList="onFetchPanoramasList"
        :onSortingPanoramas="onSortingPanoramas"
      />
      <b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="updateSection(section, true)"
          >Save</b-btn
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import Video360Table from '@/components/tables/Video360Table'
export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    Video360Table
  },
  data: () => ({
    enable: true,
    panoramas: []
  }),
  methods: {
    ...mapActions({
      getPanoramasList: ActionFetch.PROJECTS.PANORAMAS.INDEX,
      onUpdatePanoramas: ActionFetch.PROJECTS.PANORAMAS.UPDATE,
      onDeletePanoramas: ActionFetch.PROJECTS.PANORAMAS.DELETE,
      onAddPanoramas: ActionFetch.PROJECTS.PANORAMAS.ADD,
      onSortingPanoramas: ActionFetch.PROJECTS.PANORAMAS.SORT
    }),
    onFetchPanoramasList () {
      this.panoramas = []
      this.getPanoramasList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.panoramas) {
          this.panoramas = this.$store.state.project.panoramas
        }
      })
    },
    handlePanoramasList () {
      if (this.$store.state.project && this.$store.state.project.panoramas) {
        this.panoramas = this.$store.state.project.panoramas
      } else {
        this.onFetchPanoramasList()
      }
    }
  },
  created () {
    this.handlePanoramasList()
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}
</style>

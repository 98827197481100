<template>
  <div class="custom-color">
    <div class="flex-row align-center">
      <p class="col-form-label">{{ label }}</p>
      <i
        class="fas fa-info-circle"
        v-b-tooltip.hover
        title="Default color of selected template"
      ></i>
      <div
        class="color-box"
        :style="{ background: getColorValue(color) }"
        @click="togglePicker"
      ></div>
    </div>
    <div class="picker-wrapper" v-if="showPicker">
      <div class="picker-header">
        <span>Select Color</span>
        <i class="fas fa-times is-hover" @click="togglePicker"></i>
      </div>
      <color-picker :value="color" @input="updateValue"></color-picker>
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'

export default {
  props: {
    color: {
      type: String,
      default: '#000'
    },
    label: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'color-picker': Sketch
  },
  data: () => ({
    showPicker: false
  }),
  methods: {
    updateValue (c) {
      const { r, g, b } = c.rgba

      this.$emit('update:color', `${r},${g},${b}`)
    },
    togglePicker () {
      if (!this.disable) {
        this.showPicker = !this.showPicker
      }
    },
    getColorValue (color) {
      return `rgb(${color})`
    }
  },
  created () {
    // this.color = this.defaultColor
  }
}
</script>

<style scoped>
.picker-header {
  margin-top: 10px;
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
}
.picker-header > span {
  font-weight: bold;
}
.picker-header > i {
  color: #000;
  font-size: 20px;
}
i {
  font-size: 12px;
  margin-right: 10px;
  margin-top: 1px;
}
.custom-color {
  position: relative;
}
.picker-wrapper {
  position: absolute;
  z-index: 1000;
}
.col-form-label {
  padding: 0px !important;
  margin-right: 5px;
}
.color-box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #4ac7ff;
}
</style>

<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>#No.</th>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Project</th>
            <th>Mobile No.</th>
            <th>UTM</th>
            <th>Type</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              {{ index + 1 + paginate.page * paginate.limit - paginate.limit }}
            </td>
            <td>{{ renderDate(list.createdAt) }}</td>
            <td>{{ renderName(list.form) }}</td>
            <td>{{ renderEmail(list.form) }}</td>
            <td>{{ renderPojectList(list.projectId) }}</td>
            <td>{{ renderMobile(list.form) }}</td>
            <td>{{ list.utm_source }}</td>
            <td>{{ renderType(list.template) }}</td>
            <td class="text-center">
              <b-btn
                size="sm"
                variant="outline-info"
                v-on:click="onViewDetail(list)"
              >
                View
              </b-btn>
              <template v-if="!list.statusCRM">
                <b-btn
                  :id="`crm-button-${list.id}`"
                  size="sm"
                  variant="outline-info"
                  class="ml-1"
                  v-on:click="onSendCRM(list)"
                >
                  Send CRM
                </b-btn>
                <b-tooltip v-if="list.crmLogs && list.crmLogs.length" :target="`crm-button-${list.id}`" triggers="hover">
                  <ul>
                    <li v-for="item in list.crmLogs" :key="item.id">
                      {{ item.message }} @{{ item.createdAt }}
                    </li>
                  </ul>
                </b-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onUpdatePaginate"
        aria-controls="my-table"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    projectList: {
      type: Array,
      default: () => []
    },
    onUpdatePaginate: Function,
    onViewDetail: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    },
    onSendCRM: Function,
    onUpdateLimit: Function
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ]
  }),
  methods: {
    renderType ($type) {
      if ($type !== 'project') {
        return $type
      }

      return ''
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    renderName ($data) {
      let keyFirstname = $data.find(($data) => {
        return $data.key.title.en.toLowerCase() === 'firstname'
      })
      let keyLastname = $data.find(($data) => {
        return $data.key.title.en.toLowerCase() === 'lastname'
      })
      let str = keyFirstname ? keyFirstname.value : ''

      str += keyLastname ? ` ${keyLastname.value}` : ''

      return str
    },
    renderEmail ($data) {
      let keyEmail = $data.find(($data) => {
        return $data.key.title.en.toLowerCase() === 'email'
      })
      const str = keyEmail ? keyEmail.value : ''

      return str
    },
    renderMobile ($data) {
      let keyMobile = $data.find(($data) => {
        return $data.key.title.en.toLowerCase().includes('mobile no')
      })
      const str = keyMobile ? keyMobile.value : ''

      return str
    },
    renderPojectList ($projectId = null) {
      let str = ''
      str = ''

      if ($projectId) {
        const project = this.projectList.find(($project) => {
          return $project.value === $projectId
        })

        str = project && project.text
      }

      return str
    },
    items () {
      return this.data
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>

<template>
  <b-modal
    id="add-progress"
    size="lg"
    centered
    no-close-on-backdrop
    @hide="onHideModal"
    title="Add/Edit Progress List"
  >
    <form id="add-progress-form" @submit.prevent="submit">
      <b-row class="pro-padding">
        <b-form-group
          class="col-4"
          id="fieldset-1"
          label="Select year"
          label-for="input-1"
        >
          <b-form-select
            :required="true"
            v-model="form.year"
            :class="{
              'form-control': true,
              'has-error': this.$v.form.year.$error,
            }"
          >
            <template v-slot:first>
              <option :value="null" disabled>-- Please select year --</option>
            </template>
            <option
              v-for="selectOption in years"
              :key="selectOption"
              :value="selectOption"
            >
              {{ selectOption }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          class="col-4"
          id="fieldset-1"
          label="Select month"
          label-for="input-1"
        >
          <b-form-select
            :required="true"
            v-model="form.month"
            :class="{
              'form-control': true,
              'has-error': this.$v.form.month.$error,
            }"
          >
            <template v-slot:first>
              <option :value="null" disabled>-- Please select month --</option>
            </template>
            <option
              v-for="selectOption in month"
              :key="selectOption"
              :value="selectOption"
            >
              {{ selectOption }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          class="col-4"
          id="fieldset-1"
          label="Select building"
          label-for="input-1"
        >
          <b-form-select
            :required="true"
            v-model="form.buildingId"
            :class="{
              'form-control': true,
              'has-error': this.$v.form.buildingId.$error,
            }"
          >
            <template v-slot:first>
              <option :value="null" disabled>
                -- Please select building --
              </option>
            </template>
            <option
              v-for="(selectOption, indexOpt) in buildingList"
              :key="indexOpt"
              :value="selectOption.id"
            >
              {{ selectOption.title.en }}
            </option>
          </b-form-select>
        </b-form-group>
        <hr />
      </b-row>
      <div class="flex-row align-center justify-between">
        <h5 class="my-3">Progress List</h5>
      </div>
      <br />
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th width="80">Sort</th>
              <th>Name th</th>
              <th>Name en</th>
              <th>Percent</th>
              <th width="210"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in contentList()"
              :key="`index_${list.id}${index}`"
              :rid="list.id"
              :sync="true"
              :class="['row-data', rowIndexFocus === index ? className : '']"
            >
              <td :colspan="getColSpan(index)">
                <span>
                  <b-form-select
                    v-if="index !== 0"
                    :disabled="rowIndexFocus !== null"
                    :value="index + 1"
                    @change="(val) => sortingList(val, index + 1, list)"
                  >
                    <option
                      v-for="i in contentList().length"
                      :key="i"
                      :disabled="i === 1"
                    >
                      {{ i }}
                    </option>
                  </b-form-select>
                </span>
                <p v-if="index === 0">-</p>
                <b-form-select
                  v-if="index !== 0"
                  :required="true"
                  :value="list.id"
                  @change="(e) => changeList(e, index)"
                  class="select"
                >
                  <option
                    v-for="(selectOption, indexOpt) in attrProgress"
                    :key="indexOpt"
                    :value="selectOption.id"
                    :disabled="checkDisableProcessOption(selectOption.id)"
                  >
                    {{ selectOption.title.en }}
                  </option>
                </b-form-select>
              </td>
              <td v-if="rowIndexFocus !== index || index === 0">
                <p v-if="list.title">{{ list.title && list.title.th }}</p>
                <p v-else>{{ getTitle(list.id || list.attributeId, "th") }}</p>
              </td>
              <td v-if="rowIndexFocus !== index || index === 0">
                <p v-if="list.title">{{ list.title && list.title.en }}</p>
                <p v-else>{{ getTitle(list.id || list.attributeId, "en") }}</p>
              </td>
              <td>
                <span>{{ `${list.percent}%` }}</span>
                <b-form-input
                  type="number"
                  max="100"
                  v-model="list.percent"
                  :class="[
                    showInlineError && (list.percent < 0 || list.percent > 100)
                      ? 'has-error'
                      : '',
                  ]"
                />
              </td>
              <td>
                <b-button-group>
                  <b-btn
                    size="sm"
                    :disabled="rowIndexFocus !== null"
                    variant="primary"
                    class="btn-group-space btn-edit"
                    v-on:click="editAttribute(list, index)"
                    >Edit</b-btn
                  >
                  <b-btn
                    size="sm"
                    variant="primary"
                    class="btn-group-space btn-update"
                    v-on:click="onSaveInline(index)"
                    >Save</b-btn
                  >
                  <b-btn
                    size="sm"
                    variant="outline-danger"
                    class="btn-group-space btn-cancel-update"
                    v-on:click="cancelEditAttribute(index)"
                    >Cancel</b-btn
                  >
                  <b-btn
                    v-if="index !== 0"
                    size="sm"
                    variant="outline-danger"
                    class="btn-group-space"
                    v-on:click="deleteItemProgress(index)"
                    >Delete</b-btn
                  >
                </b-button-group>
              </td>
            </tr>
            <tr v-if="form.content.length === 0 && !form.id">
              <td colspan="5">
                <div class="text-center">
                  <i>Empty data.</i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b-form-select
                  v-model="data.attributeId"
                  :class="[showError && !data.attributeId ? 'has-error' : '']"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>
                      -- Please select task --
                    </option>
                  </template>
                  <option
                    v-for="(selectOption, indexOpt) in progressList"
                    :key="indexOpt"
                    :value="selectOption.id"
                    :disabled="checkDisableProcessOption(selectOption.id)"
                  >
                    {{ selectOption.title.en }}
                  </option>
                </b-form-select>
              </td>
              <td>
                <b-form-input
                  type="number"
                  max="100"
                  v-model="data.percent"
                  :class="[
                    showError && (data.percent < 0 || data.percent > 100)
                      ? 'has-error'
                      : '',
                  ]"
                />
              </td>
              <td>
                <b-btn
                  size="sm"
                  variant="primary"
                  class="btn-group-space"
                  v-on:click="addItem()"
                  >Add</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="clearItem()"
                  >Clear</b-btn
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <div class="flex-row align-center justify-between">
        <h5 class="my-3">Upload images</h5>
      </div>
      <div class="row">
        <div class="col-12" v-for="(list, index) in form.images" :key="index">
          <ImageUploadNew
            :dataImage="{ ...list }"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :maxFile="1"
            :subject="'Picture (Blue,Denim,Mazarine,Anil 1200x800 pixel , Ciela,Kara 950x650 pixel)'"
            :onFileUploaded="($event) => returnUpload($event, index)"
          />
        </div>
        <div class="col-12">
          <b-button block variant="outline-primary" @click="addImage">
            + Add
          </b-button>
        </div>
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="button"
        @click="submit"
        form="add-progress-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import ImageUploadNew from '@/components/ImageUploadNew'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    onFetchProgressList: Function,
    onUpdateProgress: Function,
    onAddProgress: Function,
    attrBuildings: {
      type: Array,
      default: () => []
    },
    attrProgress: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => ({
        projectId: null,
        buildingId: null,
        year: null,
        month: null,
        content: [],
        images: []
      })
    }
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    showError: false,
    showInlineError: false,
    data: {
      attributeId: null,
      percent: 0,
      status: 'Active'
    },
    years: [],
    month: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ],
    className: '',
    rowIndexFocus: null,
    uploadImageError: false,
    rowTemp: null
  }),
  validations: {
    form: {
      buildingId: {
        required
      },
      year: {
        required
      },
      month: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    returnUpload ($value, $index) {
      this.form.images[$index] = $value

      this.form.images = this.form.images.filter(($image) => {
        return !!$image.source
      })
    },
    changeList (e, index) {
      let findAttr = this.attrProgress.find(($attr) => {
        return $attr.id === e
      })

      if (!!findAttr && !findAttr.percent) {
        findAttr['percent'] = 0
      }
      const oldData = Object.assign({}, this.form.content[index])
      this.form.content[index] = findAttr
      this.form.content[index].percent = oldData.percent
    },
    addImage () {
      this.form.images.push({
        source: '',
        name: ''
      })
    },
    contentList () {
      return this.form.content
    },
    submit (e) {
      this.$v.form.$touch()

      if (!this.$v.form.$error) {
        this.showError = false
        this.uploadImageError = false

        if (
          this.form.content.length === 0 &&
          (!this.data.attributeId ||
            this.data.percent < 0 ||
            this.data.percent > 100)
        ) {
          this.showError = true
        }

        if (
          this.form.images.length === 0 ||
          (this.form.images.length >= 0 && !this.form.images[0].source)
        ) {
          this.uploadImageError = true
        }

        if (this.showError || this.uploadImageError) {
          return
        }
        e.preventDefault()
        this.onSubmit(true)
      }
    },
    onSaveInline (index) {
      this.showInlineError = false

      if (
        this.form.content[index].percent < 0 ||
        this.form.content[index].percent > 100
      ) {
        this.showInlineError = true
        return
      }

      this.rowIndexFocus = null
      this.className = ''
    },
    onSubmit (toCloseModal) {
      const data = this.reduceContent()
      this.$v.form.$reset()
      if (data.id) {
        this.onUpdateProgress({ id: data.id, data }).then((resp) => {
          this.onFetchProgressList(data.id)
          this.cancelEditAttribute()
          if (toCloseModal) {
            this.$bvModal.hide('add-progress')
          }
          this.$bvToast.toast(resp.message, {
            title: 'Update',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        })
      } else {
        this.onAddProgress(data).then((resp) => {
          this.onFetchProgressList()

          if (toCloseModal) {
            this.$bvModal.hide('add-progress')
          }

          this.$bvToast.toast(resp.message, {
            title: 'Add',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        })
      }
    },
    deleteItemProgress (index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.cancelEditAttribute(index)
            this.form.content.splice(index, 1)

            const dataForSave = this.form.content.map((each) => {
              return {
                attributeId: each.id || each.attributeId,
                percent: each.percent,
                status: each.status
              }
            })
            this.onUpdateProgress({
              id: this.form.id,
              data: dataForSave
            }).then((resp) => {
              this.onFetchProgressList(this.form.id)
            })
          }
        })
    },
    addItem () {
      this.showError = false

      if (
        !this.data.attributeId ||
        this.data.percent < 0 ||
        this.data.percent > 100
      ) {
        this.showError = true
        return
      }
      const data = this.reduceContent()
      data.content.push({ ...this.data })
      this.$emit('update:form', { ...data })
      this.clearItem()
    },
    clearItem () {
      this.data.percent = 0
      this.data.attributeId = null
    },
    reduceContent () {
      const data = { ...this.form }
      data.content = data.content.map((each, i) => ({
        attributeId: each.id || each.attributeId,
        percent: each.percent,
        status: each.status
      }))
      return data
    },
    editAttribute (data, index) {
      this.rowIndexFocus = index
      this.className = 'edit'
      this.rowTemp = JSON.parse(JSON.stringify(data))
    },
    cancelEditAttribute (index) {
      this.rowIndexFocus = null
      this.className = ''
      this.form.content[index] = { ...this.rowTemp }
      this.$emit('update:form', { ...this.form })
    },
    getColSpan (index) {
      return this.rowIndexFocus === index && index !== 0 ? 3 : 1
    },
    sortingList (newIndex, oldIndex, item) {
      this.loading(true)
      const form = JSON.parse(JSON.stringify(this.form))
      this.form.content = []

      const _newIndex = newIndex - 1 < 0 ? 0 : newIndex - 1
      const _oldIndex = oldIndex
      const data = form.content.filter((each, i) => {
        return i + 1 !== _oldIndex
      })

      this.form.content = []
      data.splice(_newIndex, 0, item)

      setTimeout(() => {
        this.form.content = data
        this.loading(false)
      }, 100)
    },
    getTitle (id, lang) {
      const progress = this.attrProgress.find((each) => each.id === id)
      return (progress && progress.title[lang]) || ''
    },
    onHideModal () {
      this.cancelEditAttribute(this.rowIndexFocus)
      this.$v.form.$reset()
      this.showError = false
      this.uploadImageError = false
      this.showInlineError = false

      this.data.attributeId = null
      this.data.percent = 0
    },
    onRemoveImage (index) {
      const img = this.form.images
      this.form.images = img.filter((each, i) => i !== index)
    },
    labelOverallTitle (lang) {
      const resp = this.attrProgress.find(
        (each) =>
          Number(process.env.VUE_APP_OVERALL_PROGRESS_ID) === Number(each.id)
      )

      return (resp && resp.title[lang]) || lang
    },
    checkDisableProcessOption (id) {
      return Number(process.env.VUE_APP_OVERALL_PROGRESS_ID) === Number(id)
    }
  },
  created () {
    const now = new Date().getFullYear()
    for (let i = now - 8; i <= now; i++) {
      this.years.push(i)
    }
  },
  computed: {
    buildingList () {
      return [...this.attrBuildings].sort(($first, $next) => {
        if ($first.orderNum > $next.orderNum) {
          return 1
        } else if ($first.orderNum < $next.orderNum) {
          return -1
        }

        return 0
      })
    },
    progressList () {
      return [...this.attrProgress].sort(($first, $next) => {
        if ($first.orderNum > $next.orderNum) {
          return 1
        } else if ($first.orderNum < $next.orderNum) {
          return -1
        }

        return 0
      })
    }
  }
}
</script>

<style>
tr.edit span,
tr.edit .btn-edit,
tr.row-data .btn-update,
tr.row-data input,
tr.row-data .select,
tr.row-data .btn-cancel-update {
  display: none;
}
tr.row-data.edit input,
tr.row-data.edit .btn-update,
tr.row-data.edit .select,
tr.row-data.edit .btn-cancel-update {
  display: inline-block;
}
.error {
  border: 1px solid red;
}
.pro-padding {
  padding: 1rem;
}
</style>

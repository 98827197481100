<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Blog</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <blog-table :data="news" />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import BlogTable from '@/components/tables/BlogTable'
import BlogService from '@/services/blog.service.js'

export default {
  components: {
    'blog-table': BlogTable
  },
  data: () => ({
    enable: true,
    news: []
  }),
  methods: {
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchNews () {
      try {
        this.loading = true
        this.news = await BlogService.list()
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    }
  },
  created () {
    this.fetchNews()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Edit Category & Subdomain</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="flex-row align-center justify-between">
          <div class="flex-col" style="flex: 1">
            <h5 class="page-title">Add/Edit Item</h5>
          </div>
        </div>
        <br />
        <form>
          <b-form-group label="Category Name">
            <b-form-input
              required
              v-model="form.company_name"
              type="text"
              placeholder="Category Name"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for class="label">
              Subdomain
              <span class="red">(use only non-capital letters, no space )</span>
            </label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">https://</div>
              </div>
              <b-form-input
                required
                v-model="form.sub_domain"
                type="text"
                placeholder="subdomain name"
              ></b-form-input>
              <div class="input-group-prepend">
                <div class="input-group-text">.grandunity.co.th</div>
              </div>
              <a
                @click="copy(list.url)"
                class="btn-copy"
                v-b-tooltip.hover.bottom
                title="Copy URL"
              >
                <i class="fas fa-clipboard"></i>
              </a>
            </div>
          </b-form-group>
          <p
            id="sub-domain-result"
            v-if="form.sub_domain && form.sub_domain.length > 0"
          >
            URL :
            <a
              :href="`https://${form.sub_domain}.grandunity.co.th`"
              target="_blank"
              >{{ `${form.sub_domain}.grandunity.co.th` }}</a
            >
          </p>
          <hr />
          <b-form-group
            style="font-weight: 600"
            label="Logo for Category (use onsitemap page)"
          >
            <!-- <multiselect :multiple="true" v-model="value" :options="options"></multiselect> -->

            <ImageUploader :multiple="false" size="lg" />
          </b-form-group>

          <hr />
          <div>
            <b-button size="sm" variant="primary" class="mx-1">Save</b-button>
            <b-button size="sm" variant="outline-danger" class="mx-1"
              >Cancel</b-button
            >
          </div>
        </form>
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader'
export default {
  data: () => ({
    value: null,
    options: ['Project A', 'Project B', 'Project C'],
    form: {
      company_name: ''
    }
  }),
  watch: {
    'form.sub_domain': function (val) {
      let newVal = val.toLowerCase()
      newVal = newVal.replace(/[^A-Za-z0-9-]+/g, '-')
      this.form.sub_domain = newVal
    }
  },
  components: {
    ImageUploader
  }
}
</script>

export const prepareAttributes = (data) => {
  const resp = {}
  let dataSorting = [...data]

  dataSorting.sort(($first, $next) => {
    let com = 0

    if ($first.module > $next.module) {
      com = 1
    } else if ($first.module < $next.module) {
      com = -1
    }

    return com
  }).sort(($first, $next) => {
    let com = 0

    if ($first.orderNum > $next.orderNum) {
      com = 1
    } else if ($first.orderNum < $next.orderNum) {
      com = -1
    }

    return com
  }).forEach(each => {
    if (!resp[each.module]) {
      resp[each.module] = {
        module: each.module,
        title: [each.title.en],
        updatedAt: each.updatedAt
      }
    } else {
      resp[each.module].title.push(each.title.en)
      resp[each.module].updatedAt = each.updatedAt
    }
  })

  return resp
}

<template>
  <div class="contact-config-container">
    <div class="form-group">
      <label label-for="input-formatter">Add New Email</label>
      <b-form inline @submit.prevent="onAddNewMail">
        <b-form-input
          class="col-6 col-lg-6 col-xl-4"
          type="text"
          placeholder="Receiver email"
          v-model="email"
        ></b-form-input>
        <b-button class="ml-3" variant="primary" type="submit"
          >Add Email</b-button
        >
      </b-form>
    </div>

    <hr />

    <div class="form-group">
      <label label-for="input-formatter">Update Receiver Email</label>
      <div inline class="mb-3 form-inline" v-for="(list, index) in emailList" :key="index">
        <b-form-input
          class="col-6 col-lg-6 col-xl-4"
          type="text"
          placeholder="Receiver email"
          v-model="list.email"
        ></b-form-input>
        <b-button
          class="ml-3"
          variant="primary"
          @click="(e) => onUpdateEmail(list)"
          >Update</b-button
        >
        <b-button
          variant="outline-danger"
          class="ml-3 btn-group-space"
          @click="(e) => onDeleteEmail(list.id)"
          >Delete</b-button
        >
      </div>
    </div>
    <div id="table-pagination" class="mt-5">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        aria-controls="my-table"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        style="max-width: 120px"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  components: {},
  data: () => ({
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    email: '',
    emailList: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: 25
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.CONFIG.EMAIL.LIST,
      createEmail: ActionFetch.CONFIG.EMAIL.CREATE,
      updateEmail: ActionFetch.CONFIG.EMAIL.UPDATE,
      deleteEmail: ActionFetch.CONFIG.EMAIL.DELETE
    }),
    onAddNewMail ($e) {
      $e.preventDefault()
      let validateResult = this.validateEmail(this.email)

      if (validateResult) {
        const emailList = {
          email: this.email,
          status: 'Active'
        }
        this.createEmail(emailList).then(($res) => {
          if ($res.success) {
            this.fetchList(this.paginate.page)
            this.email = ''
          } else {
            if ($res.message === 'Duplicate') {
              this.$bvToast.toast(`Email duplicate`, {
                title: 'Duplicate',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            } else {
              this.$bvToast.toast($res.message, {
                title: 'Error',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            }
          }
        })
      }
    },
    onDeleteEmail ($id) {
      this.$bvModal
        .msgBoxConfirm(
          `This email has been used for contact subject, do you want to delete?`,
          {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0 justify-center',
            centered: true,
            okOnly: true,
            okTitle: 'Delete'
          }
        )
        .then(($conDelete) => {
          if ($conDelete) {
            this.deleteEmail($id).then(($res) => {
              if ($res.success) {
                this.fetchList(this.paginate.page)
              } else {
                this.$bvToast.toast($res.message, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                  solid: true
                })
              }
            })
          }
        })
    },
    fetchList ($page = 1) {
      let condition = {
        orderBy: 'id',
        direction: 'desc',
        limit: this.paginate.limit,
        page: $page
      }

      this.getList(condition).then(($res) => {
        if ($res.success) {
          this.emailList = $res.data

          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    },
    onUpdateEmail ($data) {
      const validateResult = this.validateEmail($data.email)

      if (validateResult) {
        const emailPayload = {
          id: $data.id,
          email: $data.email
        }

        this.updateEmail(emailPayload).then(($res) => {
          if ($res.success) {
            this.fetchList(this.paginate.page)
          } else {
            if ($res.message === 'Duplicate') {
              this.$bvToast.toast(`Email duplicate`, {
                title: 'Duplicate',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'warning',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            } else {
              this.$bvToast.toast($res.message, {
                title: 'Error',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            }
          }
        })
      }
    },
    onUpdateLimit ($e) {
      this.paginate.limit = $e

      this.fetchList(this.paginate.page)
    },
    onUpdatePaginate ($e) {
      this.paginate.page = $e

      this.fetchList($e)
    },
    validateEmail ($email = '') {
      let result = false
      const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

      if (!$email) {
        this.$bvToast.toast(`Please input email`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (regEmail.test($email)) {
        result = true
      } else {
        this.$bvToast.toast(`Email invalid`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }

      return result
    }
  },
  created () {
    this.fetchList()
  },
  watch: {},
  computed: {}
}
</script>

<style>
label {
  font-weight: bold;
}
.contact-config-container {
  margin-top: 20px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#table-pagination .pagination {
  margin-right: 10px;
}
</style>

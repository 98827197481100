<template>
  <div class="p-3">
    <b-form-group label="Project" class="mb-4">
      <b-form-select
        v-model="form.project"
        :options="projects"
        v-on:change="changeProject"
      ></b-form-select>
    </b-form-group>

    <b-tabs class="mt-3">
      <b-tab key="0" title="ภาษาไทย">
        <b-form class="p-3">
          <b-form-group
            label="Tag Title (This will be displayed in the title bar of your Browser.)"
          >
            <b-form-input
              v-model="form.title.th"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group
            label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
          >
            <b-form-input
              v-model="form.metaTitle.th"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.th"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.th"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="GTM ID">
            <div
              v-for="(item, index) of form.gtmId.th"
              :key="index"
              class="col-6 pl-0 py-2 d-flex"
            >
              <b-input-group
                prepend="Input GTM ID"
                class="mb-2 mr-sm-2 mb-sm-0"
              >
                <b-input
                  id="inline-form-input-username"
                  placeholder="GTM-XXXXXXX"
                  :value="item"
                  @change="(e) => onUpdateGtmId(e, index, 'th')"
                ></b-input>
              </b-input-group>
              <b-button
                size="sm"
                variant="outline-danger"
                class="btn-group-space"
                @click="(e) => onDeleteGtmId(index, 'th')"
                >Delete</b-button
              >
            </div>
            <div class="col-6 pl-0 py-2 d-flex">
              <b-input-group
                prepend="Input GTM ID"
                class="mb-2 mr-sm-2 mb-sm-0"
              >
                <b-input
                  id="inline-form-input-username"
                  placeholder="GTM-XXXXXXX"
                  v-model="gtmNewTH"
                ></b-input>
              </b-input-group>
              <b-button
                size="sm"
                variant="primary"
                class="btn-group-space"
                @click="() => onAddNewGtm('th')"
                >Add</b-button
              >
            </div>
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <label class="label"
            >*Google Tag Separate by Project (Microsite) Page</label
          >
          <br />
        </b-form>
      </b-tab>
      <b-tab key="0" title="English">
        <b-form class="p-3">
          <b-form-group
            label="Tag Title (This will be displayed in the title bar of your Browser.)"
          >
            <b-form-input
              v-model="form.title.en"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group
            label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
          >
            <b-form-input
              v-model="form.metaTitle.en"
              required
              placeholder="Input meta"
            />
          </b-form-group>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.en"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.en"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="GTM ID">
            <div
              v-for="(item, index) of form.gtmId.en"
              :key="index"
              class="col-6 pl-0 py-2 d-flex"
            >
              <b-input-group
                prepend="Input GTM ID"
                class="mb-2 mr-sm-2 mb-sm-0"
              >
                <b-input
                  id="inline-form-input-username-en"
                  placeholder="GTM-XXXXXXX"
                  :value="item"
                  @change="(e) => onUpdateGtmId(e, index, 'en')"
                ></b-input>
              </b-input-group>
              <b-button
                size="sm"
                variant="outline-danger"
                class="btn-group-space"
                @click="(e) => onDeleteGtmId(index, 'en')"
                >Delete</b-button
              >
            </div>
            <div class="col-6 pl-0 py-2 d-flex">
              <b-input-group
                prepend="Input GTM ID"
                class="mb-2 mr-sm-2 mb-sm-0"
              >
                <b-input
                  id="inline-form-input-username"
                  placeholder="GTM-XXXXXXX"
                  v-model="gtmNewEN"
                ></b-input>
              </b-input-group>
              <b-button
                size="sm"
                variant="primary"
                class="btn-group-space"
                @click="() => onAddNewGtm('en')"
                >Add</b-button
              >
            </div>
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <label class="label"
            >*Google Tag Separate by Project (Microsite) Page</label
          >
          <br />
        </b-form>
      </b-tab>
    </b-tabs>

    <b-button variant="primary" v-on:click="saveSeoTag">Update</b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    projects: [],
    form: {
      project: null,
      title: {
        th: '',
        en: ''
      },
      metaDescription: {
        th: '',
        en: ''
      },
      metaTitle: {
        th: '',
        en: ''
      },
      metaKeyword: {
        th: '',
        en: ''
      },
      tagInHeader: {
        th: '',
        en: ''
      },
      tagInBody: {
        th: '',
        en: ''
      },
      gtmId: {
        th: [],
        en: []
      },
      visitor: '127.0.0.1',
      status: 'Active',
      updatedBy: ''
    },
    projectSelect: null,
    gtmNewTH: '',
    gtmNewEN: ''
  }),
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.REGISTERSETTING.PROJECTLIST,
      getTag: ActionFetch.SEO_GOOGLE.GET_TAG,
      saveTag: ActionFetch.SEO_GOOGLE.SAVE_TAG
    }),
    loadProjectList () {
      this.getProjectList().then(($res) => {
        if ($res.success) {
          this.projects = $res.data.map(($data) => {
            return { value: $data.id, text: $data.name.th }
          })
        }
        // this.form.project = $res.data[0].id;
        // this.changeProject();
      })
    },
    loadSeoTag () {
      this.getTag(this.projectSelect).then(($res) => {
        if ($res.success && $res.data) {
          this.form = { ...this.form, ...$res.data }
          this.form.project = this.projectSelect

          if (!this.form.gtmId.th) {
            this.form.gtmId.th = []
          }

          if (!this.form.gtmId.en) {
            this.form.gtmId.en = []
          }
        } else {
          this.form = {
            project: this.form.project,
            title: {
              th: '',
              en: ''
            },
            metaDescription: {
              th: '',
              en: ''
            },
            metaKeyword: {
              th: '',
              en: ''
            },
            metaTitle: {
              th: '',
              en: ''
            },
            tagInHeader: {
              th: '',
              en: ''
            },
            tagInBody: {
              th: '',
              en: ''
            },
            gtmId: {
              th: [],
              en: []
            },
            visitor: '127.0.0.1',
            status: 'Active',
            updatedBy: ''
          }
        }
      })
    },
    saveSeoTag () {
      this.form.gtmId = {
        th: this.form.gtmId.th.filter(($gtm) => {
          return !!$gtm
        }),
        en: this.form.gtmId.en.filter(($gtm) => {
          return !!$gtm
        })
      }

      this.saveTag(this.form).then(($res) => {
        if ($res.success) {
          this.loadSeoTag()
        }
      })
    },
    changeProject () {
      this.projectSelect = this.form.project
      this.gtmNewTH = ''
      this.gtmNewEN = ''
      this.loadSeoTag()
    },
    onAddNewGtm ($lang = 'th') {
      if ($lang === 'th') {
        this.form.gtmId.th.push(this.gtmNewTH)

        this.gtmNewTH = ''
      } else {
        this.form.gtmId.en.push(this.gtmNewEN)

        this.gtmNewEN = ''
      }
    },
    onUpdateGtmId ($value, $index, $lang = 'th') {
      this.form.gtmId[$lang][$index] = $value
    },
    onDeleteGtmId ($index, $lang) {
      this.form.gtmId[$lang].splice($index, 1)
    }
  },
  created () {
    this.form = {
      project: null,
      title: {
        th: '',
        en: ''
      },
      metaDescription: {
        th: '',
        en: ''
      },
      metaKeyword: {
        th: '',
        en: ''
      },
      metaTitle: {
        th: '',
        en: ''
      },
      tagInHeader: {
        th: '',
        en: ''
      },
      tagInBody: {
        th: '',
        en: ''
      },
      gtmId: {
        th: [],
        en: []
      },
      visitor: '127.0.0.1',
      status: 'Active',
      updatedBy: ''
    }

    this.loadProjectList()
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-attr","size":"xl","centered":"","title":_vm.titleModal,"no-close-on-backdrop":""},on:{"hide":_vm.onHideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v("Close")])]}}])},[_c('p',[_vm._v("Attributes list "+_vm._s(_vm.moduleName))]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("No.")]),_c('th',[_vm._v("Thai name")]),_c('th',[_vm._v("English name")]),_c('th'),_c('th'),_c('th')])]),_c('tbody',[_vm._l((_vm.orderOptions),function(row,index){return _c('tr',{key:row.id,class:['row-data', _vm.idFocus === row.id ? _vm.className : '']},[_c('td',[(
                _vm.moduleName != 'progress' ||
                (_vm.moduleName == 'progress' && row.orderNum != 1)
              )?_c('b-form-select',{attrs:{"name":"","id":"","value":row.orderNum,"sync":true},on:{"change":function($event){return _vm.updateSort($event, index)}}},_vm._l((_vm.list),function(list,index){return _c('option',{key:index,staticClass:"d-block",domProps:{"value":list.orderNum}},[_vm._v(" "+_vm._s(list.orderNum)+" ")])}),0):_vm._e()],1),_c('td',[_c('span',[_vm._v(_vm._s(row.title.th))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.title.th),expression:"row.title.th"}],staticClass:"form-control",attrs:{"type":"text","required":""},domProps:{"value":(row.title.th)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row.title, "th", $event.target.value)}}})]),_c('td',[_c('span',[_vm._v(_vm._s(row.title.en))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.title.en),expression:"row.title.en"}],staticClass:"form-control",attrs:{"type":"text","required":""},domProps:{"value":(row.title.en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row.title, "en", $event.target.value)}}})]),_c('td'),_c('td',{staticClass:"text-right"},[_c('b-btn',{staticClass:"btn-group-space btn-edit",attrs:{"size":"sm","variant":"primary","disabled":_vm.idFocus !== null},on:{"click":function($event){return _vm.editAttribute(row)}}},[_vm._v("Edit")]),_c('b-btn',{staticClass:"btn-group-space btn-update",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.updateAttribute(row)}}},[_vm._v("Save")])],1),_c('td',{staticClass:"text-left"},[_c('b-btn',{staticClass:"btn-group-space btn-cancel-update",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.cancelEditAttribute(index)}}},[_vm._v("Cancel")]),(
                _vm.moduleName != 'progress' ||
                (_vm.moduleName == 'progress' && row.orderNum != 1)
              )?_c('b-btn',{staticClass:"btn-group-space",attrs:{"size":"sm","variant":"outline-danger","disabled":_vm.idFocus !== null},on:{"click":function($event){return _vm.deleteAttribute(row.id)}}},[_vm._v("Delete")]):_vm._e()],1)])}),_c('tr',[_c('td'),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title.th),expression:"form.title.th"}],class:{
                'form-control': true,
                'has-error': this.$v.form.title.th.$error,
              },attrs:{"type":"text"},domProps:{"value":(_vm.form.title.th)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.title, "th", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title.en),expression:"form.title.en"}],class:{
                'form-control': true,
                'has-error': this.$v.form.title.en.$error,
              },attrs:{"type":"text"},domProps:{"value":(_vm.form.title.en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.title, "en", $event.target.value)}}})]),_c('td'),_c('td',{staticClass:"text-right"},[_c('b-btn',{staticClass:"btn-group-space",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addAttribute()}}},[_vm._v("Add")])],1),_c('td',{staticClass:"text-left"},[_c('b-btn',{staticClass:"btn-group-space",attrs:{"size":"sm","variant":"outline-danger","disabled":!_vm.form.title.th && !_vm.form.title.en},on:{"click":function($event){return _vm.clearAttribute()}}},[_vm._v("Clear")])],1)])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
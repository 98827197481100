<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('info', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('info', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Project Info</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <form @submit.prevent="submit">
        <h5 class="my-3">Title Section</h5>
        <br />
        <div class="row" style="text-align: left">
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Thai title
              <input
                type="text"
                class="form-control"
                v-model="section.title.th"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group form-group-row">
              Eng title
              <input
                type="text"
                class="form-control"
                v-model="section.title.en"
              />
            </div>
          </div>
        </div>
        <hr class="bold" />
        <b-tabs content-class="mt-3">
          <b-tab title="ภาษาไทย" @click="tab = 'th'" :active="tab === 'th'">
            <h5 class="project-info-input-label my-3">{{ labelInfo }}</h5>
            <TextEditor
              :content.sync="info.content.th"
              :onUpdateInfo="onUpdateInfoTH"
            />
          </b-tab>
          <b-tab title="English" @click="tab = 'en'" :active="tab === 'en'">
            <h5 class="project-info-input-label my-3">{{ labelInfo }}</h5>
            <TextEditor
              :content.sync="info.content.en"
              :onUpdateInfo="onUpdateInfoEN"
            />
          </b-tab>
          <div
            v-if="
              showError && (info.content.th === '' || info.content.en === '')
            "
            class="text-error"
          >
            * Content th/en is required.
          </div>
        </b-tabs>
        <div class="row mt-4" style="text-align: left">
          <div class="col-md-6" v-if="isVisible('picture')">
            <ImageUploadNew
              :dataImage="{ ...info.picture[0] }"
              :uploadSetting="{ ...uploadSetting }"
              :isRequired="false"
              :maxFile="1"
              :onFileUploaded="onFileUploadImage"
            />
            <p class="col-form-label bold">Picture (800 x 800 pixel)</p>
          </div>
          <div class="col-md-6" v-if="isVisible('bg')">
            <ImageUploadNew
              :dataImage="{ ...info.pictureBackground[0] }"
              :uploadSetting="{ ...uploadSetting }"
              :isRequired="false"
              :maxFile="1"
              :onFileUploaded="onFileUploadBackground"
            />
            <p class="col-form-label bold">
              Background Picture (800 x 500 pixel)
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <b-button size="sm" variant="primary" type="submit">Save</b-button>
            <b-button
              size="sm"
              variant="outline-danger"
              type="button"
              @click="cancel"
              >Cancel</b-button
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
import TextEditor from '../text-editor/TextEditor'

export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function,
    projectDataTemplate: String
  },
  components: {
    ImageUploadNew,
    TextEditor
  },
  methods: {
    ...mapActions({
      getProjectInfo: ActionFetch.PROJECTS.INFO.INDEX,
      onUpdateProjectInfo: ActionFetch.PROJECTS.INFO.UPDATE,
      onAddProjectInfo: ActionFetch.PROJECTS.INFO.ADD
    }),
    onFileUploadBackground ($value) {
      this.info.pictureBackground[0] = $value
    },
    onFileUploadImage ($value) {
      this.info.picture[0] = $value
    },
    fetchProlectInfo () {
      if (this.$store.state.project && this.$store.state.project.info) {
        this.info = this.$store.state.project.info
        this.setTempData()
      } else {
        this.getProjectInfo(this.projectID).then((resp) => {
          if (this.$store.state.project && this.$store.state.project.info) {
            this.info = this.$store.state.project.info
            this.setTempData()
          } else {
            this.info = {
              projectId: this.projectID,
              title: {
                th: '',
                en: ''
              },
              content: {
                th: '',
                en: ''
              },
              picture: [{ source: null }],
              pictureBackground: [{ source: null }]
            }
            this.setTempData()
          }
        })
      }
    },
    submit (e) {
      e.preventDefault()

      this.showError = false
      this.uploadPictureError = false
      this.uploadBgError = false

      if (this.info.content.th === '' || this.info.content.en === '') {
        this.showError = true
      }

      if (
        this.isVisible('picture') &&
        this.info.picture &&
        this.info.picture[0].source === null
      ) {
        this.uploadPictureError = true
      }

      if (
        this.isVisible('bg') &&
        this.info.pictureBackground &&
        this.info.pictureBackground[0].source === null
      ) {
        this.uploadBgError = true
      }

      if (this.showError || this.uploadPictureError || this.uploadBgError) {
        return
      }

      this.updateSection(this.section)
      if (this.info.id) {
        this.onUpdateProjectInfo({ id: this.info.id, data: this.info }).then(
          (resp) => {
            if (resp.success) {
              this.$bvToast.toast(resp.message, {
                title: 'Update',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })
            } else {
              alert(resp)
            }
          }
        )
      } else {
        this.onAddProjectInfo(this.info).then((resp) => {
          if (resp.success) {
            this.$bvToast.toast(resp.message, {
              title: 'Add project info',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          } else {
            alert(resp)
          }
        })
      }
    },
    isVisible (type) {
      if (type === 'picture') {
        return (
          this.projectDataTemplate === 't01' ||
          this.projectDataTemplate === 't04' ||
          this.projectDataTemplate === 't06'
        )
      } else {
        return (
          this.projectDataTemplate === 't02' ||
          this.projectDataTemplate === 't05'
        )
      }
    },
    setTempData () {
      this.tempData = JSON.parse(JSON.stringify(this.info))
    },
    cancel () {
      this.info = JSON.parse(JSON.stringify(this.tempData))
    },
    onUpdateInfoTH ($value) {
      this.info.content.th = $value
    },
    onUpdateInfoEN ($value) {
      this.info.content.en = $value
    }
  },
  data: () => ({
    tab: 'th',
    labelInfo: 'Project info',
    enable: true,
    imgObject: { source: null },
    info: {
      title: {},
      content: {},
      picture: [],
      pictureBackground: []
    },
    showError: false,
    uploadPictureError: false,
    uploadBgError: false,
    tempData: null,
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    }
  }),
  created () {
    this.fetchProlectInfo()
  },
  computed: {
    sectionLabel () {
      return 'Project Info'
    }
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}
.error {
  color: red;
}
/* .content {
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
} */
</style>

<template>
  <b-modal id="edit-advance-search" size="lg" centered :title="title">
    <form id="edit-advance-search-form" @submit.prevent="submit">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="TH Name">
            <b-form-input
              required
              v-model="form.title.th"
              :state="!!form.title.th"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="EN Name">
            <b-form-input
              required
              v-model="form.title.en"
              :state="!!form.title.en"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Published">
            <toggle-button :labels="true" v-model="form.status" :sync="true" />
          </b-form-group>
        </div>
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="edit-advance-search-form"
        >Save</b-button
      >
      <b-button size="sm" variant="primary" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: Object,
    onSave: Function,
    title: String
  },
  data: () => ({
    form: {}
  }),
  methods: {
    submit (e) {
      e.preventDefault()
      this.onSave(this.form)
      this.$bvModal.hide('edit-advance-search')
    }
  },
  created () {
    this.form = {
      title: {
        th: '',
        en: ''
      },
      status: false
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.form = Object.assign({}, newVal)
      this.form.title = Object.assign({}, newVal.title)
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

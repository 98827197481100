<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('video', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('video', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Video</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <h5 class="my-3">Title Section</h5>
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Thai title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                required
                class="form-control"
                v-model="section.title.th"
              />
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Eng title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                required
                class="form-control"
                v-model="section.title.en"
              />
            </div>
          </b-form-group>
        </div>
      </div>
      <hr class="bold" />
      <VideosTable
        :data="videos"
        :onUpdateVideo="onUpdateVideo"
        :onAddVideo="onAddVideo"
        :projectID="projectID"
        :onDeleteVideo="onDeleteVideo"
        :onFetchVideoList="onFetchVideoList"
        :onSortingVideo="onSortingVideo"
      />
      <b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="updateSection(section, true)"
          >Save</b-btn
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import VideosTable from '@/components/tables/VideosTable'
export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    VideosTable
  },
  data: () => ({
    videos: []
  }),
  methods: {
    ...mapActions({
      getVideoList: ActionFetch.PROJECTS.VIDEO.INDEX,
      onUpdateVideo: ActionFetch.PROJECTS.VIDEO.UPDATE,
      onDeleteVideo: ActionFetch.PROJECTS.VIDEO.DELETE,
      onAddVideo: ActionFetch.PROJECTS.VIDEO.ADD,
      onSortingVideo: ActionFetch.PROJECTS.VIDEO.SORT
    }),
    onFetchVideoList () {
      this.videos = []
      this.getVideoList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.videos) {
          this.videos = this.$store.state.project.videos
        }
      })
    },
    handleVideoList () {
      if (this.$store.state.project && this.$store.state.project.videos) {
        this.videos = this.$store.state.project.videos
      } else {
        this.onFetchVideoList()
      }
    }
  },
  created () {
    this.handleVideoList()
  }
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}
</style>

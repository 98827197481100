var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register-setting-page"}},[_vm._m(0),_c('div',{staticClass:"page-container"},[_c('b-card',[_c('div',{staticClass:"form-group form-group-row"},[_c('p',{staticClass:"mr-2"},[_vm._v(" Brand : ")]),_c('div',{staticClass:"col-8 col-lg-4 col-xl-4 custom-auto-dropdown"},[_c('b-form-select',{staticClass:"flex-fill",attrs:{"options":_vm.brands,"value-field":"id","text-field":"name.th"},on:{"change":_vm.reloadRegisterForm},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),_c('hr'),_c('div',[(_vm.brand)?_c('div',{staticClass:"col-12 col-lg-8 col-xl-8"},[_c('div',{staticClass:"pb-3"},[_vm._v(" Enable / Disable Register section in Page: "),_c('toggle-button',{attrs:{"color":"#0074d9","sync":true,"labels":true},on:{"change":_vm.onChangeRegisterStatus},model:{value:(_vm.brand.showRegister),callback:function ($$v) {_vm.$set(_vm.brand, "showRegister", $$v)},expression:"brand.showRegister"}})],1)]):_vm._e(),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Form List")]),_c('th',[_vm._v("Required")]),_c('th',[_vm._v("Show/Hide")]),_c('th')])]),(_vm.form.length)?_c('tbody',_vm._l((_vm.masters),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("masterLabel")(item)))]),_c('td',[(item.requirable !== false)?_c('toggle-button',{attrs:{"sync":true,"labels":true},on:{"change":function (ref) {
	var value = ref.value;

	return _vm.onChangeRegisterForm(item, 'required', value);
}},model:{value:(_vm.form.find(function (ref) {
	var masterId = ref.masterId;

	return masterId === item.id;
}).required),callback:function ($$v) {_vm.$set(_vm.form.find(function (ref) {
	var masterId = ref.masterId;

	return masterId === item.id;
}), "required", $$v)},expression:"form.find(({ masterId }) => masterId === item.id).required"}}):_vm._e()],1),_c('td',[_c('toggle-button',{attrs:{"sync":true,"labels":true},on:{"change":function (ref) {
	var value = ref.value;

	return _vm.onChangeRegisterForm(item, 'published', value);
}},model:{value:(_vm.form.find(function (ref) {
	var masterId = ref.masterId;

	return masterId === item.id;
}).published),callback:function ($$v) {_vm.$set(_vm.form.find(function (ref) {
	var masterId = ref.masterId;

	return masterId === item.id;
}), "published", $$v)},expression:"form.find(({ masterId }) => masterId === item.id).published"}})],1),_c('td',{staticStyle:{"text-align":"center"}},[(item.editable)?_c('b-btn',{staticClass:"btn-group-space",attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){_vm.editOptions(_vm.form.find(function (ref) {
	var masterId = ref.masterId;

	return masterId === item.id;
}))}}},[_vm._v(" Edit ")]):_vm._e()],1)])}),0):_vm._e()])])]),_c('br')],1),(_vm.brand)?_c('FormSelectEditModal',{attrs:{"ref-name":"campaign","ref-id":_vm.brand.id},on:{"saved":_vm.reloadRegisterForm}}):_vm._e(),(_vm.brand)?_c('RegisterSettingEditor',{attrs:{"ref-name":"campaign","ref-id":_vm.brand.id},on:{"saved":_vm.reloadRegisterForm}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-name"},[_c('span',{staticClass:"table-title"},[_vm._v("Register Setting")])])}]

export { render, staticRenderFns }
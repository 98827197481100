<template>
  <b-modal
    id="add-checkbox-campaign"
    size="xl"
    centered
    no-close-on-backdrop
    @hide="onHideModal"
    title="Add/Edit Register Checkbox - Campaign"
  >
    <form id="add-banner-form" @submit.prevent="submit">
      <div class="flex-row">
        <div class="col-md-6">
          <b-form-group label="Select Brand">
            <b-form-select
              class="select"
              v-model="categoryID"
              :class="{ 'has-error': !validateForm.categoryID }"
            >
              <template>
                <option :value="null" disabled>
                  -- Please select project category --
                </option>
              </template>
              <option
                v-for="(data, index) in categoryList"
                :key="index"
                :value="data.id"
              >
                {{ data.title.th }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Select Project">
            <b-form-select
              class="select"
              v-model="data.project_id"
              :class="{ 'has-error': !validateForm.projectID }"
            >
              <option :value="null" disabled>
                -- Please select project --
              </option>
              <!-- <template v-if="showProject(projectData)">
                <option
                  v-for="projectData in projectList"
                  :key="projectData.id"
                  :value="projectData.id"
                >
                  {{ projectData.name.th }}
                </option>
              </template> -->
              <template v-for="projectData in projectList">
                <option v-if="showProject(projectData)" :key="projectData.id" :value="projectData.id">
                  {{ projectData.name.th }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="flex-row col-md-12">
        <div class="thumbnail-picker-wrapper">
          <ImageUploadNew
            :isRequired="false"
            :maxFile="1"
            :uploadSetting="{ ...uploadSetting }"
            :dataImage="{ ...image }"
            :showError="uploadImgError"
            :onFileUploaded="onFileUploadImage"
          />
        </div>
        <div class="flex-1">
          <b-form-group
            style="font-weight: 600"
            label="Thumbnail (500 x 280 pixel)"
          >
            <div class="flex-row">
              <p style="margin-right: 5px; flex: 1 10%" class="alt-thumb">
                ALT Text
              </p>
              <b-form-input
                type="text"
                placeholder="Typing Something..."
                v-model="data.image.alt"
              ></b-form-input>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-12">
        <b-tabs content-class="mt-3">
          <b-tab title="ภาษาไทย">
            <b-form-group label="Price">
              <b-form-input
                type="text"
                name="priceTH"
                placeholder="Input price"
                v-model="data.price.th"
                :class="{ 'has-error': !validateForm.priceTH }"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label="Detail">
              <b-form-textarea
                name="descTH"
                v-model="data.description.th"
                placeholder="Input Detail"
                rows="3"
                max-rows="6"
                :class="{ 'has-error': !validateForm.descTH }"
              />
            </b-form-group>
            <b-form-group label="Url">
              <b-form-input
                type="text"
                name="urlTH"
                v-model="data.url.th"
                placeholder="Input URL"
              />
            </b-form-group>
          </b-tab>
          <b-tab title="English">
            <b-form-group label="Price">
              <b-form-input
                type="text"
                name="priceEN"
                placeholder="Input price"
                v-model="data.price.en"
                :class="{ 'has-error': !validateForm.priceEN }"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label="Detail">
              <b-form-textarea
                name="descEN"
                v-model="data.description.en"
                placeholder="Input Detail"
                rows="3"
                max-rows="6"
                :class="{ 'has-error': !validateForm.descEN }"
              />
            </b-form-group>
            <b-form-group label="Url">
              <b-form-input
                type="text"
                name="urlEN"
                v-model="data.url.en"
                placeholder="Input URL"
              />
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
    </form>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="add-banner-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="onHideModal()"
        >Cancel</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import ImageUploadNew from '../ImageUploadNew'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    categoryList: Array,
    data: Object,
    registerProjectList: Array,
    onSubmitForm: Function
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: false
    },
    image: {
      source: '',
      name: ''
    },
    uploadImgError: false,
    validateForm: {
      categoryID: true,
      projectID: true,
      priceTH: true,
      priceEN: true,
      descTH: true,
      descEN: true
    },
    projectList: [],
    categoryID: ''
  }),
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.MAIN.INDEX,
      getProjectInfo: ActionFetch.PROJECTS.MAIN.DETAIL
    }),
    fetchProject () {
      const condition = {
        cid: this.categoryID,
        apiVersion: '/v1'
      }
      this.getProjectList(condition).then(($res) => {
        this.projectList = $res.data
      })
    },
    fetchProjectInfo () {
      this.getProjectInfo(this.data.project_id).then(($res) => {
        this.categoryID = $res.data.category.id
      })
    },
    onFileUploadImage ($value) {
      this.data.image.filename = $value.source
    },
    showProject ($project) {
      const project = this.registerProjectList.find(($reg) => {
        return (
          $reg.project_id === $project.id && this.data.project_id !== $project.id
        )
      })

      return !project
    },
    onHideModal () {
      this.uploadImgError = false
      this.validateForm = {
        categoryID: true,
        projectID: true,
        priceTH: true,
        priceEN: true,
        descTH: true,
        descEN: true
      }
      this.$bvModal.hide('add-checkbox-campaign')
    },
    submit ($e) {
      $e.preventDefault()

      this.uploadImgError = !this.data.image.filename
      this.validateForm = {
        categoryID: !!this.categoryID,
        projectID: !!this.data.project_id,
        priceTH: !!this.data.price.th,
        priceEN: !!this.data.price.en,
        descTH: !!this.data.description.th,
        descEN: !!this.data.description.en
      }

      if (
        !this.uploadImgError &&
        this.validateForm.categoryID &&
        this.validateForm.projectID &&
        this.validateForm.priceTH &&
        this.validateForm.priceEN &&
        this.validateForm.descTH &&
        this.validateForm.descEN
      ) {
        this.onSubmitForm(this.data)
      }
    }
  },
  watch: {
    categoryID: function (value) {
      this.fetchProject()
    },
    'data.image': function (value) {
      this.image = {
        source: value.filename,
        name: value.alt
      }
    },
    'data.project_id': function (value) {
      this.fetchProjectInfo()
    }
  }
}
</script>

<style scoped>
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
.p-icon {
  min-width: 100px;
}
</style>

<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No.</th>
            <th class="text-left pl-4">Brand Name</th>
            <th class="text-left">URL</th>
            <th>Last Update</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>{{ showPageNo(index) }}</td>
            <td class="text-left pl-4">{{ list.title.en }}</td>
            <td class="text-left">
              {{ `${domain}/${lang}/${list.subDomain}` }}
            </td>
            <!-- <td>
              <span v-if="list.projects && list.projects.length > 0">
                <p
                  class="start_stop_date"
                  v-for="(project, i) in list.projects"
                  :key="i"
                >{{ project }}</p>
              </span>
              <span v-else="list.projects && list.projects.length == 0">
                <p>-</p>
              </span>
            </td>-->
            <td>{{ renderDate(list.updatedAt) }}</td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  v-on:click="addEditProjectCategory(list.id)"
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="mx-1"
                  v-on:click="onDelete(list.id, index)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        aria-controls="my-table"
        v-on:change="onUpdatePaginate"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onUpdateLimit"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onFetchList: Function,
    addEditProjectCategory: Function,
    onUpdatePaginate: Function,
    onUpdateLimit: Function,
    paginate: {
      type: Object,
      default: () => {
        return {
          limit: 10,
          page: 1,
          totalPage: 1,
          totalRow: 0
        }
      }
    }
  },
  data: () => ({
    // perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    domain: process.env.VUE_APP_DOMAIN,
    lang: process.env.VUE_APP_LANG
  }),
  methods: {
    ...mapActions({
      delete: ActionFetch.PROJECTS.CATEGORIES.DELETE
    }),
    showPageNo (i) {
      if (this.paginate.page === 1) {
        return i + 1
      } else {
        const r = i + 1
        const offset = this.paginate.limit * (this.paginate.page - 1)
        return r + offset
      }
    },
    view (id) {
      this.$router.push(`/project/sub-domain/${id}/edit`)
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, data } = this
      const start = (currentPage - 1) * this.paginate.limit
      const end =
        start + this.paginate.limit > data.length - 1
          ? data.length
          : start + this.paginate.limit
      const items = data.slice(start, end)
      return items
    },
    onDelete (id, index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.delete(id).then((resp) => {
              if (resp.success) {
                this.onFetchList()
              }
            })
          }
        })
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  },
  watch: {
    'paginate.totalRow': function (newVal, oldVal) {
      this.orderList = []
      for (let ls = 1; ls <= newVal; ls++) {
        this.orderList.push(ls)
      }
    }
  }
}
</script>

<style>
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>

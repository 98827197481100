<template>
  <div class="image-upload-item">
    <image-input
      size="md"
      :url="data.url"
      :onSelect="onSelectImage"
      :multiple="true"
      :onRemove="remove"
    />
    <div class="upload-item-container" v-if="data.url && data.url.length > 0">
      <b-form-group>
        <label for class="label">{{ data.name }}</label>
        <div class="form-group form-group-row" v-if="showText">
          ALT text
          <input
            v-if="showText"
            class="form-control"
            placeholder="Typing something..."
            v-model="data.alt"
            required
            type="text"
          />
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import ImageInput from './ImageInput'
export default {
  components: {
    ImageInput
  },
  props: {
    onSelect: {
      type: Function,
      required: true
    },
    edit: {
      type: Function,
      required: true
    },
    remove: {
      type: Function
    },
    data: {
      type: Object
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    removeItem () {
      this.remove()
    },
    onSelectImage (d) {
      this.onSelect(d)
    }
  }
}
</script>

<style scoped>
.label {
  font-size: 1.5em;
  margin-bottom: 0.8em;
}
.upload-item-container > p {
  vertical-align: middle;
}
.upload-item-container > p > button {
  margin-right: 10px;
}
.upload-item-container > p > span {
  margin-right: 10px;
  font-size: 1.4em;
  margin-bottom: 0px;
}
.image-upload-item {
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  margin-bottom: 0em;
}

.upload-item-container {
  text-align: left;
  flex: 1;
  padding: 10px;
}
</style>

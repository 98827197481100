<template>
  <b-modal
    id="add-banner"
    size="xl"
    centered
    no-close-on-backdrop
    @hide="onHideModal"
    title="Add/Edit Banner List"
  >
    <form id="add-banner-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Start Slide</span>
            <date-picker
              lang="en"
              :disabledDays="disableStartDate"
              placeholder="Select Date"
              :class="{
                'date-picker': true,
                'date-required': requiredStartDate,
              }"
              v-model="banner.startDate"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            <span class="pr-2">Date Stop Slide</span>
            <date-picker
              lang="en"
              :disabledDays="disableEndDate"
              placeholder="Select Date"
              :class="{ 'date-picker': true, 'date-required': requiredEndDate }"
              v-model="banner.endDate"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <ImageUploadNew
            :dataImage="{ ...banner.pcImages[0] }"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :showError="uploadImgPcError"
            :maxFile="1"
            :subject="'Picture Slide (PC) (1920 x 800 Pixel)'"
            :onFileUploaded="onFileUploadPcImage"
          />
        </div>
        <div class="col-md-6">
          <ImageUploadNew
            :dataImage="{ ...banner.mobileImages[0] }"
            :uploadSetting="{ ...uploadSetting }"
            :isRequired="false"
            :showError="uploadImgMobileError"
            :maxFile="1"
            :subject="'Picture Slide (Mobile) (800 x 800 Pixel)'"
            :onFileUploaded="onFileUploadMobileImage"
          />
        </div>
      </div>
      <hr />
      <h6 class="pb-3">Please fill link for click to</h6>
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            color="primary"
            :checked="banner.fileType === 'url'"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'url')"
          >
            <i slot="extra" class="icon fa fa-check"></i> URL Link
          </p-check>
        </div>
        <input
          type="text"
          v-model="banner.url.url"
          :class="{
            'form-controlsss': true,
            'form-control': true,
            'has-error': vuelidate.banner.url.$error,
          }"
          :disabled="banner.fileType !== 'url'"
          placeholder="Example - https://www.grandunity.co.th/directory"
        />
      </div>
      <br />
      <div class="flex-row align-center">
        <div class="checkbox-wrapper">
          <p-check
            name="check"
            class="p-icon p-smooth p-bigger"
            color="primary"
            :checked="banner.fileType === 'youtube'"
            style="margin-right: 20px"
            @change="(e) => swithType(e, 'youtube')"
          >
            <i slot="extra" class="icon fa fa-check"></i> Youtube Link
          </p-check>
        </div>
        <input
          type="text"
          v-model="banner.urlYoutube.url"
          :class="{
            'form-control': true,
            'has-error': vuelidate.banner.urlYoutube.$error,
          }"
          :disabled="banner.fileType !== 'youtube'"
          placeholder="Example - https://www.youtube.com/path"
        />
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button size="sm" variant="primary" type="submit" form="add-banner-form"
        >Save</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import ImageUploadNew from '../ImageUploadNew'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    editID: Number,
    form: Object,
    banner: Object,
    vuelidate: Object
  },
  components: {
    ImageUploadNew
  },
  data: () => ({
    uploadImgPcError: false,
    uploadImgMobileError: false,
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    },
    requiredStartDate: false,
    requiredEndDate: false
  }),
  methods: {
    ...mapActions([ActionFetch.BANNERS_ADD, ActionFetch.BANNERS_UPDATE]),
    submit (e) {
      this.uploadImgPcError = false
      this.uploadImgMobileError = false

      if (this.banner.pcImages && !this.banner.pcImages[0].source) {
        this.uploadImgPcError = true
      }

      if (this.banner.mobileImages && !this.banner.mobileImages[0].source) {
        this.uploadImgMobileError = true
      }

      this.requiredStartDate = !this.banner.startDate && this.banner.endDate
      this.requiredEndDate = !this.banner.endDate && this.banner.startDate

      // if (!this.banner.endDate && this.banner.startDate) {
      //   this.requiredEndDate = true
      // }

      if (
        this.uploadImgPcError ||
        this.uploadImgMobileError ||
        this.requiredStartDate ||
        this.requiredEndDate
      ) {
        return
      }

      e.preventDefault()
      this.$emit('onSubmit')
    },
    swithType (value, type) {
      this.banner.fileType = this.banner.fileType === type ? null : type
      this.vuelidate.banner.$reset()
    },
    disableStartDate (date) {
      const end = new Date(this.banner.endDate)
      return this.banner.endDate && date >= end
    },
    disableEndDate (date) {
      const start = new Date(this.banner.startDate)
      return date < start
    },
    onHideModal () {
      this.uploadImgPcError = false
      this.uploadImgMobileError = false
      this.vuelidate.banner.$reset()
    },
    onFileUploadPcImage ($value) {
      this.banner.pcImages[0] = $value
    },
    onFileUploadMobileImage ($value) {
      this.banner.mobileImages[0] = $value
    }
  }
}
</script>

<style scoped>
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
.p-icon {
  min-width: 100px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Project</div>
    </div>
    <div class="page-container">
      <b-card v-if="project" class="w-100">
        <h5 class="page-title">{{ projectName }}</h5>
        <hr />
        <setup-project
          v-if="project && categories.length"
          :enabled.sync="project_enabled"
          :form.sync="project"
          :categories="categories"
          :updateSuccess="updateSuccess"
        />
        <banner-slide
          :section.sync="section.bannerSlide"
          :enabled.sync="section.bannerSlide.status"
          :confirm.sync="this.confirm.bannerSlide"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <!-- <register :enabled="register_enabled" /> -->
        <concept
          :section="section.content"
          :enabled.sync="section.content.status"
          :confirm.sync="this.confirm.content"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :projectDataTemplate="project.template"
          :updateSection="updateSection"
        />
        <project-info
          :section="section.info"
          :enabled.sync="section.info.status"
          :confirm.sync="this.confirm.info"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :projectDataTemplate="project.template"
          :updateSection="updateSection"
        />
        <floor-plan
          :section="section.floorPlan"
          :enabled.sync="section.floorPlan.status"
          :confirm.sync="this.confirm.floorPlan"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <unit-plan
          :section="section.unitPlan"
          :enabled.sync="section.unitPlan.status"
          :confirm.sync="this.confirm.unitPlan"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <gallery
          :section="section.gallery"
          :enabled.sync="section.gallery.status"
          :confirm.sync="this.confirm.gallery"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Video
          :section="section.video"
          :enabled.sync="section.video.status"
          :confirm.sync="this.confirm.video"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Video360
          :section="section.panorama"
          :enabled.sync="section.panorama.status"
          :confirm.sync="this.confirm.panorama"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Location
          :section="section.location"
          :enabled.sync="section.location.status"
          :confirm.sync="this.confirm.location"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Progress
          :section="section.progress"
          :enabled.sync="section.progress.status"
          :confirm.sync="this.confirm.progress"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Download
          :section="section.brochure"
          :enabled.sync="section.brochure.status"
          :confirm.sync="this.confirm.brochure"
          :toggleSectionStatus="toggleSectionStatus"
          :projectID="projectID"
          :updateSection="updateSection"
        />
        <Review
          :section="section.review"
          :enabled.sync="section.review.status"
          :confirm.sync="this.confirm.review"
          :toggleSectionStatus="toggleSectionStatus"
        />

        <br />
        <div class="flex-row align-center justify-center">
          <b-button-group>
            <b-btn
              size="sm"
              variant="outline-primary"
              class="btn-group-space extra-btn"
              @click="previewMicroSite"
              >Preview Microsite</b-btn
            >
          </b-button-group>
        </div>
      </b-card>
      <div v-else class="text-center">{{ labelEmptyData }}</div>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import SetupProject from '@/components/project-form/SetupProject'
import FloorPlan from '@/components/project-form/FloorPlan'
import UnitPlan from '@/components/project-form/UnitPlan'
import Gallery from '@/components/project-form/Gallery'
import Video from '@/components/project-form/Video'
import Video360 from '@/components/project-form/Video360'
import Location from '@/components/project-form/Location'
import Progress from '@/components/project-form/Progress'
import Download from '@/components/project-form/Download'
import BannerSlide from '@/components/project-form/BannerSlide'
import Concept from '@/components/project-form/Concept'
import ProjectInfo from '@/components/project-form/ProjectInfo'
import Review from '@/components/project-form/Review'

export default {
  components: {
    SetupProject,
    FloorPlan,
    UnitPlan,
    Gallery,
    Video,
    Video360,
    Location,
    Progress,
    Download,
    Review,
    BannerSlide,
    Concept,
    ProjectInfo
  },
  data: () => ({
    sectionInit: false,
    confirm: {
      bannerSlide: true,
      content: true,
      info: true,
      floorPlan: true,
      unitPlan: true,
      gallery: true,
      video: true,
      panorama: true,
      location: true,
      progress: true,
      brochure: true,
      review: true
    },
    section: {
      bannerSlide: { status: false, title: { th: null, en: null } },
      content: { status: false, title: { th: null, en: null } },
      info: { status: false, title: { th: null, en: null } },
      floorPlan: { status: false, title: { th: null, en: null } },
      unitPlan: { status: false, title: { th: null, en: null } },
      gallery: { status: false, title: { th: null, en: null } },
      video: { status: false, title: { th: null, en: null } },
      panorama: { status: false, title: { th: null, en: null } },
      location: { status: false, title: { th: null, en: null } },
      progress: { status: false, title: { th: null, en: null } },
      brochure: { status: false, title: { th: null, en: null } },
      review: { status: false, title: { th: null, en: null } }
    },
    project_enabled: true,
    review_enabled: false,
    emptyData: 'Loading...',
    projectID: 0,
    project: null,
    limit: 0,
    categories: [],
    setupForm: {
      new_project: false,
      category: null,
      project: null,
      template: null,
      thumb_1: '',
      thumb_2: '',
      thumb_3: ''
    }
  }),
  methods: {
    ...mapActions({
      getProjectDetail: ActionFetch.PROJECTS.MAIN.DETAIL,
      getCategoryList: ActionFetch.PROJECTS.CATEGORIES.INDEX,
      getPublics: ActionFetch.PROJECTS.PUBLICS.INDEX,
      onUpdatePublics: ActionFetch.PROJECTS.PUBLICS.UPDATE
    }),
    fetchCategories () {
      const payload = {
        limit: this.limit
      }
      if (this.$store.state.project && this.$store.state.project.categories) {
        this.categories = this.$store.state.project.categories
      } else {
        this.getCategoryList(payload).then((resp) => {
          if (
            this.$store.state.project &&
            this.$store.state.project.categories
          ) {
            this.categories = this.$store.state.project.categories
          }
        })
      }
    },
    updateSuccess (msg) {
      this.$bvToast.toast(msg, {
        title: 'Project updated',
        autoHideDelay: 2000,
        appendToast: true,
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        solid: true
      })
    },
    getPublicsList () {
      if (
        this.$store.state.project &&
        this.$store.state.project.publics &&
        this.$store.state.project.publics[this.projectID]
      ) {
        this.setPublics(this.$store.state.project.publics[this.projectID])
      } else {
        this.getPublics(this.projectID).then((resp) => {
          if (
            this.$store.state.project &&
            this.$store.state.project.publics &&
            this.$store.state.project.publics[this.projectID]
          ) {
            this.setPublics(this.$store.state.project.publics[this.projectID])
          }
        })
      }
    },
    setPublics (data) {
      data.forEach((each) => {
        this.section[each.module] = {
          ...each,
          status: each.status === 'Y'
        }
      })
      this.sectionInit = true
    },
    updateAllSection () {
      const keys = Object.keys(this.section)
      keys.forEach((key, index) => {
        const each = this.section[key]
        this.updateSection(each, keys && keys.length - 1 === index)
      })
    },
    updateSection (data, isLast) {
      this.onUpdatePublics({
        id: data.id,
        data: {
          ...data,
          status: data.status ? 'Y' : 'N'
        }
      }).then((resp) => {
        if (isLast && resp.success) {
          this.brochure = resp.data
          this.$bvToast.toast(resp.message, {
            title: 'Update',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      })
    },
    toggleSectionStatus (name, value) {
      this.confirm[name] = true
      this.section[name].status = value
      if (value) {
        this.section[name].status = value
        this.updateSection(this.section[name], true)
      } else {
        this.$bvModal
          .msgBoxConfirm(`Are you sure to disable this section.`, {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0 justify-center',
            centered: true,
            okOnly: true
          })
          .then((confirm) => {
            this.confirm[name] = confirm
            this.section[name].status = !confirm
            if (confirm) {
              this.updateSection(this.section[name], true)
            }
          })
      }
    },
    previewMicroSite () {
      window.open(this.project.url, '_blank')
    }
  },
  created () {
    this.projectID = Number(this.$route.params.id)
    this.fetchCategories()
    this.getPublicsList()

    this.getProjectDetail(this.projectID).then((project) => {
      if (project.success) {
        this.project = project.data
      } else {
        this.emptyData = 'Project not found'
      }
    })
  },
  mounted () {
    this.fetchCategories()
    this.getPublicsList()
  },
  computed: {
    projectName () {
      return (this.project.name && this.project.name.en) || ''
    },
    labelEmptyData () {
      return this.emptyData
    }
  }
}
</script>

<style scoped>
.extra-btn {
  min-width: 120px;
  margin-left: 20px;
  margin-right: 20px;
}
.hide {
  display: none;
}
</style>

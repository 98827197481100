<template>
  <div class="image-input">
    <div v-if="url && url.length > 0">
      <div class="image-wrapper large is-hover" v-if="size == 'lg'">
        <img
          :src="getImagePath(url)"
          alt
          class="show-image large"
          @click="modalShow = true"
        />
      </div>
      <div class="image-wrapper medium is-hover" v-else-if="size == 'md'">
        <img
          :src="getImagePath(url)"
          alt
          class="show-image medium"
          @click="modalShow = true"
        />
      </div>
      <div class="image-wrapper small is-hover" v-else-if="size == 'sm'">
        <img
          :src="getImagePath(url)"
          alt
          class="show-image small"
          @click="modalShow = true"
        />
      </div>
    </div>
    <div class="button-wrapper" v-else>
      <div
        @click="modalShow = true"
        class="image-input-btn large"
        v-if="size == 'lg'"
      >
        <i class="fal fa-plus"></i>
      </div>
      <div
        @click="modalShow = true"
        class="image-input-btn medium"
        v-else-if="size == 'md'"
      >
        <i class="fal fa-plus"></i>
      </div>
      <div
        @click="modalShow = true"
        class="image-input-btn small"
        v-else-if="size == 'sm'"
      >
        <i class="fal fa-plus"></i>
      </div>
    </div>
    <b-modal v-model="modalShow" size="lg" centered title="Add Thumbnail">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-1 img-wrapper box"
            v-for="(img, index) in thumbnails"
            :key="index"
          >
            <img
              :src="getImagePath(img.url, index)"
              :alt="index"
              :class="selected === img.id ? 'selected' : ''"
              @click="() => select(img)"
            />
            <div class="active-icon-wrapper" v-if="selected === img.id">
              <i class="fas fa-check-circle"></i>
            </div>
          </div>
        </div>
      </div>
      <template slot="modal-footer">
        <b-button
          size="sm"
          variant="primary"
          @click="handleOnSelectImage"
          form="thumbnail-input-form"
          >Save</b-button
        >
        <b-button size="sm" variant="primary" @click="handleOnCancelImage"
          >Cancel</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    onSelect: {
      type: Function,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'md'
    },
    url: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onRemove: {
      type: Function,
      default: () => ({})
    },
    iconList: Array
  },
  data: () => ({
    thumbnails: [],
    selected: null,
    action_visible: false,
    modalShow: false,
    fileName: '',
    isChange: false,
    originUrl: ''
  }),
  methods: {
    select ({ url, id, name }) {
      if (!this.isChange) {
        this.originUrl = this.url
      }

      this.selected = id
      this.url = url
      this.fileName = name
      this.isChange = true
    },
    onSelecteImage ({ url, id, name }) {
      if (!this.isChange) {
        this.originUrl = this.url
      }

      this.url = url
      this.selected = id
      this.fileName = name
      this.isChange = true
    },
    handleOnSelectImage () {
      this.onSelect({ url: this.url, id: this.selected, name: this.fileName })
      this.modalShow = false
      this.isChange = false
    },
    handleOnCancelImage () {
      if (this.isChange) {
        this.url = this.originUrl ? this.originUrl : ''
      }

      this.selected = ''
      this.fileName = ''

      this.modalShow = false
      this.isChange = false
    },
    refresh () {
      this.clearImage()
      this.triggerUpload()
    },
    clearImage () {
      this.onRemove()
      this.action_visible = false
    },
    hideAction () {
      setTimeout(() => {
        this.action_visible = false
      }, 300)
    },
    showAction () {
      if (this.action_visible !== true) {
        this.action_visible = true
      }
    },
    async handleUpload (e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const url = e.target.result
        this.onSelect({
          url,
          name: file.name
        })
      }
      reader.readAsDataURL(file)
    },
    triggerUpload () {
      this.$refs.input.click()
    },
    getImagePath ($img = '', $index) {
      return $img
        ? `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$img}?random=${$index}`
        : ''
    }
  },
  created () {},
  watch: {
    iconList: function (iconListData) {
      this.thumbnails = []
      iconListData = iconListData.map(($icon) => {
        this.thumbnails.push({
          url: $icon.pathFile,
          id: $icon.id,
          name: $icon.pathFile
        })
        return $icon
      })
    }
  }
}
</script>

<style scoped>
.img-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.active-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-icon-wrapper > i {
  color: #007bff;
  font-size: 4em;
}

.img-wrapper > img {
  /* width: 100%;
  height: 100%; */
  background: #c7c7c7;
}

.img-wrapper > img.selected {
  cursor: pointer;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  filter: blur(5px);
  -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
}

.img-wrapper > img:hover {
  cursor: pointer;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
</style>

<style scoped>
.image-input {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.image-input-action {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 1000;
}
.show-image {
  width: 100%;
  height: 100%;
}

i {
  color: #fff;
}

.small > i {
  font-size: 30px;
}

.medium > i {
  font-size: 40px;
}

.medium-image {
  max-height: 100px;
  max-width: 100px;
}

.large > i {
  font-size: 80px;
}
.large {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.image-wrapper img {
  background: #c7c7c7;
}

.image-wrapper.medium {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.image-wrapper.small {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
}

.image-input-btn.medium {
  width: 100px;
  height: 100px;
}

.image-input-btn.small {
  width: 60px;
  height: 60px;
}
.image-input-btn:hover {
  cursor: pointer;
}
.image-input-btn {
  min-height: 50px;
  border: solid 1px #999999;
  border-style: dashed;
  background-color: #fff;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 90%;
  height: 90%;
}
.image-input-btn > i {
  color: #999999;
}
.button-wrapper {
  padding: 5px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Banner Index Page</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <h5>
          Open or Close Cover page :
          <toggle-button
            :disabled="!getCoverBannerStatus()"
            :sync="true"
            :labels="true"
            :value="getCoverBannerStatus()"
            @change="(value) => onChangeCoverBannerStatus(value, true)"
          />
        </h5>
      </b-card>
      <br />
      <b-card class="w-100">
        <BannerTable
          :data.sync="banners"
          :page="'index'"
          :onGetDetail="onGetDetailIndex"
          :onAddBanner="onAddBannerIndex"
          :onUpdateBanner="onUpdateBannerIndex"
          :onDeleteBanner="onDeleteBannerIndex"
          :onFetchBannerList="fetchBannerList"
          :onSorting="bannerSorting"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'

import BannerTable from '@/components/tables/BannerTable'
import BannerServices from '@/services/banner.service'

export default {
  components: {
    BannerTable
  },
  data: () => ({
    statusCoverBanner: 'statusCoverBanner',
    coverBanner: {},
    enable: true,
    banners: []
  }),
  methods: {
    ...mapActions({
      onGetCover: ActionFetch.SYSTEM_CONFIGS.LIST,
      onUpdateCover: ActionFetch.SYSTEM_CONFIGS.UPDATE,
      onGetListIndex: ActionFetch.BANNERS_LIST,
      onGetDetailIndex: ActionFetch.BANNERS_DETAIL,
      onAddBannerIndex: ActionFetch.BANNERS_ADD,
      onUpdateBannerIndex: ActionFetch.BANNERS_UPDATE,
      onDeleteBannerIndex: ActionFetch.BANNERS_DELETE,
      bannerSorting: ActionFetch.BANNERS_SORT
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    fetchBannerList () {
      this.onGetListIndex('index').then((resp) => {
        if (this.$store.state.banners && this.$store.state.banners.index) {
          this.banners = BannerServices.list(this.$store.state.banners.index)
          this.banners = this.banners.map(($data) => {
            if ($data.endDate) {
              const endDate = new Date($data.endDate)
              let dd = endDate.getDate()
              let mm = endDate.getMonth() + 1
              let yy = endDate.getFullYear()
              const currentEndDate = new Date(`${yy}-${mm}-${dd} 23:59:59`)
              const currentDate = new Date()

              if (currentEndDate < currentDate) {
                $data.status = 'InActive'
              }
            }
            return $data
          })
        }
      })
    },
    handleBannerList () {
      if (this.$store.state.banners && this.$store.state.banners.index) {
        this.banners = BannerServices.list(this.$store.state.banners.index)
      } else {
        this.fetchBannerList()
      }
    },
    getCoverBannerData () {
      this.onGetCover(this.statusCoverBanner).then((resp) => {
        if (
          this.$store.state.systemConfigs &&
          this.$store.state.systemConfigs[this.statusCoverBanner]
        ) {
          this.coverBanner = this.$store.state.systemConfigs[this.statusCoverBanner][0]
        }
      })
    },
    handleGetCover () {
      if (
        this.$store.state.systemConfigs &&
        this.$store.state.systemConfigs[this.statusCoverBanner]
      ) {
        this.coverBanner = this.$store.state.systemConfigs[this.statusCoverBanner][0]
      } else {
        this.getCoverBannerData()
      }
    },
    getCoverBannerStatus () {
      let ispublic =
        this.coverBanner.configValue &&
        this.coverBanner.configValue.toLowerCase() === 'active'
      return ispublic
    },
    onChangeCoverBannerStatus (value, toRefresh) {
      this.coverBanner.configValue = value.value ? 'Active' : 'InActive'
      Object.defineProperty(this.coverBanner, 'updatedAt', {
        enumerable: false
      })
      Object.defineProperty(this.coverBanner, 'createdAt', {
        enumerable: false
      })
      this.onUpdateCover(this.coverBanner)

      if (!value.value) {
        this.banners.forEach((each, index) => {
          if (this.isPublished(each)) {
            this.onUpdateBannerIndex({
              id: each.id,
              data: { ...each, status: 'InActive' }
            })
          }

          if (index + 1 === this.banners.length && toRefresh) {
            setTimeout(() => {
              this.banners = []
              this.fetchBannerList()
            }, 200)
          }
        })
      }
    },
    isPublished (banner) {
      if (banner) {
        let ispublic = banner.status.toLowerCase() === 'active'
        if (ispublic) {
          ispublic = this.isBetweenDate(banner)
        }

        return ispublic
      } else {
        return false
      }
    },
    isBetweenDate (banner) {
      const now = this.renderDate(new Date())
      const startDate = this.renderDate(new Date(banner.startDate))
      const endDate = this.renderDate(new Date(banner.endDate))
      return now >= startDate && (!banner.endDate || now <= endDate)
    }
  },
  created () {
    this.handleGetCover()
    this.handleBannerList()
  },
  watch: {
    banners: function (newValue) {
      let countPublishsed = 0

      newValue.forEach((each) => {
        if (this.isPublished(each)) {
          countPublishsed++
        }
      })

      this.onChangeCoverBannerStatus({ value: countPublishsed !== 0 }, false)
    }
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Brand &amp; Campaign</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <BrandTable
          :data="campaigns"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onDeleteCampaigns="onDeleteCampaigns"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import BrandTable from '@/components/tables/BrandCampaignTable'

export default {
  components: {
    BrandTable
  },
  data: () => ({
    campaigns: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: 0
    }
  }),
  methods: {
    ...mapActions({
      getCampaignList: ActionFetch.CAMPAIGNS.LIST,
      updateCampaigns: ActionFetch.CAMPAIGNS.UPDATE,
      updatePublish: ActionFetch.CAMPAIGNS.UPDATE_PUBLISH,
      deleteData: ActionFetch.CAMPAIGNS.DELETE
    }),
    onDeleteCampaigns ($id) {
      // this.deleteData($id).then(($res) => {
      //   if ($res.success) {
      //     this.fetchCampaigns()
      //   }
      // })
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteData($id).then(($res) => {
              this.fetchCampaigns()
            })
          }
        })
    },
    onSetActive ($data, $campaigns) {
      let dataSave = {
        id: $campaigns.id,
        published: $data.value
      }

      this.updatePublish(dataSave)
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchCampaigns()
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchCampaigns()
    },
    async fetchCampaigns () {
      let data = {
        orderBy: 'id',
        direction: 'desc',
        limit: this.paginate.limit,
        page: this.paginate.page
      }
      this.getCampaignList(data).then(($res) => {
        this.campaigns = $res.data
        this.paginate.totalPage = $res.dataLimit ? $res.dataLimit.totalPage : 1
        this.paginate.totalRow = $res.dataLimit ? $res.dataLimit.totalData : 0
      })
    }
  },
  created () {
    this.fetchCampaigns()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

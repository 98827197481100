<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Advance Search</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <tranport-link
          :data="transportlinks"
          :paginate="paginate"
          :onCreateTransport="createTransport"
          :onUpdateTransport="updateTransport"
          :onDeleteTransport="deleteTransport"
          :onPaginateChange="paginationChange"
          :onLimitChange="limitChange"
          :onUpdateSortTransport="updateSortTransport"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import TransportLinkTable from '@/components/tables/TransportLinkTable'

export default {
  components: {
    'tranport-link': TransportLinkTable
  },
  data: () => ({
    enable: true,
    transportlinks: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.TRANSPORTLINK.LIST,
      createData: ActionFetch.TRANSPORTLINK.CREATE,
      updateData: ActionFetch.TRANSPORTLINK.UPDATE,
      updateSort: ActionFetch.TRANSPORTLINK.UPDATESORT,
      deleteData: ActionFetch.TRANSPORTLINK.DELETE
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchTransportLinks () {
      const condition = {
        type: undefined,
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: this.paginate.page
      }
      this.getList(condition).then(($res) => {
        if ($res.success) {
          // this.transportlinks = ($res.data)? $res.data : []
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0

          if ($res.data) {
            this.transportlinks = $res.data.map(($data) => {
              $data.status = $data.status.toLowerCase() === 'active'

              return $data
            })
          }
        }
      })
    },
    async createTransport ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'
      this.createData(dataSave).then(($res) => {
        this.fetchTransportLinks()
      })
    },
    async updateTransport ($data) {
      let dataSave = Object.assign({}, $data)

      dataSave.status = dataSave.status ? 'Active' : 'Inactive'

      this.updateData(dataSave).then(($res) => {
        if ($res.success) {
          this.fetchTransportLinks()
        }
      })
    },
    async deleteTransport ($id) {
      this.deleteData($id).then(($res) => {
        if ($res.success) {
          this.fetchTransportLinks()
        }
      })
    },
    paginationChange ($paginate) {
      this.paginate.page = $paginate
      this.fetchTransportLinks()
    },
    limitChange ($value) {
      this.paginate.limit = $value

      this.fetchTransportLinks()
    },
    async updateSortTransport ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchTransportLinks()
        }
      })
    }
  },
  created () {
    this.fetchTransportLinks()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

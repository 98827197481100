<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Contact</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <message-table
          :data="message"
          :onUpdateSearchbox="onUpdateSearchbox"
          :paginate="paginate"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import MessageTable from '@/components/tables/MessageTable'

export default {
  components: {
    'message-table': MessageTable
  },
  data: () => ({
    enable: true,
    message: [],
    searchbox: '',
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getMailList: ActionFetch.CONFIG.MESSAGE.LIST
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchMessage ($page = 1) {
      let payload = {
        search: this.searchbox,
        orderBy: 'id',
        direction: 'DESC',
        limit: this.paginate.limit,
        page: $page
      }

      this.getMailList(payload).then(($res) => {
        if ($res.success) {
          this.message = $res.data
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchMessage()
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchMessage($paginate)
    },
    onUpdateSearchbox ($data) {
      this.searchbox = $data

      this.fetchMessage()
    }
  },
  created () {
    this.fetchMessage()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

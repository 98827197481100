const MOCK_DATA = []

for (let i = 0; i <= 121; i++) {
  MOCK_DATA[i] = {
    no: i + 1,
    sort: i + 1,
    image: 'https://picsum.photos/id/237/400/300',
    last_update: new Date(),
    type: 'image',
    title: 'Full Stack Programmer',
    content: '511 West Oxford Street Conway, SC 29526',
    published: true,
    auto_start: new Date(),
    auto_stop: new Date(),
    open_new_window: true
  }
}

const list = () => {
  return MOCK_DATA
}

export default {
  list
}

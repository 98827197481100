<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Register List - Cover page</div>
    </div>
    <b-container fluid>
      <b-row class="d-flex align-content-end align-items-end py-2">
        <b-col lg="12" xl="3">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
            >
              Select Project
            </label>
            <b-form-select
              v-model="selected"
              :options="options"
              class="flex-fill"
            ></b-form-select>
          </b-form>
        </b-col>
        <b-col lg="12" xl="6">
          <b-form inline>
            <label
              class="col-12 col-sm-2 col-lg-12 m-0 p-0 justify-content-start"
              >Select Date</label
            >
            <date-picker
              lang="en"
              placeholder="Start Date"
              class="date-picker flex-fill mr-0 my-1 my-sm-0 mr-sm-1"
              v-model="start_date"
            />
            <date-picker
              lang="en"
              placeholder="End Date"
              class="date-picker flex-fill ml-0 my-1 my-sm-0 ml-sm-1"
              v-model="end_date"
            />
          </b-form>
        </b-col>
        <b-col lg="12" xl="3">
          <b-button size="md" variant="primary" v-b-modal.form-select-edit
            >Download CSV</b-button
          >
        </b-col>
      </b-row>
    </b-container>
    <div class="page-container">
      <b-card class="w-100">
        <RegisteredTable :data="registered_list" />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import RegisteredTable from '@/components/tables/RegisteredTable'
import RegisterService from '@/services/register.service.js'

export default {
  components: {
    RegisteredTable
  },
  data: () => ({
    start_date: null,
    end_date: null,
    myDataVariable: false,
    selected: null,
    selected2: null,
    options: [
      { value: null, text: 'Project' },
      { value: 'a', text: 'Index Page' },
      { value: 'b', text: 'Index Page 2' }
    ],
    options2: [
      { value: null, text: 'UTM Fillter' },
      { value: 'a', text: 'Index Page' },
      { value: 'b', text: 'Index Page 2' }
    ],
    registered_list: []
  }),
  methods: {
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    async fetchRegsited () {
      try {
        this.loading = true
        this.registered_list = await RegisterService.list()
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    }
  },
  created () {
    this.fetchRegsited()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

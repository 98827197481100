<template>
  <b-modal
    id="register-setting-editor"
    size="xl"
    centered
    title="Edit"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    @show="onShow"
    @shown="modalShown = true"
    @hidden="modalShown = false"
  >
    <div class="container">
      <div class="my-label">ภาษาไทย</div>
      <TextEditor
        v-if="options.length"
        :modalShown="modalShown"
        :modal="true"
        :content="options[0].translations.find(({ lang }) => lang === 'th').name"
        :onUpdateInfo="(value) => options[0].translations.find(({ lang }) => lang === 'th').name = value"
      />

      <div class="space"></div>

      <div class="my-label">English</div>
      <TextEditor
        v-if="options.length"
        :modalShown="modalShown"
        :modal="true"
        :content="options[0].translations.find(({ lang }) => lang === 'en').name"
        :onUpdateInfo="(value) => options[0].translations.find(({ lang }) => lang === 'en').name = value"
      />
    </div>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        form="form-select-edit-form"
        @click="submit"
      >
        Save
      </b-button>
      <b-button size="sm" variant="danger" @click="close()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import TextEditor from '../text-editor/TextEditor'

export default {
  props: {
    refName: String,
    refId: [String, Number]
  },
  components: {
    TextEditor
  },
  data () {
    return {
      options: [],
      modalShown: false
    }
  },
  computed: {
    ...mapGetters('lead', ['formInput'])
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    ...mapActions('lead', ['updateRegisterForm', 'setFormInput', 'unsetFormInput', 'updateOptions']),
    onShow () {
      const { options } = this.formInput || {}

      this.options = [
        ...(options || []).map((o, index) => Object.assign(o, { index })).sort((a, b) => (a.ordering || 0) - (b.ordering || 0))
      ]

      this.options.forEach((option) => {
        if (!option.translations) {
          Object.assign(option, { translations: [] })
        }

        ['th', 'en'].forEach((lang) => {
          const translation = option.translations.find((t) => t.lang === lang)
          if (!translation) {
            option.translations.push({
              lang,
              name: ''
            })
          }
        })
      })

      if (this.options.length === 0) {
        this.addOption()
      }
    },
    addOption () {
      const option = {}
      this.options.push(option)

      Object.assign(option, {
        ordering: this.options.length,
        translations: [
          { lang: 'th', name: '' },
          { lang: 'en', name: '' }
        ]
      })
    },
    async submit () {
      this.loading(true)
      if (!this.formInput.id) {
        const data = await this.updateRegisterForm({
          ref: this.refName,
          refId: this.refId,
          body: [{
            op: 'add',
            path: '/0',
            value: this.formInput
          }]
        })

        this.setFormInput(data[0])
      }

      const body = []

      body.push(
        ...this.options.map((o) => ({
          op: o.id ? 'replace' : 'add',
          path: `/${o.id ? this.formInput.options.find(({ id }) => o.id === id).index : 0}`,
          value: {
            id: o.id,
            ordering: o.ordering,
            translations: o.translations
          }
        })),
        ...this.formInput.options.map((o, index) => Object.assign(o, { index }))
          .filter(({ id }) => !this.options.find((o) => o.id === id))
          .map((o) => ({
            op: 'remove',
            path: `/${o.index}`
          }))
      )

      await this.updateOptions({
        id: this.formInput.id,
        body
      })

      this.loading(false)
      this.$emit('saved')
      this.close()
    },
    close () {
      this.unsetFormInput()
      this.$bvModal.hide('register-setting-editor')
    }
  }
}
</script>

<style scoped>
.space {
  margin-bottom: 20px;
}
.my-label {
  font-size: 1em;
}
</style>

<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="20%">Attribute Groups</th>
            <th width="15%">Last Update</th>
            <th>Terms</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>{{ list.module }}</td>
            <td>{{ renderDate(list.updatedAt) }}</td>
            <td>{{ list.title.join(", ") }}</td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="primary"
                  class="btn-group-space"
                  v-on:click="updateAttribute(list.module)"
                  >Edit</b-btn
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
      <AddAttr
        :list="list"
        :moduleName="moduleName"
        :onFetchAttributeList="updateAttribute"
        :onFetchGlobalAttributeList="onFetchAttributeList"
        :onAddAttribute="onAddAttribute"
        :onUpdateAttribute="onUpdateAttribute"
        :onDeleteAttribute="onDeleteAttribute"
        :onUpdateSort="onUpdateSort"
      ></AddAttr>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'
import { prepareAttributes } from '@/services/attributes.service'
import AddAttr from '../modals/AddEditAttributesModal'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onFetchAttributeList: Function,
    // onGetDetail: Function,
    onAddAttribute: Function,
    onUpdateAttribute: Function,
    onDeleteAttribute: Function,
    onUpdateSort: Function
  },
  components: {
    AddAttr
  },
  data: () => ({
    list: [],
    moduleName: ''
  }),
  methods: {
    ...mapActions({
      onFetchList: ActionFetch.ATTRIBUTES.INDEX
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      return prepareAttributes(this.data)
    },
    updateAttribute (moduleName) {
      this.onFetchList({ moduleName, toStoreData: false }).then((resp) => {
        this.list = resp.data
        this.moduleName = moduleName
        this.$bvModal.show('add-attr')
      })
    }
  }
}
</script>

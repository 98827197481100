<template>
  <div>
    <div class="flex-row align-center justify-between">
      <span class="page-title">Create Video</span>
      <div class="flex-row">
        <b-btn
          v-on:click="addEditVideo()"
          size="sm"
          variant="primary"
          class="btn-group-space"
          >Add New Video</b-btn
        >
      </div>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Video Name</th>
            <th>URL Link</th>
            <th>Image</th>
            <th>Last Update</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>{{ list.title && list.title.en }}</td>
            <td>{{ list.video.url }}</td>
            <td>
              <img :src="videoCover(list.video)" alt class="video-cover" />
            </td>
            <td>{{ renderDate(list.updatedAt) }}</td>
            <!-- <td>{{ list.amount }}</td> -->
            <td>
              <toggle-button
                :labels="true"
                :value="getPublished(list)"
                @change="(value) => onChangeStatus(value, list)"
                color="#0074d9"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="primary"
                  class="btn-group-space"
                  v-on:click="addEditVideo(list)"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="deleteVideo(list.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="data.length === 0">
            <td colspan="7">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddVideo
      :form="form"
      :onUpdateVideo="onUpdateVideo"
      :onAddVideo="onAddVideo"
      :onFetchVideoList="onFetchVideoList"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'

import AddVideo from '../modals/AddVideo.modal'
const defaultVideoObject = {
  projectId: 0,
  title: {
    th: '',
    en: ''
  },
  video: {
    source: null
  },
  visitor: null,
  status: 'Active'
}
export default {
  props: {
    projectID: Number,
    data: {
      type: Array,
      default: () => []
    },
    onUpdateVideo: Function,
    onAddVideo: Function,
    onDeleteVideo: Function,
    onFetchVideoList: Function,
    onSortingVideo: Function
  },
  components: {
    AddVideo
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    form: null
  }),
  methods: {
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)
      return items
    },
    videoCover (video) {
      return `https://img.youtube.com/vi/${video.id}/mqdefault.jpg`
    },
    getPublished (video) {
      return video.status.toLowerCase() === 'active'
    },
    onChangeStatus (value, video) {
      video.status = value.value ? 'Active' : 'Inactive'
      this.onUpdateVideo({ id: video.id, data: video })
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSortingVideo({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.onFetchVideoList()
        }
      })
    },
    addEditVideo (data) {
      let form = {
        projectId: this.projectID,
        title: {
          th: '',
          en: ''
        },
        video: {
          source: null
        },
        visitor: null,
        status: 'Active'
      }
      if (data) {
        form = data
      }
      this.form = { ...form }
      this.$bvModal.show('add-video')
    },
    deleteVideo (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeleteVideo(id).then((resp) => {
              if (resp.success) {
                this.onFetchVideoList()
              }
            })
          }
        })
    }
  },
  created () {
    defaultVideoObject.projectId = this.projectID
    this.form = Object.assign({}, defaultVideoObject)
  }
}
</script>

<style>
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

th {
  text-align: center;
}
td {
  text-align: center;
}

.video-cover {
  max-height: 60px;
}
</style>

import format from 'date-fns/format'

const list = (banners) => {
  return banners
}
const youtubeParser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}
const prepareBannerForForm = (data, type) => {
  const { urlYoutube, url, pcImages, mobileImages, orderNum } = data || {}

  const _urlYoutube = urlYoutube || {}
  const _url = url || {}
  const _pcImages = pcImages || {}
  const _mobileImages = mobileImages || {}
  const startDate = null

  return {
    ...data,
    id: (data && data.id) || 0,
    fileType: _url.url ? 'url' : _urlYoutube.url ? 'youtube' : '',
    urlYoutube: _urlYoutube,
    url: _url,
    pcImages: _pcImages ? [_pcImages] : [{ source: null }],
    mobileImages: _mobileImages ? [_mobileImages] : [{ source: null }],
    startDate: (data && data.startDate) || startDate,
    endDate: (data && data.endDate) || null,
    orderNum: orderNum || 0,
    module: type || (data && data.type)
  }
}

const prepareBannerToCommandAPI = (data) => {
  const url = data.fileType === 'url' ? data.url : {}
  const urlYoutube = data.fileType === 'youtube' ? { ...data.urlYoutube, youtubeID: youtubeParser(data.urlYoutube.url) } : {}

  return {
    url,
    urlYoutube,
    pcImages: data.pcImages.length > 0 ? data.pcImages[0] : null,
    mobileImages: data.mobileImages.length > 0 ? data.mobileImages[0] : null,
    startDate: (data.startDate && format(data.startDate, 'YYYY-MM-DD')) || null,
    endDate: (data.endDate && format(data.endDate, 'YYYY-MM-DD')) || null,
    visitor: '000.000.000.000',
    status: data.status || 'InActive',
    orderNum: data.orderNum || 0,
    createdBy: data.createdBy,
    updatedBy: data.updatedBy,
    isOpenNewWindow: data.isOpenNewWindow || 'N',
    module: data.module
  }
}

export default {
  list,
  prepareBannerForForm,
  prepareBannerToCommandAPI
}

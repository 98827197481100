<template>
  <b-modal
    id="add-attr"
    size="xl"
    centered
    :title="titleModal"
    no-close-on-backdrop
    @hide="onHideModal"
  >
    <p>Attributes list {{ moduleName }}</p>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Thai name</th>
            <th>English name</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in orderOptions"
            :key="row.id"
            :class="['row-data', idFocus === row.id ? className : '']"
          >
            <!-- <td>{{index + 1}}</td> -->
            <td>
              <b-form-select
                name
                id
                :value="row.orderNum"
                :sync="true"
                v-on:change="updateSort($event, index)"
                v-if="
                  moduleName != 'progress' ||
                  (moduleName == 'progress' && row.orderNum != 1)
                "
              >
                <option
                  v-for="(list, index) in list"
                  :value="list.orderNum"
                  :key="index"
                  class="d-block"
                >
                  {{ list.orderNum }}
                </option>
              </b-form-select>
            </td>
            <td>
              <span>{{ row.title.th }}</span>
              <input
                type="text"
                class="form-control"
                required
                v-model="row.title.th"
              />
            </td>
            <td>
              <span>{{ row.title.en }}</span>
              <input
                type="text"
                class="form-control"
                required
                v-model="row.title.en"
              />
            </td>
            <td></td>
            <td class="text-right">
              <b-btn
                size="sm"
                variant="primary"
                :disabled="idFocus !== null"
                class="btn-group-space btn-edit"
                v-on:click="editAttribute(row)"
                >Edit</b-btn
              >

              <b-btn
                size="sm"
                variant="primary"
                class="btn-group-space btn-update"
                v-on:click="updateAttribute(row)"
                >Save</b-btn
              >
            </td>
            <td class="text-left">
              <b-btn
                size="sm"
                variant="outline-danger"
                class="btn-group-space btn-cancel-update"
                v-on:click="cancelEditAttribute(index)"
                >Cancel</b-btn
              >
              <b-btn
                size="sm"
                variant="outline-danger"
                :disabled="idFocus !== null"
                class="btn-group-space"
                v-on:click="deleteAttribute(row.id)"
                v-if="
                  moduleName != 'progress' ||
                  (moduleName == 'progress' && row.orderNum != 1)
                "
                >Delete</b-btn
              >
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'has-error': this.$v.form.title.th.$error,
                }"
                v-model="form.title.th"
              />
            </td>
            <td>
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'has-error': this.$v.form.title.en.$error,
                }"
                v-model="form.title.en"
              />
            </td>
            <td></td>
            <td class="text-right">
              <b-btn
                size="sm"
                variant="primary"
                class="btn-group-space"
                v-on:click="addAttribute()"
                >Add</b-btn
              >
            </td>
            <td class="text-left">
              <b-btn
                size="sm"
                variant="outline-danger"
                class="btn-group-space"
                :disabled="!form.title.th && !form.title.en"
                v-on:click="clearAttribute()"
                >Clear</b-btn
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">Close</b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    list: {
      type: Array,
      default: () => {}
    },
    moduleName: String,
    onFetchAttributeList: Function,
    onFetchGlobalAttributeList: Function,
    onAddAttribute: Function,
    onUpdateAttribute: Function,
    onDeleteAttribute: Function,
    onUpdateSort: Function
  },
  data: () => ({
    form: {
      title: {
        th: '',
        en: ''
      },
      status: 'Active'
    },
    className: '',
    idFocus: null,
    rowTemp: null
  }),
  validations: {
    form: {
      title: {
        th: {
          required
        },
        en: {
          required
        }
      }
    }
  },
  methods: {
    clearAttribute () {
      this.form.title = {
        th: '',
        en: ''
      }
    },
    deleteAttribute (id) {
      this.$bvModal
        .msgBoxConfirm(
          `This attribute have used in some project.
            If you delete, it will effect the display.
            Do you want to delete?`,
          {
            size: 'sm',
            buttonSize: 'sm',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0 justify-center',
            centered: true,
            // okVariant: "default",
            // okTitle: "default",
            okVariant: 'primary',
            okTitle: 'Cancel',
            cancelVariant: 'danger',
            cancelTitle: 'Delete'
          }
        )
        .then((value) => {
          if (!value) {
            this.onDeleteAttribute(id).then((resp) => {
              if (resp.success) {
                this.onFetchList()
              }
            })
          }
        })
    },
    editAttribute (data) {
      this.idFocus = data.id
      this.className = 'edit'
      this.rowTemp = JSON.parse(JSON.stringify(data))
    },
    cancelEditAttribute (index) {
      this.idFocus = null
      this.className = ''
      this.list[index] = { ...this.rowTemp }
      this.$emit('update:list', { ...this.list })
    },
    updateAttribute (data) {
      this.onUpdateAttribute({ id: data.id, data }).then((resp) => {
        if (resp.success) {
          this.className = ''
          this.idFocus = null
          this.onFetchList()
        }
      })
    },
    addAttribute () {
      this.$v.form.$touch()

      if (!this.$v.form.$error) {
        this.onAddAttribute({
          ...this.form,
          module: this.moduleName
        }).then((resp) => {
          if (resp.success) {
            this.clearAttribute()
            this.onFetchList()
            this.$v.form.$reset()
          }
        })
      }
    },
    onFetchList () {
      this.onFetchAttributeList(this.moduleName)
      this.onFetchGlobalAttributeList()
    },
    onHideModal () {
      this.clearAttribute()
      this.idFocus = null
      this.$v.form.$reset()
      this.onFetchGlobalAttributeList()
    },
    updateSort ($next, $current) {
      let dataA = this.list[$next - 1].orderNum

      this.list[$next - 1].orderNum = $current + 1
      this.list[$current].orderNum = dataA

      this.onUpdateSort({
        id: this.list[$current].id,
        module: this.list[$current].module,
        orderNew: this.list[$current].orderNum,
        orderOld: this.list[$next - 1].orderNum
      })
    }
  },
  computed: {
    titleModal () {
      return `${this.moduleName} attribute`
    },
    orderOptions () {
      return [...this.list].sort(($first, $next) => {
        if ($first.orderNum > $next.orderNum) {
          return 1
        } else if ($first.orderNum < $next.orderNum) {
          return -1
        }

        return 0
      })
    }
  }
}
</script>
<style>
tr.edit span,
tr.edit .btn-edit,
tr.row-data .btn-update,
tr.row-data input,
tr.row-data .btn-cancel-update {
  display: none;
}
tr.row-data.edit input,
tr.row-data.edit .btn-update,
tr.row-data.edit .btn-cancel-update {
  display: inline-block;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Articles</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <article-table
          :data="article"
          :paginate="paginate"
          :onSetActive="onSetActive"
          :onUpdatePaginate="onUpdatePaginate"
          :onUpdateLimit="onUpdateLimit"
          :onUpdateSort="onUpdateSort"
          :onDeleteArticle="onDeleteArticle"
        />
      </b-card>
      <br />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import ArticleTable from '@/components/tables/ArticleTable'

export default {
  components: {
    'article-table': ArticleTable
  },
  data: () => ({
    enable: true,
    article: [],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getArticleList: ActionFetch.PROJECT_ARTICLE.LIST,
      updateArticle: ActionFetch.PROJECT_ARTICLE.UPDATE,
      updateSort: ActionFetch.PROJECT_ARTICLE.UPDATESORT,
      deleteData: ActionFetch.PROJECT_ARTICLE.DELETE
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    onDeleteArticle ($id) {
      this.deleteData($id).then(($res) => {
        if ($res.success) {
          this.fetchArticle()
        }
      })
    },
    onSetActive ($data, $article) {
      $article.status = $data.value ? 'Active' : 'Inactive'

      let articleSave = {
        id: $article.id,
        status: $article.status
      }

      this.updateArticle(articleSave)
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchArticle()
    },
    onUpdateSort ($data) {
      this.updateSort($data).then(($res) => {
        if ($res.success) {
          this.fetchArticle(this.paginate.page)
        }
      })
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchArticle()
    },
    async fetchArticle () {
      let data = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: this.paginate.page
      }
      this.getArticleList(data).then(($res) => {
        if ($res.success) {
          this.article = $res.data.map(($data) => {
            if ($data.endDate) {
              const endDate = new Date($data.endDate)
              let dd = endDate.getDate()
              let mm = endDate.getMonth() + 1
              let yy = endDate.getFullYear()
              const currentEndDate = new Date(`${yy}-${mm}-${dd} 23:59:59`)
              const currentDate = new Date()

              if (currentEndDate < currentDate) {
                $data.status = 'Inactive'
              }
            }

            return $data
          })
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    }
  },
  created () {
    this.fetchArticle()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Brand &amp; URL</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="flex-row align-center justify-between">
          <div class="flex-col" style="flex: 1">
            <span class="table-title">Brand List</span>
          </div>
          <b-button
            size="sm"
            variant="primary"
            class="mb-3"
            @click="addEditProjectCategory(0)"
            >Add New</b-button
          >
        </div>
        <data-table
          v-if="subDomains.length"
          :data="subDomains"
          :onFetchList="handleList"
          :paginate="paginate"
          :onUpdateLimit="onUpdateLimit"
          :onUpdatePaginate="onUpdatePaginate"
          :addEditProjectCategory="addEditProjectCategory"
        />
        <div v-else class="text-center">
          <i>Empty data</i>
        </div>
      </b-card>
      <br />
    </div>
    <AddSubDomain
      :category="category"
      :onSubmit="onSubmitCategory"
      :domains="subDomains"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import DataTable from '@/components/tables/SubDomainTable'
import SubDomainService from '@/services/subDomain.service.js'
import AddSubDomain from '@/components/modals/AddSubDomain.modal'

export default {
  components: {
    DataTable,
    AddSubDomain
  },
  data: () => ({
    enable: true,
    subDomains: [],
    category: {
      title: { th: null, en: null }
    },
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: ''
    }
  }),
  methods: {
    ...mapActions({
      getCategoryList: ActionFetch.PROJECTS.CATEGORIES.INDEX,
      addCategory: ActionFetch.PROJECTS.CATEGORIES.ADD,
      updateCategory: ActionFetch.PROJECTS.CATEGORIES.UPDATE
    }),
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit
      this.paginate.page = 1

      this.fetchProjectCategories()
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchProjectCategories($paginate)
    },
    fetchProjectCategories ($page = 1) {
      const payload = {
        orderBy: 'orderNum',
        direction: 'asc',
        limit: this.paginate.limit,
        page: $page
      }
      this.getCategoryList(payload).then(($res) => {
        if (this.$store.state.project && this.$store.state.project.categories) {
          this.subDomains = this.$store.state.project.categories
        }
        if ($res.success) {
          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    },
    handleList () {
      if (this.$store.state.project && this.$store.state.project.categories) {
        this.subDomains = this.$store.state.project.categories
      } else {
        this.fetchProjectCategories()
      }
    },
    addEditProjectCategory (id) {
      if (id === 0) {
        this.setCategoryForm(SubDomainService.prepareSubDomainForForm())
      } else {
        const cat = this.subDomains.find((each) => each.id === id)
        const data = SubDomainService.prepareSubDomainForForm(cat)
        this.setCategoryForm(data)
      }
      this.$bvModal.show('add-sub-domain')
    },
    setCategoryForm (data) {
      this.category = data
    },
    async onSubmitCategory () {
      const data = {
        title: this.category.title,
        subDomain: this.category.subDomain,
        logo: this.category.logo,
        visitor: this.category.visitor,
        status: this.category.status,
        sense: this.category.senseId
      }

      try {
        const { success, message } = !this.category.id
          ? await this.addCategory(data)
          : await this.updateCategory({ id: this.category.id, data })

        if (success) {
          this.fetchProjectCategories()
          this.$bvModal.hide('add-sub-domain')
        }

        this.$bvToast.toast(message, {
          title: 'Create',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } catch (e) {}
    }
  },
  created () {
    this.setCategoryForm(SubDomainService.prepareSubDomainForForm())
    this.handleList()
  },
  mounted () {
    this.fetchProjectCategories()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

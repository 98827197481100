<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('content', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('content', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Concept</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <h5 class="my-3">Title Section</h5>
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Thai title
            <input
              type="text"
              class="form-control"
              v-model="section.title.th"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Eng title
            <input
              type="text"
              class="form-control"
              v-model="section.title.en"
            />
          </div>
        </div>
      </div>
      <hr class="bold" />
      <ConceptTable
        :data.sync="content"
        :projectID="projectID"
        :onAddContent="onAddContent"
        :onUpdateContent="onUpdateContent"
        :onFetchContentList="onFetchContentList"
        :onDeleteContent="onDeleteContent"
        :onSorting="onSortingContent"
        :projectDataTemplate="projectDataTemplate"
      />
      <b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="updateSection(section, true)"
          >Save</b-btn
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ConceptTable from '@/components/tables/ConceptTable'

export default {
  props: {
    projectID: Number,
    projectDataTemplate: String,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    ConceptTable
  },
  data: () => ({
    content: []
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.PROJECTS.CONTENT.INDEX,
      onUpdateContent: ActionFetch.PROJECTS.CONTENT.UPDATE,
      onDeleteContent: ActionFetch.PROJECTS.CONTENT.DELETE,
      onAddContent: ActionFetch.PROJECTS.CONTENT.ADD,
      onSortingContent: ActionFetch.PROJECTS.CONTENT.SORT
    }),
    onFetchContentList () {
      this.content = []
      this.getList(this.projectID).then((resp) => {
        if (this.$store.state.project && this.$store.state.project.content) {
          this.content = this.$store.state.project.content
        }
      })
    },
    handleContentList () {
      if (this.$store.state.project && this.$store.state.project.content) {
        this.content = this.$store.state.project.content
      } else {
        this.onFetchContentList()
      }
    }
  },
  created () {
    this.handleContentList()
  }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <span class="text">Setup Project</span>
      </div>
    </div>
    <div class="container content" v-if="enabled">
      <br />
      <form @submit.prevent="submit">
        <div id="name-section">
          <b-form-group label="Brand">
            <b-form-select
              :required="true"
              v-model="form.projectCategoryId"
              class="select"
            >
              <template v-slot:first>
                <option :value="null" disabled>
                  -- Please select project category --
                </option>
              </template>
              <option
                v-for="(selectOption, indexOpt) in categories"
                :key="indexOpt"
                :value="selectOption.id"
              >
                {{ selectOption.title.en }}
              </option>
            </b-form-select>
          </b-form-group>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Thai project name">
                <b-form-input
                  required
                  v-model="form.name.th"
                  type="text"
                  placeholder="Thai project name"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="English project name">
                <b-form-input
                  required
                  v-model="form.name.en"
                  type="text"
                  placeholder="English project name"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-form-group label="Project URL" style="margin-bottom: 5px">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">{{ currentEndpoint }}</div>
              </div>
              <b-form-input
                required
                v-model="url"
                type="text"
                placeholder="Project URL"
              ></b-form-input>
              <a
                @click="copyToClipboard()"
                class="btn-copy"
                v-b-tooltip.hover.bottom
                ref="btn-copy"
                title="Copy URL"
              >
                <i class="fas fa-clipboard"></i>
              </a>
            </div>
          </b-form-group>
          <p id="url-result" v-if="form.url && form.url.length > 0">
            URL :
            <a :href="currentURL" target="_blank">{{ currentURL }}</a>
            <input type="hidden" ref="project_url" :value="currentURL" />
          </p>
        </div>
        <hr />

        <b-tabs content-class="mt-3">
          <b-tab
            title="ภาษาไทย"
            @click="descTabLang = 'th'"
            :active="descTabLang === 'th'"
          >
            <TextEditor
              :content="form.description.th"
              :onUpdateInfo="(e) => onUpdateContent(e, 'th')"
            />
          </b-tab>
          <b-tab
            title="English"
            @click="descTabLang = 'en'"
            :active="descTabLang === 'en'"
          >
            <TextEditor
              :content="form.description.en"
              :onUpdateInfo="(e) => onUpdateContent(e, 'en')"
            />
          </b-tab>
        </b-tabs>

        <hr />

        <div id="status-section">
          <div class="d-flex justify-content-between">
            <div class="checkbox-wrapper">
              <p-check
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                v-model="form.isNewProject"
                true-value="Y"
                false-value="N"
              >
                <i slot="extra" class="icon fa fa-check"></i>New Project
              </p-check>
            </div>
            <div class="checkbox-wrapper">
              <p-check
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                v-model="form.isReadyToMoveIn"
                true-value="Y"
                false-value="N"
              >
                <i slot="extra" class="icon fa fa-check"></i>Ready to move in
              </p-check>
            </div>
            <div class="checkbox-wrapper">
              <p-check
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                v-model="form.isSoldOut"
                true-value="Y"
                false-value="N"
              >
                <i slot="extra" class="icon fa fa-check"></i>Sold Out
              </p-check>
            </div>
            <div class="checkbox-wrapper" />
          </div>
        </div>
        <hr />
        <div id="logo-section" class="flex-row">
          <div class="flex-col flex-1" style="padding-right: 40px">
            <!-- {{ form.projectLogo }}
            <ImagesUpload :multiple="false" :images="[form.projectLogo]" :requiredName="false" header="Project Logo (width 100 px with proper height)" size="lg" :uploadImageErrorRequired="uploadLogoError" /> -->

            <ImageUploadNew
              :dataImage="{ ...form.projectLogo }"
              :uploadSetting="{ ...uploadSetting }"
              :isRequired="false"
              :showError="uploadLogoError"
              :maxFile="1"
              :subject="'Project Logo (width 100 px with proper height)'"
              :onFileUploaded="onFileUploadLogo"
            />
          </div>
          <div class="flex-col flex-1" style="padding-right: 40px">
            <!-- {{ form.projectThumb }}
            <ImagesUpload :multiple="false" :images="[form.projectThumb]" :requiredName="false" header="Project Thumbnail (500 x 600 pixel)" size="lg" :uploadImageErrorRequired="uploadProjectThumbError" /> -->
            <ImageUploadNew
              :dataImage="{ ...form.projectThumb }"
              :uploadSetting="{ ...uploadSetting }"
              :isRequired="false"
              :showError="uploadProjectThumbError"
              :maxFile="1"
              :subject="'Project Thumbnail (500 x 600 pixel)'"
              :onFileUploaded="onFileUploadThumbnail"
            />
          </div>
        </div>
        <hr />
        <div id="thumbnail-section">
          <p class="col-form-label">Project Thumbnail Details</p>
          <br />
          <div id="thumbs-1" class="thumb-item">
            <div class="flex-row">
              <div class="thumbnail-picker-wrapper">
                <thumbnail-input
                  size="sm"
                  :url="form.projectIcon1 && form.projectIcon1.source"
                  :onSelect="(file) => onSelectImage('projectIcon1', file)"
                  :iconList="iconList"
                ></thumbnail-input>
              </div>
              <div class="w-100">
                <p>Select icon & Typing Description</p>
                <div class="row">
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Thai title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon1.title.th"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Eng title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon1.title.en"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div id="thumbs-2" class="thumb-item">
            <div class="flex-row">
              <div class="thumbnail-picker-wrapper">
                <thumbnail-input
                  size="sm"
                  :url="form.projectIcon2 && form.projectIcon2.source"
                  :onSelect="(file) => onSelectImage('projectIcon2', file)"
                  :iconList="iconList"
                ></thumbnail-input>
              </div>
              <div class="w-100">
                <p>Select icon & Typing Description</p>
                <div class="row">
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Thai title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon2.title.th"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Eng title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon2.title.en"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div id="thumbs-3" class="thumb-item">
            <div class="flex-row">
              <div class="thumbnail-picker-wrapper">
                <thumbnail-input
                  size="sm"
                  :url="form.projectIcon3 && form.projectIcon3.source"
                  :onSelect="(file) => onSelectImage('projectIcon3', file)"
                  :iconList="iconList"
                ></thumbnail-input>
              </div>
              <div class="w-100">
                <p>Select icon & Typing Description</p>
                <div class="row">
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Thai title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon3.title.th"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="2"
                      label-size="sm"
                      label="Eng title"
                      label-for="input-sm"
                    >
                      <b-form-input
                        type="text"
                        required
                        size="sm"
                        v-model="form.projectIcon3.title.en"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div id="data-from-api">
          <p>Setting for connect registration data</p>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Select project">
                <b-form-select
                  :required="true"
                  v-model="form.projectCode"
                  class="select"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>
                      -- Please select project --
                    </option>
                  </template>
                  <option
                    v-for="(selectOption, indexOpt) in projectMasters"
                    :key="indexOpt"
                    :value="selectOption.ProjectID"
                  >
                    {{ selectOption.ProjectID }} {{ selectOption.ProjectName }}
                  </option>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <hr />
        <div id="theme-template">
          <p>Theme Template & Theme Color Option</p>
          <div class="row">
            <div class="col-6">
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="Theme template :"
                label-align-sm="left"
              >
                <b-form-select
                  value-field="id"
                  text-field="name"
                  :required="form.isUseTheme === 'Y'"
                  v-model="form.template"
                  :disabled="form.isUseTheme === 'N'"
                  :options="templates"
                >
                  <template v-slot:first>
                    <option :value="null" disabled>
                      -- Please select template --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-4 mb-2">
              <b-button
                size="md"
                variant="success"
                @click="previewTemplate"
                style="margin-left: 10px"
                :disabled="form.isUseTheme === 'N'"
                >Preview</b-button
              >
            </div>
          </div>
        </div>
        <hr />
        <div id="custom-theme">
          <div class="checkbox-wrapper">
            <p-check
              name="check"
              class="p-icon p-smooth p-bigger"
              color="primary"
              v-model="form.isUseTheme"
              true-value="N"
              false-value="Y"
            >
              <i slot="extra" class="icon fa fa-check"></i>Use Theme Color
              Option (*Leave it for use default theme color)
            </p-check>
          </div>
          <br />
          <div class="flex-row" style="justify-content: space-around">
            <custom-color
              label="Primary Color"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.primaryColorTheme"
            ></custom-color>
            <custom-color
              label="Secondary Color"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.secondaryTheme"
            ></custom-color>
            <custom-color
              v-if="form.subColorTheme1"
              label="Sub Color 1"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.subColorTheme1"
            ></custom-color>
            <custom-color
              v-if="form.subColorTheme2"
              label="Sub Color 2"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.subColorTheme2"
            ></custom-color>
            <custom-color
              v-if="form.subColorTheme3"
              label="Sub Color 3"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.subColorTheme3"
            ></custom-color>
            <custom-color
              v-if="form.subColorTheme4"
              label="Sub Color 4"
              :disable="form.isUseTheme === 'Y'"
              :color.sync="form.subColorTheme4"
            ></custom-color>
          </div>
        </div>
        <hr />
        <p>Select with tag Transport</p>
        <multiselect
          v-model="tagTrainSelected"
          tag-placeholder=""
          placeholder="Search or add a tag"
          label="name"
          track-by="code"
          :options="tagTrains"
          :multiple="true"
          :taggable="true"
        ></multiselect>
        <br />
        <p>Select with tag Price</p>
        <multiselect
          v-model="tagPriceSelected"
          tag-placeholder=""
          placeholder="Search or add a tag"
          label="name"
          track-by="code"
          :options="tagPrices"
          :multiple="true"
          :taggable="true"
        ></multiselect>
        <br />
        <p>Select with tag Location</p>
        <multiselect
          v-model="tagLocationSelected"
          tag-placeholder=""
          placeholder="Search or add a tag"
          label="name"
          track-by="code"
          :options="tagLocations"
          :multiple="true"
          :taggable="true"
        ></multiselect>
        <br />
        <b-button size="sm" variant="primary" type="submit">Save</b-button>
        <b-button
          size="sm"
          variant="outline-danger"
          type="button"
          @click="cancel"
          >Cancel</b-button
        >
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'
import CustomColor from '@/components/CustomColor'
import ThumbnailInput from '@/components/ThumbnailInput'
import TextEditor from '@/components/text-editor/TextEditor'
import * as ProjectTemplate from '@/services/projectTemplate.service'

export default {
  props: {
    enabled: Boolean,
    form: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    },
    updateSuccess: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    CustomColor,
    ThumbnailInput,
    Multiselect,
    ImageUploadNew,
    TextEditor
  },
  data: () => ({
    url: '',
    descTabLang: 'th',
    projectTemplateURL: null,
    projects: [
      { value: null, text: 'Project' },
      { value: 'a', text: 'Project 1' },
      { value: 'b', text: 'Project 1' }
    ],
    templates: [],
    uploadLogoError: false,
    uploadProjectThumbError: false,
    tempData: null,
    tempURL: '',
    gulist: [],
    tagTrains: [],
    tagPrices: [],
    tagLocations: [],
    tagTrainSelected: [],
    tagPriceSelected: [],
    tagLocationSelected: [],
    advanceSearchList: {},
    iconList: [],
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: true
    }
  }),
  computed: {
    ...mapGetters('projects', ['projectMasters']),
    category () {
      return this.categories.find(({ id }) => id === this.form.projectCategoryId)
    },
    currentEndpoint () {
      const { subDomain } = this.category || {}

      return `${process.env.VUE_APP_DOMAIN}/${process.env.VUE_APP_LANG}/${subDomain}-`
    },
    currentURL () {
      return `${this.currentEndpoint}${this.url}`
    }
  },
  methods: {
    ...mapActions('projects', ['getProjectMasters']),
    ...mapActions({
      onUpdate: ActionFetch.PROJECTS.MAIN.UPDATE,
      guLeadList: ActionFetch.EXTERNAL.PROJECT_GULEAD.LIST,
      tagTrainList: ActionFetch.TRANSPORTLINK.LIST,
      tagLocationList: ActionFetch.SEARCH_LOCATION.LIST,
      tagPriceList: ActionFetch.SEARCH_PRICE.LIST,
      onGetAvanceSearch: ActionFetch.PROJECTS.ADVANCESEARCH.INDEX,
      onCreateAvanceSearch: ActionFetch.PROJECTS.ADVANCESEARCH.ADD,
      onUpdateAvanceSearch: ActionFetch.PROJECTS.ADVANCESEARCH.UPDATE,
      getIcon: ActionFetch.ICON.LIST,
      clearProjectList: ActionFetch.PROJECTS.MAIN.CLEAR_PROJECT_LIST
    }),
    onSelectImage (id, data) {
      this.form[id].source = data.url
    },
    getImagePath ($image) {
      return $image ? `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$image}` : ''
    },
    submit (e) {
      this.uploadLogoError = false
      this.uploadProjectThumbError = false

      if (!this.form.projectLogo.source) {
        this.uploadLogoError = true
      }

      if (!this.form.projectThumb.source) {
        this.uploadProjectThumbError = true
      }

      if (this.uploadProjectThumbError || this.uploadLogoError) {
        return
      }

      e.preventDefault()
      const data = { ...this.form }

      Object.defineProperty(data, 'category', {
        enumerable: false
      })
      Object.defineProperty(data, 'advanceSearch', {
        enumerable: false
      })

      this.onHandleSearchAdvance()
      this.onUpdate({ id: this.form.id, data }).then((resp) => {
        if (resp && resp.success && resp.message) {
          this.updateSuccess(resp.message)

          this.clearProjectList(null)
        }
      })
    },
    copyToClipboard () {
      const copyText = this.$refs.project_url
      copyText.setAttribute('type', 'text')
      copyText.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.$bvToast.toast(`Project url was copied ${msg}`, {
          title: 'Copied',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'info',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
        copyText.setAttribute('type', 'hidden')
      } catch (err) {
        this.$bvModal.msgBoxOk(err.message, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
          okOnly: true
        })
      }
    },
    getEndpoint () {
      const category =
        this.categories &&
        this.categories.find((each) => each.id === this.form.projectCategoryId)
      const subDomain = category ? `${category.subDomain}` : ''

      return `${process.env.VUE_APP_DOMAIN}/${process.env.VUE_APP_LANG}/${subDomain}-`
    },
    onFileUploadLogo ($value) {
      this.form.projectLogo = $value
    },
    onFileUploadThumbnail ($value) {
      this.form.projectThumb = $value
    },
    extractURL () {
      this.url = this.form.url.replace(this.currentEndpoint, '')
      this.tempURL = this.url
    },
    previewTemplate () {
      if (!this.projectTemplateURL && this.form.template) {
        const data = ProjectTemplate.templates.find(
          (each) => each.id === this.form.template
        )
        this.projectTemplateURL = data.url
      }
      window.open(this.projectTemplateURL, '_blank')
    },
    toggle (value) {
      this.$emit('update:enabled', value.value)
    },
    nameWithLang ({ name, language }) {
      return `${name} — [${language}]`
    },
    setTempData () {
      this.tempData = JSON.parse(JSON.stringify(this.form))
    },
    cancel () {
      this.$emit('update:form', JSON.parse(JSON.stringify(this.tempData)))
      this.url = this.tempURL
    },
    onFetchGuleadList () {
      this.getProjectMasters()
    },
    fetchTrain () {
      this.onGetAvanceSearch(this.form.id).then((resp) => {
        if (
          this.$store.state.project &&
          this.$store.state.project.advanceSearch
        ) {
          this.advanceSearchList = this.$store.state.project.advanceSearch[0]

          if (this.advanceSearchList && this.advanceSearchList.trainId) {
            this.advanceSearchList.trainId.map((each) => {
              this.tagTrainSelected.push({
                code: each.id,
                name: `${each.title.en}/ ${each.title.th}`,
                type: 'train'
              })
            })

            this.advanceSearchList.locationId.map((each) => {
              this.tagLocationSelected.push({
                code: each.id,
                name: `${each.title.en}/ ${each.title.th}`,
                type: 'location'
              })
            })

            this.advanceSearchList.priceId.map((each) => {
              this.tagPriceSelected.push({
                code: each.id,
                name: `${each.title.en}/ ${each.title.th}`,
                type: 'price'
              })
            })
          }
        }
      })
    },
    onUpdateContent ($value, $lang) {
      this.form.description[$lang] = $value
    },
    onFetchAdvanceSerch () {
      this.fetchTrain()
    },
    onFetchTagList () {
      this.tagTrainList({ orderBy: 'title', direction: 'ASC' }).then((resp) => {
        if (
          this.$store.state.projects &&
          this.$store.state.projects.transportlink
        ) {
          this.$store.state.projects.transportlink.forEach((each) => {
            if (each.status === 'Active') {
              this.tagTrains.push({
                code: each.id,
                name: `${each.title.en}/ ${each.title.th}`,
                type: 'train'
              })
            }
          })
        }
      })
      this.tagLocationList({ orderBy: 'title', direction: 'ASC' }).then(
        (resp) => {
          if (
            this.$store.state.projects &&
            this.$store.state.projects.locationList
          ) {
            this.$store.state.projects.locationList.forEach((each, index) => {
              if (each.status === 'Active') {
                this.tagLocations.push({
                  code: each.id,
                  name: `${each.title.en}/ ${each.title.th}`,
                  type: 'location'
                })
              }
            })
          }
        }
      )
      this.tagPriceList({ orderBy: 'title', direction: 'ASC' }).then((resp) => {
        if (
          this.$store.state.projects &&
          this.$store.state.projects.priceRangeList
        ) {
          this.$store.state.projects.priceRangeList.forEach((each) => {
            if (each.status === 'Active') {
              this.tagPrices.push({
                code: each.id,
                name: `${each.title.en}/ ${each.title.th}`,
                type: 'price'
              })
            }
          })
        }
      })
    },
    onHandleSearchAdvance () {
      try {
        const toastUpdate = (msg) =>
          this.$bvToast.toast(msg, {
            title: 'Project search advance',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })

        const trainId = this.tagTrainSelected.map(($tag) => {
          return { id: $tag.code }
        })
        const locationId = this.tagLocationSelected.map(($tag) => {
          return { id: $tag.code }
        })
        const priceId = this.tagPriceSelected.map(($tag) => {
          return { id: $tag.code }
        })
        const params = {
          ...this.advanceSearchList,
          trainId,
          locationId,
          priceId
        }
        if (this.advanceSearchList && this.advanceSearchList.id) {
          this.onUpdateAvanceSearch({
            id: this.advanceSearchList.id,
            data: params
          }).then((resp) => {
            if (resp && resp.success) {
              toastUpdate(resp.message)
            }
          })
        } else {
          this.onCreateAvanceSearch({
            ...params,
            projectId: this.form.id,
            status: 'Active'
          }).then((resp) => {
            if (resp && resp.success) {
              toastUpdate(resp.message)
              this.onFetchAdvanceSerch()
            }
          })
        }
      } catch (error) {}
    },
    handleTemplateValue (value) {
      const temp = this.templates.find((each) => each.id === value)
      this.form.primaryColorTheme = temp.primaryColorTheme
      this.form.secondaryTheme = temp.secondaryTheme
      this.form.subColorTheme1 = temp.subColorTheme1
      this.form.subColorTheme2 = temp.subColorTheme2
      this.form.subColorTheme3 = temp.subColorTheme3
      this.projectTemplateURL = temp.url
      this.form.isUseTheme = 'Y'
    },
    getIconList () {
      this.getIcon().then(($res) => {
        if ($res.success) {
          this.iconList = $res.data
        }
      })
    }
  },
  created () {
    this.enable = this.enabled
    this.form.description = this.form.description
      ? this.form.description
      : { th: '', en: '' }

    this.templates = ProjectTemplate.templates
    this.onFetchAdvanceSerch()
    this.onFetchTagList()
    this.getIconList()
  },
  mounted () {
    this.onFetchGuleadList()
    this.setTempData()
    this.extractURL()
  },
  watch: {
    url: function (val) {
      this.url = val.toLowerCase().replace(/ /g, '-')
      this.form.url = `${this.currentEndpoint}${this.url}`
    },
    'form.url': function () {
      this.extractURL()
    },
    'category': function () {
      this.extractURL()
    },
    'form.template': function (newValue) {
      if (newValue) {
        this.handleTemplateValue(newValue)
      }
    },
    'form.isUseTheme': function (val) {
      if (val === 'Y') {
        this.handleTemplateValue(this.form.template)
      }
    },
    'form.isNewProject': function (val) {
      if (val === 'Y') {
        this.form.isReadyToMoveIn = 'N'
      }
    },
    'form.isReadyToMoveIn': function (val) {
      if (val === 'Y') {
        this.form.isNewProject = 'N'
      }
    },
    'form.startPrice': function (val) {
      if (val < 0) {
        val = 0
      }
      this.form.startPrice = val
    },
    'form.endPrice': function (val) {
      if (val < 0) {
        val = 0
      }
      this.form.endPrice = val
    },
    'form.isSoldOut': function (val) {
      if (val === 'Y') {
        this.form.statusRegister = 'Inactive'
      }
    }
  }
}
</script>

<style scoped>
input.hide {
  display: none;
}
</style>

<template>
  <div>
    <div class="flex-row align-center justify-between">
      <span class="page-title">Create 360 List</span>
      <div class="flex-row">
        <b-btn
          v-on:click="addEditPanorama()"
          size="sm"
          variant="primary"
          class="btn-group-space"
          >Add New List</b-btn
        >
      </div>
    </div>
    <br />
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>360 Name</th>
            <!-- <th>File Type</th> -->
            <th>Last Update</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td>{{ list.title.en }}</td>
            <!-- <td>{{ list.file_type }}</td> -->
            <td>{{ renderDate(list.updatedAt) }}</td>
            <td>
              <toggle-button
                :labels="true"
                :value="getPublished(list)"
                @change="(value) => onChangeStatus(value, list)"
                color="#0074d9"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="primary"
                  class="btn-group-space"
                  v-on:click="addEditPanorama(list)"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="deletePanorama(list.id)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
          <tr v-if="data.length === 0">
            <td colspan="7">
              <div class="text-center">
                <i>Empty data.</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddVideo360
      v-if="form"
      :form="form"
      :onAddPanoramas="onAddPanoramas"
      :onUpdatePanoramas="onUpdatePanoramas"
      :handlePanoramasList="handlePanoramasList"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'

import AddVideo360 from '../modals/AddVideo360.modal'

export default {
  props: {
    projectID: Number,
    data: {
      type: Array,
      default: () => []
    },
    onUpdatePanoramas: Function,
    onAddPanoramas: Function,
    onDeletePanoramas: Function,
    handlePanoramasList: Function,
    onSortingPanoramas: Function
  },
  components: {
    AddVideo360
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    form: null
  }),
  methods: {
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)
      return items
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSortingPanoramas({
        id,
        module: this.page,
        orderNew: Number(newValue),
        orderOld: Number(oldValue)
      }).then((resp) => {
        if (resp.success) {
          this.handlePanoramasList()
        }
      })
    },
    getPublished (data) {
      return data.status.toLowerCase() === 'active'
    },
    onChangeStatus (value, data) {
      data.status = value.value ? 'Active' : 'Inactive'
      this.onUpdatePanoramas({ id: data.id, data: data })
    },
    addEditPanorama (data) {
      let form = data
      if (!data) {
        form = {
          projectId: this.projectID,
          title: {
            th: '',
            en: ''
          },
          embedded: {
            url: null
          },
          upload: {
            source: null
          },
          status: 'Active'
        }
      }
      this.form = form
      this.$bvModal.show('add-video-360')
    },
    deletePanorama (id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.onDeletePanoramas(id).then((resp) => {
              if (resp.success) {
                this.handlePanoramasList()
              }
            })
          }
        })
    }
  }
}
</script>

<style>
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

th {
  text-align: center;
}
td {
  text-align: center;
}

.video-cover {
  max-height: 60px;
}
</style>

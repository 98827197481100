<template>
  <div class="p-3">
    <b-form-group label="Project" class="mb-4">
      <b-form-select
        v-model="form.project"
        :options="projects"
        v-on:change="changeProject"
      ></b-form-select>
    </b-form-group>

    <b-tabs class="mt-3">
      <b-tab key="0" title="ภาษาไทย">
        <b-form class="p-3">
          <b-row class="flex-nowrap align-items-center no-gutters">
            <b-col sm="11">
              <b-form-group label="Page URL">
                <b-form-input
                  id="pageURL"
                  :value="getUrl()"
                  required
                  label="Page URL"
                  placeholder="https://google.com"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <a
                @click="copy()"
                class="btn-copy mt-2"
                v-b-tooltip.hover.bottom
                title="Copy URL"
              >
                <i class="fas fa-clipboard"></i>
              </a>
            </b-col>
          </b-row>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.th"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.th"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.th"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <label class="label"
            >*Google Tag Separate by Project (Microsite) Page</label
          >
          <br />
        </b-form>
      </b-tab>
      <b-tab key="0" title="English">
        <b-form class="p-3">
          <b-row class="flex-nowrap align-items-center no-gutters">
            <b-col sm="11">
              <b-form-group label="Page URL">
                <b-form-input
                  id="pageURL"
                  required
                  :value="getUrl()"
                  label="Page URL"
                  placeholder="https://google.com"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <a class="btn-copy mt-2" title="Copy URL">
                <i class="fas fa-clipboard"></i>
              </a>
            </b-col>
          </b-row>
          <b-form-group label="Meta tag description">
            <b-form-input
              v-model="form.metaDescription.en"
              required
              placeholder="Input meta tag description"
            />
          </b-form-group>
          <b-form-group label="Meta tag keywords">
            <b-form-input
              v-model="form.metaKeyword.en"
              required
              placeholder="Input meta tag keywords"
            />
          </b-form-group>
          <b-form-group label="Tag In Header">
            <b-form-textarea
              v-model="form.tagInHeader.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group label="Tag In Body">
            <b-form-textarea
              v-model="form.tagInBody.en"
              required
              placeholder="HTML/Javascript code"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <label class="label"
            >*Google Tag Separate by Project (Microsite) Page</label
          >
          <br />
        </b-form>
      </b-tab>
    </b-tabs>

    <b-button variant="primary" v-on:click="saveSeoThankyou">Update</b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    projects: [],
    projectList: [],
    form: {
      project: null
    },
    projectSelect: null
  }),
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.REGISTERSETTING.PROJECTLIST,
      getList: ActionFetch.SEO_GOOGLE.GET_THANKYOU,
      saveThankyou: ActionFetch.SEO_GOOGLE.SAVE_THANKYOU
    }),
    loadProjectList () {
      this.getProjectList().then(($res) => {
        if ($res.success) {
          this.projectList = $res.data
          this.projects = $res.data.map(($data) => {
            return { value: $data.id, text: $data.name.th }
          })
        }
        // this.form.project = $res.data[0].id;
        // this.changeProject();
      })
    },
    getThankyou () {
      this.getList(this.projectSelect).then(($res) => {
        if ($res.success && $res.data) {
          this.form = { ...this.form, ...$res.data }
          this.form.project = this.projectSelect
        } else {
          this.form = {
            project: this.form.project,
            metaDescription: '',
            metaKeyword: '',
            tagInHeader: '',
            tagInBody: '',
            visitor: '127.0.0.1',
            status: 'Active',
            updatedBy: ''
          }
        }
      })
    },
    changeProject () {
      this.projectSelect = this.form.project
      this.getThankyou()
    },
    getUrl ($lang = 'th') {
      let str = ''

      if (this.projectSelect) {
        let project = this.projectList.find(($project) => {
          return $project.id === this.projectSelect
        })

        str = project ? `${project.url}/thank-you` : ''
      }

      return str
    },
    copy () {
      let pageUrl = document.getElementById('pageURL')

      pageUrl.select()
      document.execCommand('copy')
    },
    saveSeoThankyou () {
      this.saveThankyou(this.form).then(($res) => {
        if ($res.success) {
          this.getThankyou()
        }
      })
    }
  },
  created () {
    this.form = {
      project: null,
      metaDescription: '',
      metaKeyword: '',
      tagInHeader: '',
      tagInBody: '',
      visitor: '127.0.0.1',
      status: 'Active',
      updatedBy: ''
    }
    this.loadProjectList()
  }
}
</script>

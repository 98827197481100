<template>
  <div class="image-uploader">
    <h4 class="title">{{ title }}</h4>
    <br />
    <div class="image-uploader-container">
      <upload-item
        v-for="(image, index) in images"
        :key="index"
        :remove="() => removeImage(image.id)"
        :data="image"
        :edit="() => editImage(image.id)"
        :onSelect="(file) => onSelectImage(image.id, file)"
        :showText="showText"
      />
      <br />
      <b-button
        block
        variant="outline-primary"
        @click="addImage"
        v-if="multiple"
        >+ Add</b-button
      >
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadItem from './ImageUploadItem'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    returnFile: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => [{ id: uuid(), alt: '', url: '', name: '' }]
    },
    showText: {
      type: Boolean,
      default: true
    },
    uploadReturn: Function
  },
  components: {
    'upload-item': ImageUploadItem
  },
  data: () => ({
    // image: '',
    // images: [{ id: uuid(), alt: '', url: '', name: '' }]
  }),
  methods: {
    ...mapActions({
      getConfig: ActionFetch.SEO_FACEBOOK.GET_CONFIG
    }),
    loadConfig () {
      this.getConfig().then(($res) => {
        if ($res.success) {
          this.form = $res.data
        }
      })
    },
    async removeImage (id) {
      const images = await this.images.filter((i) => i.id !== id)
      this.images = images

      if (this.returnFile) {
        this.onRemove(id)
      }
    },
    addImage () {
      this.images = [...this.images, { id: uuid(), alt: '', url: '' }]
    },
    async editImage (id) {
      const { images } = this
      const index = images.findIndex((i) => i.id === id)
      images[index].url = ''
      this.images = images
    },
    onSelectImage (id, file) {
      const { images } = this
      const index = images.findIndex((i) => i.id === id)

      images[index].url = file.data.url
      images[index].name = file.data.name
      this.images = images

      if (this.returnFile) {
        this.uploadReturn(file.file)
      }
    }
  }
}
</script>

<style scoped>
.image-uploader {
  text-align: center;
}
.image-uploader-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1.5em;
}
.show-image {
  max-height: 150px;
}
.medium > i {
  font-size: 40px;
}
.medium {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.medium-image {
  max-height: 70px;
  max-width: 70px;
}

i {
  color: #fff;
}
.large > i {
  font-size: 80px;
}
.large {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
.image-uploader-btn:hover {
  cursor: pointer;
}
.image-uploader-btn {
  min-height: 50px;
  border: solid 2px rgba(38, 153, 251, 0.2);
  background-color: rgba(38, 153, 251, 0.2);
  max-width: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

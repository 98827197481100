var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"register-setting-editor","size":"xl","centered":"","title":"Edit","hide-header-close":true,"no-close-on-backdrop":true,"no-close-on-esc":true},on:{"show":_vm.onShow,"shown":function($event){_vm.modalShown = true},"hidden":function($event){_vm.modalShown = false}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"my-label"},[_vm._v("ภาษาไทย")]),(_vm.options.length)?_c('TextEditor',{attrs:{"modalShown":_vm.modalShown,"modal":true,"content":_vm.options[0].translations.find(function (ref) {
	var lang = ref.lang;

	return lang === 'th';
}).name,"onUpdateInfo":function (value) { return _vm.options[0].translations.find(function (ref) {
	var lang = ref.lang;

	return lang === 'th';
	}).name = value; }}}):_vm._e(),_c('div',{staticClass:"space"}),_c('div',{staticClass:"my-label"},[_vm._v("English")]),(_vm.options.length)?_c('TextEditor',{attrs:{"modalShown":_vm.modalShown,"modal":true,"content":_vm.options[0].translations.find(function (ref) {
	var lang = ref.lang;

	return lang === 'en';
}).name,"onUpdateInfo":function (value) { return _vm.options[0].translations.find(function (ref) {
	var lang = ref.lang;

	return lang === 'en';
	}).name = value; }}}):_vm._e()],1),_c('template',{slot:"modal-footer"},[_c('b-button',{attrs:{"size":"sm","variant":"primary","form":"form-select-edit-form"},on:{"click":_vm.submit}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
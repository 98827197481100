const SUB_DOMAINS = []

for (let i = 0; i <= 121; i++) {
  SUB_DOMAINS[i] = {
    no: i + 1,
    last_update: new Date(),
    company_name: 'abc',
    url: 'https://google.com',
    projects: ['Project A', 'Project B'],
    published: true
  }
}

const list = () => {
  return SUB_DOMAINS
}

const prepareSubDomainForForm = (data) => {
  if (!data) {
    return {
      title: { th: null, en: null },
      subDomain: '',
      logo: { source: null },
      visitor: null,
      status: 'Active',
      createdBy: 0,
      updatedBy: 0,
      orderNum: 0
    }
  } else {
    return {
      ...data
    }
  }
}

export default {
  list,
  prepareSubDomainForForm
}

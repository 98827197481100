<template>
  <div>
    <div class="table-responsive">
      <div class="flex-row align-center justify-between">
        <h5 class="page-title" style="font-weight: 600">Create Job</h5>
        <div class="flex-row">
          <b-btn
            size="sm"
            variant="primary"
            class="btn-group-space"
            v-b-modal.add-blog
            >Add New Item</b-btn
          >
        </div>
      </div>

      <br />

      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>Blog Title</th>
            <th>Work Place</th>
            <th>Created Date</th>
            <!-- <th>Picture</th> -->
            <th>Published</th>
            <!-- <th>Start/Stop Auto Published</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select name id :value="null">
                <option
                  v-for="(list, index) in items()"
                  :value="null"
                  :key="index + 1"
                >
                  {{ index + 1 }}
                </option>
              </b-form-select>
            </td>
            <td>{{ list.title }}</td>
            <td>{{ list.content }}</td>
            <td>{{ renderDate(list.last_update) }}</td>
            <!-- <td>
              <img :src="list.image" alt class="banner">
            </td> -->

            <td>
              <toggle-button :value="list.published" :labels="true" />
            </td>
            <!-- <td>
              <p class="start_stop_date">start: {{ renderDate(list.auto_start) }}</p>
              <p class="start_stop_date">end: {{ renderDate(list.auto_stop) }}</p>
            </td> -->

            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  v-b-modal.add-blog
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="DeleteUser(1, 1)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="perPage"
        :options="perPageOptions"
      ></b-form-select>
    </div>
    <AddBlogModal />
  </div>
</template>

<script>
import format from 'date-fns/format'

import AddBlogModal from '../modals/AddEditBlogModal'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AddBlogModal
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ]
  }),
  methods: {
    view (id) {
      this.$router.push(`/newsandarticles/news/${id}/edit`)
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length - 1 : start + perPage
      const items = data.slice(start, end)
      return items
    },
    DeleteUser (id, index) {
      if (confirm('Are you sure?')) {
      }
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

/* th {
  text-align: center;
}
td {
  text-align: center;
} */
</style>

<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Banner Home Page</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <banner-table
          :data.sync="banners"
          :page="'home'"
          :onGetDetail="onGetDetailIndex"
          :onAddBanner="onAddBannerIndex"
          :onUpdateBanner="onUpdateBannerIndex"
          :onDeleteBanner="onDeleteBannerIndex"
          :onFetchBannerList="fetchBannerList"
          :onSorting="bannerSorting"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import format from 'date-fns/format'

import BannerTable from '@/components/tables/BannerTable'
import BannerServices from '@/services/banner.service'

export default {
  components: {
    BannerTable
  },
  data: () => ({
    enable: true,
    banners: []
  }),
  methods: {
    ...mapActions({
      onGetListIndex: ActionFetch.BANNERS_LIST,
      onGetDetailIndex: ActionFetch.BANNERS_DETAIL,
      onAddBannerIndex: ActionFetch.BANNERS_ADD,
      onUpdateBannerIndex: ActionFetch.BANNERS_UPDATE,
      onDeleteBannerIndex: ActionFetch.BANNERS_DELETE,
      bannerSorting: ActionFetch.BANNERS_SORT
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    fetchBannerList () {
      this.loading = true
      this.onGetListIndex('home').then((resp) => {
        if (this.$store.state.banners && this.$store.state.banners.home) {
          this.banners = BannerServices.list(this.$store.state.banners.home)
          this.banners = this.banners.map(($data) => {
            if ($data.endDate) {
              const endDate = new Date($data.endDate)
              let dd = endDate.getDate()
              let mm = endDate.getMonth() + 1
              let yy = endDate.getFullYear()
              const currentEndDate = new Date(`${yy}-${mm}-${dd} 23:59:59`)
              const currentDate = new Date()

              if (currentEndDate < currentDate) {
                $data.status = 'InActive'
              }
            }
            return $data
          })
        }
        this.loading = false
      })
    },
    handleBannerList () {
      if (this.$store.state.banners && this.$store.state.banners.home) {
        this.banners = BannerServices.list(this.$store.state.banners.home)
      } else {
        this.fetchBannerList()
      }
    }
  },
  created () {
    this.handleBannerList()
  }
}
</script>

<style>
td {
  vertical-align: middle !important;
  font-size: 14px;
}
</style>

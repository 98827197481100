<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('bannerSlide', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('bannerSlide', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Banner Slide</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="content pt-3" v-if="enabled">
      <h5 class="my-3 hide">Title Section</h5>
      <div class="row hide" style="text-align: left">
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Thai title
            <input
              type="text"
              class="form-control"
              v-model="section.title.th"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-row">
            Eng title
            <input
              type="text"
              class="form-control"
              v-model="section.title.en"
            />
          </div>
        </div>
      </div>
      <BannerTable
        :data="banners"
        :projectID="projectID"
        :onGetDetail="onGetDetail"
        :onAddBanner="onAddBanner"
        :onUpdateBanner="onUpdateBanner"
        :onDeleteBanner="onDeleteBanner"
        :onFetchBannerList="fetchBannerList"
        :onSorting="onSortingBanner"
      />
      <b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="updateSection(section, true)"
          >Save</b-btn
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import BannerTable from '@/components/tables/BannerTable'

export default {
  props: {
    projectID: {
      type: Number,
      default: 0
    },
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    BannerTable
  },
  data: () => ({
    banners: []
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.PROJECTS.BANNERSLIDE.INDEX,
      onGetDetail: ActionFetch.PROJECTS.BANNERSLIDE.DETAIL,
      onAddBanner: ActionFetch.PROJECTS.BANNERSLIDE.ADD,
      onUpdateBanner: ActionFetch.PROJECTS.BANNERSLIDE.UPDATE,
      onDeleteBanner: ActionFetch.PROJECTS.BANNERSLIDE.DELETE,
      onSortingBanner: ActionFetch.PROJECTS.BANNERSLIDE.SORT
    }),
    fetchBannerList () {
      this.banners = []
      this.getList(this.projectID).then((resp) => {
        if (
          this.$store.state.project &&
          this.$store.state.project.bannerSlide
        ) {
          this.banners = this.$store.state.project.bannerSlide
        }
      })
    },
    handleBannerList () {
      if (this.$store.state.project && this.$store.state.project.bannerSlide) {
        this.banners = this.$store.state.project.bannerSlide
      } else {
        this.fetchBannerList()
      }
    }
  },
  created () {
    // this.handleBannerList();
    this.fetchBannerList()
  }
}
</script>

<template>
  <div>
    <div class="page-name">
      <div class="page-title">Attributes</div>
    </div>
    <div class="page-container">
      <b-card class="p-3">
        <div class="d-flex justify-content-between my-3">
          <span class="table-title">Setting Attributes</span>
        </div>
        <attr-table
          :data="attrs"
          :onFetchAttributeList="onFetchProjectAttributeList"
          :onAddAttribute="onAddAttribute"
          :onUpdateAttribute="onUpdateAttribute"
          :onDeleteAttribute="onDeleteAttribute"
          :onUpdateSort="onUpdateSort"
        ></attr-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import attrTable from '@/components/tables/AttributesTable'
export default {
  components: {
    attrTable
  },
  data: () => ({
    attrs: []
  }),
  methods: {
    ...mapActions({
      onFetchAttributeList: ActionFetch.ATTRIBUTES.INDEX,
      onAddAttribute: ActionFetch.ATTRIBUTES.ADD,
      onUpdateAttribute: ActionFetch.ATTRIBUTES.UPDATE,
      onDeleteAttribute: ActionFetch.ATTRIBUTES.DELETE,
      onUpdateSort: ActionFetch.ATTRIBUTES.SORT
    }),
    onFetchProjectAttributeList () {
      this.onFetchAttributeList({ toStoreData: true }).then((resp) => {
        if (this.$store.state.attributes && this.$store.state.attributes.list) {
          this.attrs = this.$store.state.attributes.list
        }
      })
    },
    handleProjectAttributeList () {
      if (this.$store.state.attributes && this.$store.state.attributes.list) {
        this.attrs = this.$store.state.attributes.list
      } else {
        this.onFetchProjectAttributeList()
      }
    }
  },
  created () {
    this.handleProjectAttributeList()
  }
}
</script>

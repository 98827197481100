<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('review', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('review', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Review</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enable"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  methods: {}
}
</script>

<style scoped>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
</style>

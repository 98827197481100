import { render, staticRenderFns } from "./PriceRange.page.vue?vue&type=template&id=716e1fc8&"
import script from "./PriceRange.page.vue?vue&type=script&lang=js&"
export * from "./PriceRange.page.vue?vue&type=script&lang=js&"
import style0 from "./PriceRange.page.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-video","size":"lg","centered":"","no-close-on-backdrop":"","title":"Add/Edit Video"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"primary","type":"submit","form":"add-video-form"}},[_vm._v("Save")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancel")])]}}])},[_c('form',{attrs:{"id":"add-video-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group form-group-row"},[_vm._v(" Thai name "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title.th),expression:"form.title.th"}],class:{
              'form-control': true,
              'has-error': this.$v.form.title.th.$error,
            },attrs:{"type":"text"},domProps:{"value":(_vm.form.title.th)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.title, "th", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group form-group-row"},[_vm._v(" Eng name "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title.en),expression:"form.title.en"}],class:{
              'form-control': true,
              'has-error': this.$v.form.title.en.$error,
            },attrs:{"type":"text"},domProps:{"value":(_vm.form.title.en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.title, "en", $event.target.value)}}})])])]),_c('div',{staticClass:"form-group form-group-row"},[_vm._v(" Youtube Link "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.video.url),expression:"form.video.url"}],class:{
          'form-control': true,
          'has-error': this.$v.form.video.$error,
        },attrs:{"type":"text"},domProps:{"value":(_vm.form.video.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.video, "url", $event.target.value)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="flex-row justify-between align-center tab">
      <div class="flex-row">
        <toggle-button
          v-if="confirm"
          :value="enabled"
          :sync="true"
          @input="(value) => toggleSectionStatus('floorPlan', value)"
          color="#0074d9"
          :labels="true"
        />
        <toggle-button
          v-else
          :value="true"
          :sync="true"
          @input="(value) => toggleSectionStatus('floorPlan', value)"
          color="#0074d9"
          :labels="true"
        />
        <span class="text">Floor Plan</span>
      </div>
      <!-- <i class="fas fa-chevron-up icon-primary" v-if="enabled"></i>
      <i class="fas fa-chevron-down icon-primary" v-else></i>-->
    </div>
    <div class="container my-3" v-if="enabled">
      <h5 class="my-3">Title Section</h5>
      <div class="row" style="text-align: left">
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Thai title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="section.title.th"
              />
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-cols-xl="2"
            label="Eng title"
            label-align-sm="left"
            label-for="nested-street"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="section.title.en"
              />
            </div>
          </b-form-group>
        </div>
      </div>
      <hr class="bold" />
      <floor-list-table :projectID="projectID" />
      <b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          class="btn-group-space"
          @click="updateSection(section, true)"
          >Save</b-btn
        >
      </b-button-group>
    </div>
  </div>
</template>

<script>
import FloorListTable from '@/components/tables/FloorListTable'
export default {
  props: {
    projectID: Number,
    section: Object,
    enabled: Boolean,
    confirm: Boolean,
    toggleSectionStatus: Function,
    updateSection: Function
  },
  components: {
    FloorListTable
  }
}
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th class="text-left pl-4">Project Name</th>
            <th class="text-left">URL Domain</th>
            <th width="120">Last Update</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in items()" :key="index">
            <td>
              <b-form-select
                :value="list.orderNum"
                @change="
                  (value) => onChangeOrder(list.id, value, list.orderNum)
                "
              >
                <option v-for="i in data.length" :key="i">{{ i }}</option>
              </b-form-select>
            </td>
            <td class="text-left pl-4">{{ list.name.en || list.name.th }}</td>
            <td class="text-left">{{ list.url }}</td>
            <td>{{ renderDate(list.updatedAt) }}</td>
            <td>
              <b-form-checkbox
                v-model="list.status"
                value="Active"
                unchecked-value="InActive"
                switch
                :class="{
                  'checkbox-project-status-active': list.status === 'Active',
                  'checkbox-project-status-inactive': list.status !== 'Active',
                }"
                @change="(value) => onChangeStatus(value, list)"
              />
            </td>
            <td>
              <b-button-group>
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  @click="view(list.id)"
                  class="mx-1"
                  >Edit</b-btn
                >
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="mx-1"
                  v-on:click="onDelete(list.id, index)"
                  >Delete</b-btn
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      />
      <b-form-select
        class="col-2 mx-2"
        v-model="perPage"
        :options="perPageOptions"
      />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    onFetchList: Function,
    confirm: Object
  },
  data: () => ({
    perPage: 10,
    currentPage: 1,
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ]
  }),
  methods: {
    ...mapActions({
      updateProject: ActionFetch.PROJECTS.MAIN.UPDATE,
      deleteProject: ActionFetch.PROJECTS.MAIN.DELETE,
      onSorting: ActionFetch.PROJECTS.MAIN.SORT
    }),
    getPublished (data) {
      return data.status.toLowerCase() === 'active'
    },
    view (id) {
      this.$router.push(`/project/project/${id}/edit`)
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      const { currentPage, perPage, data } = this
      const start = (currentPage - 1) * perPage
      const end =
        start + perPage > data.length - 1 ? data.length : start + perPage
      const items = data.slice(start, end)

      return items
    },
    onChangeStatus (value, data) {
      const p = { ...data }
      Object.defineProperty(p, 'category', {
        enumerable: false
      })

      Object.defineProperty(p, 'advanceSearch', {
        enumerable: false
      })

      if (value === 'Active') {
        this.confirm[data.id] = true
        // data.confirm = true;
        this.$bvModal
          .msgBoxConfirm(
            `Do you already set and recheck all needed project information? Are you sure to publish this project?`,
            {
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0 justify-center',
              centered: true,
              okOnly: true,
              okTitle: 'Publish'
            }
          )
          .then((c) => {
            this.confirm[data.id] = c
            p.status = c ? 'Active' : 'InActive'
            data.status = p.status
            this.$emit('update:confirm', { ...this.confirm })
            if (c) {
              this.onUpdateProject(data.id, p)
            }
          })
      } else {
        p.status = 'InActive'
        this.onUpdateProject(data.id, p)
      }
    },
    onUpdateProject (id, data) {
      this.updateProject({ id, data })
    },
    onDelete (id, index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then((value) => {
          if (value) {
            this.deleteProject(id).then((resp) => {
              if (resp.success) {
                this.onFetchList()
              }
            })
          }
        })
    },
    onChangeOrder (id, newValue, oldValue) {
      this.$emit('update:data', [])
      this.onSorting({
        id,
        module: this.page,
        orderNew: newValue,
        orderOld: oldValue
      }).then((resp) => {
        if (resp.success) {
          this.onFetchList()
        }
      })
    },
    getIsConfirm (data) {
      return this.confirmPublic[data.id]
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.btn-group-space.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}
.custom-control-input ~ .custom-control-label::before {
  line-height: 15px;
  font-size: 7px;
  margin-right: 35px;
  font-weight: 600;
  width: 2rem;
  padding-left: 0.2rem;
  content: "off";
  text-align: right;
  padding-right: 0.3rem;
  color: #ffffff;
  border-color: #bfcad9;
  background-color: #bfcad9;
}
.custom-control-input ~ .custom-control-label::after {
  left: calc(-2.25rem + 2px);
  background-color: #ffffff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(117, 199, 145) !important;
  border-color: rgb(117, 199, 145);
  content: "on";
  text-align: left;
}
.custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-2.25rem + 6px);
}
</style>

<template>
  <div id="">
    <div class="page-name">
      <div class="page-title">Brand &amp; Campaign</div>
    </div>

    <div class="page-container">
      <form>
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting</h5>
              <hr />
            </div>
          </div>
          <b-form-group label="Select Template">
            <b-form-select
              class="select"
              v-model="form.template"
              :disabled="!!form.id"
            >
              <option value="brand">Brand</option>
              <option value="campaign">Campaign</option>
            </b-form-select>
          </b-form-group>

          <!-- /// Only Brand /// -->
          <b-form-group label="Select Brand" v-if="form.template == 'brand'">
            <b-form-select
              class="select"
              v-model="form.project_category_id"
              :disabled="!!form.id"
            >
              <template>
                <option :value="null" disabled>
                  -- Please select project category --
                </option>
              </template>
              <option
                v-for="(data, index) in categoryList"
                :key="index"
                :value="data.id"
              >
                {{ data.title.th }}
              </option>
            </b-form-select>
          </b-form-group>
          <!-- /// End /// -->

          <!-- /// Only Campaign /// -->
          <b-form-group label="Title" v-if="form.template == 'campaign'">
            <b-form-input
              type="text"
              placeholder="Input campaign name"
              v-model="form.name.th"
            ></b-form-input>
          </b-form-group>
          <!-- /// End /// -->

          <div class="flex-col mt-4" style="flex: 1">
            <h6 class="page-title-bg">Setup URL</h6>
          </div>
          <b-tabs content-class="mt-3">
            <b-tab title="ภาษาไทย">
              <b-form-group>
                <label for class="label">
                  <span style="font-weight: 600; margin-right: 10px">
                    Page URL
                  </span>
                  <span v-if="form.template == 'campaign'" class="red">
                    (use only non-capital letters, no space)
                  </span>
                  <span v-else class="red">
                    (use only non-capital letters, no space, no dash)
                  </span>
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ getDefaultUrl("th") }}
                    </div>
                  </div>
                  <b-form-input
                    id="urlTH"
                    class="col-lg-4"
                    type="text"
                    placeholder="detail"
                    v-model="form.slug.th"
                    v-bind:class="{ 'url-error': urlVerify.th }"
                    @keyup="(e) => onUpdatePageUrl(e, 'th')"
                  >
                  </b-form-input>

                  <a
                    class="btn-copy"
                    title="Copy URL"
                    @click="copy(form.slug.th, 'urlThCpyZone', 'th')"
                  >
                    <i class="fas fa-clipboard"></i>
                  </a>

                  <div id="urlThCpyZone" class="hidden"></div>
                </div>
              </b-form-group>
            </b-tab>
            <b-tab title="English">
              <b-form-group>
                <label for class="label">
                  <span style="font-weight: 600; margin-right: 10px">
                    Page URL
                  </span>
                  <span v-if="form.template == 'campaign'" class="red">
                    (use only non-capital letters, no space)
                  </span>
                  <span v-else class="red">
                    (use only non-capital letters, no space, no dash)
                  </span>
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ getDefaultUrl("en") }}
                    </div>
                  </div>
                  <b-form-input
                    id="urlEN"
                    class="col-lg-4"
                    type="text"
                    placeholder="detail"
                    v-model="form.slug.en"
                    @keyup="(e) => onUpdatePageUrl(e, 'en')"
                    v-bind:class="{ 'url-error': urlVerify.en }"
                  ></b-form-input>

                  <a
                    class="btn-copy"
                    title="Copy URL"
                    @click="copy(form.slug.en, 'urlEnCpyZone', 'en')"
                  >
                    <i class="fas fa-clipboard"></i>
                  </a>
                  <div id="urlEnCpyZone" class="hidden"></div>
                </div>
              </b-form-group>
            </b-tab>
            <button
              type="button"
              class="btn mx-1 btn-primary btn-sm"
              @click="onSubmit()"
            >
              Save
            </button>
          </b-tabs>

          <div
            class="flex-col mt-4 page-title-bg d-flex justify-item-center mb-2"
            style="flex: 1"
          >
            <toggle-button
              :value="true"
              :sync="true"
              v-model="form.show_banner"
              :disabled="!form.id"
              @change="onUpdateToggle()"
            />
            <h6 class="d-inline-block ml-2 m-0">Banner Slider</h6>
          </div>
          <div class="text-right">
            <button
              type="button"
              class="btn mb-2 btn-primary btn-sm"
              v-on:click="addBanner()"
              :disabled="!form.id"
            >
              Add New
            </button>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th width="80">Sort</th>
                <th>Image</th>
                <th>Last Updated</th>
                <th>Type</th>
                <th>Published</th>
                <th>Start/Stop Auto Published</th>
                <th>Open New Window</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(dataBanner, index) in campaignBanner"
                :key="`caban${index}_${dataBanner.id}`"
              >
                <td>
                  <b-form-select
                    :value="dataBanner.order_num"
                    :sync="true"
                    @change="(e) => onUpdateSortBanner(dataBanner, e)"
                  >
                    <option
                      v-for="ls in orderList.campaignBanner"
                      :key="ls"
                      :value="ls"
                    >
                      {{ ls }}
                    </option>
                  </b-form-select>
                </td>
                <td>
                  <img
                    :src="dataBanner && dataBanner.image ? renderImage(dataBanner.image.filename) : null"
                    alt
                    class="banner img-campaign"
                  />
                </td>
                <td>{{ renderDate(dataBanner.updated_at) }}</td>
                <td>
                  {{ dataBanner.link_type == "youtube" ? "video" : "image" }}
                </td>
                <td>
                  <toggle-button
                    :value="true"
                    :sync="true"
                    v-model="dataBanner.published"
                    @change="onSaveBanner(dataBanner)"
                    :disabled="validateDisable(dataBanner)"
                  />
                </td>
                <td>
                  <p class="start_stop_date">
                    Start: {{ renderDate(dataBanner.start_date) }}
                  </p>
                  <p class="start_stop_date">
                    End: {{ renderDate(dataBanner.end_date) }}
                  </p>
                </td>
                <td>
                  <div v-if="dataBanner.link_type == 'youtube'">N/A</div>
                  <div v-else>
                    <toggle-button
                      :value="true"
                      :sync="true"
                      v-model="dataBanner.open_new_tab"
                      @change="onSaveBanner(dataBanner)"
                    />
                  </div>
                </td>
                <td>
                  <b-button-group>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      class="mx-1"
                      v-on:click="() => addBanner(dataBanner)"
                      :disabled="!form.id"
                    >
                      Edit
                    </b-btn>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="btn-group-space"
                      v-on:click="
                        () => onDeleteCampaignBanner(dataBanner, index)
                      "
                      :disabled="!form.id"
                      >Delete</b-button
                    >
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex-col mt-4 page-title-bg d-flex justify-item-center mb-2"
            style="flex: 1"
          >
            <toggle-button
              :value="true"
              :sync="true"
              v-model="form.show_concept"
              :disabled="!form.id"
              @change="onUpdateToggle()"
            />
            <h6 class="d-inline-block ml-2 m-0">Concept Detail</h6>
          </div>
          <b-tabs v-if="form.show_concept" content-class="mt-3">
            <b-tab title="ภาษาไทย">
              <b-form-group>
                <TextEditor
                  :content="concept.th"
                  :onUpdateInfo="(e) => onUpdateContent(e, 'th')"
                />
              </b-form-group>
            </b-tab>
            <b-tab title="English">
              <b-form-group>
                <TextEditor
                  :content="concept.en"
                  :onUpdateInfo="(e) => onUpdateContent(e, 'en')"
                />
              </b-form-group>
            </b-tab>
            <button
              type="button"
              class="btn mx-1 btn-primary btn-sm"
              @click="onSubmitConcept()"
              :disabled="!form.id"
            >
              Save
            </button>
            <button
              type="button"
              class="btn mx-1 btn-outline-danger btn-sm"
              @click="fetchInfo()"
            >
              Cancel
            </button>
          </b-tabs>

          <div class="flex-col mt-4" style="flex: 1">
            <h6 class="page-title-bg">Register - Checkbox List</h6>
          </div>
          <div class="row">
            <div class="col-md-6 text-left red">
              *Add โครงการได้เฉพาะโครงการที่ Published เท่านั้น
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                class="btn mb-2 btn-primary btn-sm"
                v-if="form.template == 'brand'"
                v-on:click="addCheckboxBrand()"
                :disabled="!form.id"
              >
                Add New
              </button>
              <button
                type="button"
                class="btn mb-2 btn-primary btn-sm"
                v-if="form.template == 'campaign'"
                v-on:click="addCheckboxCampaign()"
                :disabled="!form.id"
              >
                Add New
              </button>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th width="80">No.</th>
                <th>Project</th>
                <th>Last Updated</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(dataRegister, index) in campaignRegisterProject"
                :key="`rePro_${index}_${dataRegister.id}`"
              >
                <td>
                  <b-form-select
                    :sync="true"
                    :value="dataRegister.order_num"
                    @change="
                      (e) => onSortCampaignRegisterProject(dataRegister, e)
                    "
                  >
                    <option
                      v-for="orderNum in orderList.registerProject"
                      :key="orderNum"
                      :value="orderNum"
                    >
                      {{ orderNum }}
                    </option>
                  </b-form-select>
                </td>
                <td>{{ dataRegister.project.name.th }}</td>
                <td>{{ renderDate(dataRegister.updated_at) }}</td>
                <td>
                  <toggle-button
                    :value="true"
                    :sync="true"
                    v-model="dataRegister.published"
                    @change="onSaveRegisterProject(dataRegister)"
                  />
                </td>
                <td>
                  <b-button-group>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      class="mx-1"
                      v-if="form.template == 'brand'"
                      v-on:click="addCheckboxBrand(dataRegister)"
                      :disabled="!form.id"
                    >
                      Edit
                    </b-btn>
                    <b-btn
                      size="sm"
                      variant="outline-primary"
                      class="mx-1"
                      v-if="form.template == 'campaign'"
                      v-on:click="addCheckboxCampaign(dataRegister)"
                      :disabled="!form.id"
                    >
                      Edit
                    </b-btn>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="btn-group-space"
                      v-on:click="
                        onDeleteCampaignRegisterProject(dataRegister, index)
                      "
                      :disabled="!form.id"
                    >
                      Delete
                    </b-button>
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- /// Only Brand /// -->
          <div v-if="form.template == 'brand'">
            <div
              class="flex-col mt-4 page-title-bg d-flex justify-item-center mb-2"
              style="flex: 1"
            >
              <toggle-button
                :value="true"
                :sync="true"
                v-model="form.show_project_thumbnails"
                @change="onUpdateToggle()"
              />
              <h6 class="d-inline-block ml-2 m-0">Project Thumbnail</h6>
            </div>
            <h6 class="mt-3">Title Section</h6>
            <div class="row my-2" style="text-align: left">
              <div class="col-md-6">
                <div class="form-group form-group-row">
                  Thai title
                  <input
                    type="text"
                    class="form-control"
                    v-model="titleThumbnail.th"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group form-group-row">
                  Eng title
                  <input
                    type="text"
                    class="form-control"
                    v-model="titleThumbnail.en"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="text-right">
              <button
                type="button"
                class="btn mb-2 btn-primary btn-sm"
                v-on:click="addProjectThumbnail()"
                :disabled="!form.id"
              >
                Add New
              </button>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th width="80">No.</th>
                  <th>Project</th>
                  <th>Last Updated</th>
                  <th>Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(dataCampaignProject, index) in campaignProject"
                  :key="`camproj_${index}_${dataCampaignProject.id}`"
                >
                  <td>
                    <b-form-select
                      :value="dataCampaignProject.order_num"
                      @change="
                        (e) => onThumbnailChangeSort(dataCampaignProject, e)
                      "
                    >
                      <option
                        v-for="orderNum in orderList.campaignProject"
                        :key="orderNum"
                        :value="orderNum"
                      >
                        {{ orderNum }}
                      </option>
                    </b-form-select>
                  </td>
                  <td>{{ dataCampaignProject.project.name.th }}</td>
                  <td>{{ renderDate(dataCampaignProject.updated_at) }}</td>
                  <td>
                    <toggle-button
                      :value="true"
                      :sync="true"
                      v-model="dataCampaignProject.published"
                      @change="onProjectThumbnailChange(dataCampaignProject)"
                    />
                  </td>
                  <td>
                    <b-button-group>
                      <b-btn
                        size="sm"
                        variant="outline-primary"
                        class="mx-1"
                        @click="addProjectThumbnail(dataCampaignProject)"
                        >Edit</b-btn
                      >
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        class="btn-group-space"
                        @click="onDeleteThumbnail(dataCampaignProject.id)"
                      >
                        Delete
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              type="button"
              class="btn mx-1 btn-primary btn-sm"
              @click="onSubmitTitleThumbnail()"
              :disabled="!form.id"
            >
              Save
            </button>
          </div>
          <!-- /// End /// -->

          <!-- /// Only Brand /// -->
          <div
            v-if="form.template == 'brand'"
            class="flex-col mt-4 page-title-bg d-flex justify-item-center mb-2"
            style="flex: 1"
          >
            <toggle-button
              :labels="true"
              :sync="true"
              v-model="form.show_article"
              :disabled="!form.id"
              @change="onUpdateToggle()"
            />
            <h6 class="d-inline-block ml-2 m-0">Article</h6>
          </div>
          <!-- /// End /// -->

          <!-- /// Only Campaign /// -->
          <div
            v-if="form.template == 'campaign'"
            class="flex-col mt-4 page-title-bg d-flex justify-item-center mb-2"
            style="flex: 1"
          >
            <toggle-button
              :labels="true"
              :sync="true"
              v-model="form.show_advance_search"
              :disabled="!form.id"
              @change="onUpdateToggle()"
            />
            <h6 class="d-inline-block ml-2 m-0">Advance Search</h6>
          </div>
          <!-- /// End /// -->

          <div class="flex-col mt-4" style="flex: 1">
            <h6 class="page-title-bg">SEO</h6>
            <b-tabs content-class="mt-3">
              <b-tab title="Tag Content Page">
                <b-tabs content-class="mt-3 p-2">
                  <b-tab title="ภาษาไทย">
                    <b-form-group
                      label="Tag Title (This will be displayed in the title bar of your Browser.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.content.title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.content.meta_title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Description">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag description"
                        v-model="seo.google.content.meta_description.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Keyword">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag keyword"
                        v-model="seo.google.content.meta_keyword.th"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="GTM ID">
                      <div
                        class="col-6 pl-0 py-2 d-flex"
                        v-for="(item, index) of seo.google.content.gtm_id.th"
                        :key="item"
                      >
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username"
                            placeholder="GTM-XXXXXXX"
                            :value="item"
                            @change="
                              (e) => onUpdateGtmId(e, index, 'content', 'th')
                            "
                          ></b-input>
                        </b-input-group>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          @click="(e) => onDeleteGtmId(index, 'content', 'th')"
                          >Delete</b-button
                        >
                      </div>

                      <div class="col-6 pl-0 d-flex">
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username-en"
                            placeholder="GTM-XXXXXXX"
                            v-model="gtm.content.th"
                          ></b-input>
                        </b-input-group>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onAddNewGtm('content', 'th')"
                        >
                          Add New
                        </button>
                      </div>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.content.tag_in_header.th"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.content.tag_in_body.th"
                      />
                    </b-form-group>

                    <!-- Facebook -->
                    <h6 class="page-title-bg">Facebook</h6>
                    <b-form-group label="og:url">
                      <b-form-input
                        type="text"
                        placeholder="Input og url"
                        v-model="seo.fb.content.url.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:title">
                      <b-form-input
                        type="text"
                        placeholder="Input og title"
                        v-model="seo.fb.content.meta_title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:description">
                      <b-form-input
                        type="text"
                        placeholder="Input og description"
                        v-model="seo.fb.content.meta_description.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.content.tag_in_header.th"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.content.tag_in_body.th"
                      />
                    </b-form-group>
                    <hr />
                  </b-tab>
                  <b-tab title="English">
                    <b-form-group
                      label="Tag Title (This will be displayed in the title bar of your Browser.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.content.title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.content.meta_title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Description">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag description"
                        v-model="seo.google.content.meta_description.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Keyword">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag keyword"
                        v-model="seo.google.content.meta_keyword.en"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="GTM ID">
                      <div
                        class="col-6 pl-0 py-2 d-flex"
                        v-for="(item, index) of seo.google.content.gtm_id.en"
                        :key="item"
                      >
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username"
                            placeholder="GTM-XXXXXXX"
                            :value="item"
                            @change="
                              (e) => onUpdateGtmId(e, index, 'content', 'en')
                            "
                          ></b-input>
                        </b-input-group>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          @click="(e) => onDeleteGtmId(index, 'content', 'en')"
                          >Delete</b-button
                        >
                      </div>

                      <div class="col-6 pl-0 d-flex">
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username-en"
                            placeholder="GTM-XXXXXXX"
                            v-model="gtm.content.en"
                          ></b-input>
                        </b-input-group>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onAddNewGtm('content', 'en')"
                        >
                          Add New
                        </button>
                      </div>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.content.tag_in_header.en"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.content.tag_in_body.en"
                      />
                    </b-form-group>

                    <!-- Facebook -->
                    <h6 class="page-title-bg">Facebook</h6>
                    <b-form-group label="og:url">
                      <b-form-input
                        type="text"
                        placeholder="Input og url"
                        v-model="seo.fb.content.url.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:title">
                      <b-form-input
                        type="text"
                        placeholder="Input og title"
                        v-model="seo.fb.content.meta_title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:description">
                      <b-form-input
                        type="text"
                        placeholder="Input og description"
                        v-model="seo.fb.content.meta_description.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.content.tag_in_header.en"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.content.tag_in_body.en"
                      />
                    </b-form-group>
                    <hr />
                  </b-tab>
                </b-tabs>
              </b-tab>
              <b-tab title="Tag Thank You Page">
                <b-tabs content-class="mt-3 p-2">
                  <b-tab title="ภาษาไทย">
                    <b-form-group
                      label="Tag Title (This will be displayed in the title bar of your Browser.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.thankyou.title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.thankyou.meta_title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Description">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag description"
                        v-model="seo.google.thankyou.meta_description.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Keyword">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag keyword"
                        v-model="seo.google.thankyou.meta_keyword.th"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="GTM ID">
                      <div
                        class="col-6 pl-0 py-2 d-flex"
                        v-for="(item, index) of seo.google.thankyou.gtm_id.th"
                        :key="item"
                      >
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username"
                            placeholder="GTM-XXXXXXX"
                            :value="item"
                            @change="
                              (e) => onUpdateGtmId(e, index, 'thankyou', 'th')
                            "
                          ></b-input>
                        </b-input-group>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          @click="(e) => onDeleteGtmId(index, 'thankyou', 'th')"
                          >Delete</b-button
                        >
                      </div>

                      <div class="col-6 pl-0 d-flex">
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username-en"
                            placeholder="GTM-XXXXXXX"
                            v-model="gtm.thankyou.th"
                          ></b-input>
                        </b-input-group>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onAddNewGtm('thankyou', 'th')"
                        >
                          Add New
                        </button>
                      </div>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.thankyou.tag_in_header.th"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.thankyou.tag_in_body.th"
                      />
                    </b-form-group>

                    <!-- Facebook -->
                    <h6 class="page-title-bg">Facebook</h6>
                    <b-form-group label="og:url">
                      <b-form-input
                        type="text"
                        placeholder="Input og url"
                        v-model="seo.fb.thankyou.url.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:title">
                      <b-form-input
                        type="text"
                        placeholder="Input og title"
                        v-model="seo.fb.thankyou.meta_title.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:description">
                      <b-form-input
                        type="text"
                        placeholder="Input og description"
                        v-model="seo.fb.thankyou.meta_description.th"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.thankyou.tag_in_header.th"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.thankyou.tag_in_body.th"
                      />
                    </b-form-group>
                    <hr />
                  </b-tab>
                  <b-tab title="English">
                    <b-form-group
                      label="Tag Title (This will be displayed in the title bar of your Browser.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.thankyou.title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Meta tag Title (Whatever you type in here will be displayed on search engines.)"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Input meta"
                        v-model="seo.google.thankyou.meta_title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Description">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag description"
                        v-model="seo.google.thankyou.meta_description.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Meta tag Keyword">
                      <b-form-input
                        type="text"
                        placeholder="Input meta tag keyword"
                        v-model="seo.google.thankyou.meta_keyword.en"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="GTM ID">
                      <div
                        class="col-6 pl-0 py-2 d-flex"
                        v-for="(item, index) of seo.google.thankyou.gtm_id.en"
                        :key="item"
                      >
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username"
                            placeholder="GTM-XXXXXXX"
                            :value="item"
                            @change="
                              (e) => onUpdateGtmId(e, index, 'thankyou', 'en')
                            "
                          ></b-input>
                        </b-input-group>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          @click="(e) => onDeleteGtmId(index, 'thankyou', 'en')"
                          >Delete</b-button
                        >
                      </div>

                      <div class="col-6 pl-0 d-flex">
                        <b-input-group
                          prepend="Input GTM ID"
                          class="mb-2 mr-sm-2 mb-sm-0"
                        >
                          <b-input
                            id="inline-form-input-username-en"
                            placeholder="GTM-XXXXXXX"
                            v-model="gtm.thankyou.en"
                          ></b-input>
                        </b-input-group>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="onAddNewGtm('thankyou', 'en')"
                        >
                          Add New
                        </button>
                      </div>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.thankyou.tag_in_header.en"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.google.thankyou.tag_in_body.en"
                      />
                    </b-form-group>

                    <!-- Facebook -->
                    <h6 class="page-title-bg">Facebook</h6>
                    <b-form-group label="og:url">
                      <b-form-input
                        type="text"
                        placeholder="Input og url"
                        v-model="seo.fb.thankyou.url.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:title">
                      <b-form-input
                        type="text"
                        placeholder="Input og title"
                        v-model="seo.fb.thankyou.meta_title.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="og:description">
                      <b-form-input
                        type="text"
                        placeholder="Input og description"
                        v-model="seo.fb.thankyou.meta_description.en"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Tag In Header">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.thankyou.tag_in_header.en"
                      />
                    </b-form-group>
                    <b-form-group label="Tag In Body">
                      <b-form-textarea
                        required
                        placeholder="HTML/Javascript code"
                        rows="3"
                        max-rows="6"
                        v-model="seo.fb.thankyou.tag_in_body.en"
                      />
                    </b-form-group>
                    <hr />
                  </b-tab>
                </b-tabs>
              </b-tab>
            </b-tabs>
          </div>

          <button
            type="button"
            class="btn mx-1 btn-primary btn-sm"
            @click="onSubmitSeo()"
            :disabled="!form.id"
          >
            Update
          </button>
        </b-card>
      </form>
    </div>

    <EditBrandCampaignBannerModal
      :data="modal.banner"
      :onSubmitForm="(e) => onBannerChange(e)"
      :onFetch="() => fetchCampaignBanner()"
    />

    <EditBrandCampaignCheckboxBrandModal
      v-if="form.template == 'brand'"
      :categoryID="form.project_category_id"
      :data="modal.registerProject"
      :registerProjectList="campaignRegisterProject"
      :onSubmitForm="(e) => onRegisterProjectChange(e)"
      :onFetch="() => fetchCampaignBanner()"
    />

    <EditBrandCampaignCheckboxCampaignModal
      :categoryList="categoryList"
      :categoryID="form.project_category_id"
      :data="modal.registerProject"
      :registerProjectList="campaignRegisterProject"
      :onSubmitForm="(e) => onRegisterProjectChange(e)"
      :onFetch="() => fetchCampaignBanner()"
    />
    <EditBrandCampaignProjectThumbnailModal
      v-if="form.template == 'brand'"
      :thumbnailList="campaignProject"
      :categoryID="form.project_category_id"
      :data="modal.projectThumbnail"
      :onSubmitForm="(e) => onProjectThumbnailChange(e)"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import TextEditor from '../../components/text-editor/TextEditor'
import EditBrandCampaignBannerModal from '../../components/modals/EditBrandCampaignBannerModal'
import EditBrandCampaignCheckboxBrandModal from '../../components/modals/EditBrandCampaignCheckboxBrandModal'
import EditBrandCampaignCheckboxCampaignModal from '../../components/modals/EditBrandCampaignCheckboxCampaignModal'
import EditBrandCampaignProjectThumbnailModal from '../../components/modals/EditBrandCampaignProjectThumbnailModal'

export default {
  data: () => ({
    categoryList: [],
    campaignBanner: [],
    campaignRegisterProject: [],
    campaignProject: [],
    titleThumbnail: {
      th: '',
      en: ''
    },
    urlVerify: {
      th: false,
      en: false
    },
    orderList: {
      campaignBanner: [],
      registerProject: [],
      campaignProject: []
    },
    modal: {
      banner: {},
      registerProject: {
        campaign_id: '',
        project_id: '',
        image: {
          filename: '',
          alt: ''
        },
        description: {
          th: '',
          en: ''
        },
        price: {
          th: '',
          en: ''
        },
        url: {
          th: '',
          en: ''
        }
      },
      projectThumbnail: {
        campaign_id: '',
        project_id: '',
        description: {
          th: '',
          en: ''
        },
        published: false,
        thumbnail: {
          filename: '',
          alt: ''
        },
        title: {
          th: '',
          en: ''
        }
      }
    },
    gtm: {
      content: {
        th: '',
        en: ''
      },
      thankyou: {
        th: '',
        en: ''
      }
    },
    form: {
      project_category_id: '',
      template: '',
      show_banner: false,
      show_concept: false,
      show_project_thumbnails: false,
      show_article: false,
      slug: {
        th: '',
        en: ''
      },
      name: {
        th: '',
        en: ''
      },
      detail: {
        th: '',
        en: ''
      }
    },
    concept: {
      th: '',
      en: ''
    },
    seo: {
      google: {
        content: {
          title: {
            th: '',
            en: ''
          },
          meta_title: {
            th: '',
            en: ''
          },
          meta_keyword: {
            th: '',
            en: ''
          },
          meta_description: {
            th: '',
            en: ''
          },
          gtm_id: {
            th: [],
            en: []
          },
          tag_in_header: {
            th: '',
            en: ''
          },
          tag_in_body: {
            th: '',
            en: ''
          }
        },
        thankyou: {
          title: {
            th: '',
            en: ''
          },
          meta_title: {
            th: '',
            en: ''
          },
          meta_keyword: {
            th: '',
            en: ''
          },
          meta_description: {
            th: '',
            en: ''
          },
          gtm_id: {
            th: [],
            en: []
          },
          tag_in_header: {
            th: '',
            en: ''
          },
          tag_in_body: {
            th: '',
            en: ''
          }
        }
      },
      fb: {
        content: {
          image: {
            th: '',
            en: ''
          },
          image_mobile: {
            th: '',
            en: ''
          },
          meta_title: {
            th: '',
            en: ''
          },
          meta_keyword: {
            th: '',
            en: ''
          },
          meta_description: {
            th: '',
            en: ''
          },
          url: {
            th: '',
            en: ''
          },
          tag_in_header: {
            th: '',
            en: ''
          },
          tag_in_body: {
            th: '',
            en: ''
          }
        },
        thankyou: {
          image: {
            th: '',
            en: ''
          },
          image_mobile: {
            th: '',
            en: ''
          },
          meta_title: {
            th: '',
            en: ''
          },
          meta_keyword: {
            th: '',
            en: ''
          },
          meta_description: {
            th: '',
            en: ''
          },
          url: {
            th: '',
            en: ''
          },
          tag_in_header: {
            th: '',
            en: ''
          },
          tag_in_body: {
            th: '',
            en: ''
          }
        }
      }
    }
  }),
  components: {
    TextEditor,
    EditBrandCampaignBannerModal,
    EditBrandCampaignCheckboxBrandModal,
    EditBrandCampaignCheckboxCampaignModal,
    EditBrandCampaignProjectThumbnailModal
  },
  methods: {
    ...mapActions({
      getCampaignTitleThumbnail: ActionFetch.CAMPAIGNTITLETHUMBNAIL.INFO,
      updateCampaignTitleThumbnail: ActionFetch.CAMPAIGNTITLETHUMBNAIL.UPDATE,
      getCampaignProject: ActionFetch.CAMPAIGNPROJECT.LIST,
      createCampaignProject: ActionFetch.CAMPAIGNPROJECT.CREATE,
      updateCampaignProject: ActionFetch.CAMPAIGNPROJECT.UPDATE,
      sortCampaignProject: ActionFetch.CAMPAIGNPROJECT.SORT,
      deleteCampaignProject: ActionFetch.CAMPAIGNPROJECT.DELETE,
      getCampaignRegisterProject: ActionFetch.CAMPAIGNREGISTERPROJECT.LIST,
      createCampaignRegisterProject: ActionFetch.CAMPAIGNREGISTERPROJECT.CREATE,
      updateCampaignRegisterProject: ActionFetch.CAMPAIGNREGISTERPROJECT.UPDATE,
      deleteCampaignRegisterProject: ActionFetch.CAMPAIGNREGISTERPROJECT.DELETE,
      sortCampaignRegisterProject: ActionFetch.CAMPAIGNREGISTERPROJECT.SORT,
      getCampaignBanner: ActionFetch.CAMPAIGNBANNER.LIST,
      createCampaignBanner: ActionFetch.CAMPAIGNBANNER.CREATE,
      updateCampaignBanner: ActionFetch.CAMPAIGNBANNER.UPDATE,
      deleteCampaignBanner: ActionFetch.CAMPAIGNBANNER.DELETE,
      sortCampaignBanner: ActionFetch.CAMPAIGNBANNER.UPDATESORT,
      getCategoryList: ActionFetch.PROJECTS.CATEGORIES.INDEX,
      createData: ActionFetch.CAMPAIGNS.CREATE,
      getInfo: ActionFetch.CAMPAIGNS.DETAIL,
      updateData: ActionFetch.CAMPAIGNS.UPDATE,
      getConcept: ActionFetch.CAMPAIGNCONCEPT.LIST,
      createConcept: ActionFetch.CAMPAIGNCONCEPT.CREATE,
      updateConcept: ActionFetch.CAMPAIGNCONCEPT.UPDATE,
      getSeoContent: ActionFetch.CAMPAIGNSEO.CONTENT.LIST,
      createSeoContent: ActionFetch.CAMPAIGNSEO.CONTENT.CREATE,
      updateSeoContent: ActionFetch.CAMPAIGNSEO.CONTENT.UPDATE,
      getSeoThankyou: ActionFetch.CAMPAIGNSEO.THANKYOU.LIST,
      createSeoThankyou: ActionFetch.CAMPAIGNSEO.THANKYOU.CREATE,
      updateSeoThankyou: ActionFetch.CAMPAIGNSEO.THANKYOU.UPDATE,
      getSeoContentFb: ActionFetch.CAMPAIGNSEO.CONTENT.FBLIST,
      createSeoContentFb: ActionFetch.CAMPAIGNSEO.CONTENT.FBCREATE,
      updateSeoContentFb: ActionFetch.CAMPAIGNSEO.CONTENT.FBUPDATE,
      getSeoThankyouFb: ActionFetch.CAMPAIGNSEO.THANKYOU.FBLIST,
      createSeoThankyouFb: ActionFetch.CAMPAIGNSEO.THANKYOU.FBCREATE,
      updateSeoThankyouFb: ActionFetch.CAMPAIGNSEO.THANKYOU.FBUPDATE
    }),
    validateDisable ($data) {
      let result = !!$data.start_date && !!$data.end_date
      return result
    },
    addBanner ($data = null) {
      if (!$data) {
        this.modal.banner = {
          image: {
            filename: '',
            alt: '',
            status: 'Active'
          },
          image_mobile: {
            filename: '',
            alt: '',
            status: 'Active'
          },
          open_new_tab: false,
          link_type: '',
          url: '',
          youtube: '',
          start_date: null,
          end_date: null,
          campaign_id: this.form.id
        }
      } else {
        this.modal.banner = $data
      }
      this.$bvModal.show('add-banner')
    },
    onSubmitTitleThumbnail () {
      this.updateCampaignTitleThumbnail({
        ...this.titleThumbnail,
        campaign_id: +this.form.id
      }).then(($res) => {
        this.fetchTitileThumbnail()
      })
    },
    onSaveCampaignBanner () {
      this.campaignBanner.forEach(($banner) => {
        if (!$banner.id) {
          return this.createCampaignBanner($banner)
        }
      })
    },
    renderImage ($image) {
      return `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$image}`
    },
    onBannerChange ($data) {
      $data.start_date = $data.start_date
        ? format($data.start_date, 'YYYY-MM-DD')
        : null
      $data.end_date = $data.end_date
        ? format($data.end_date, 'YYYY-MM-DD')
        : null

      if (!$data.id) {
        this.createCampaignBanner($data).then(($res) => {
          $data.id = $res.id
          this.fetchCampaignBanner()
        })
      } else {
        this.updateCampaignBanner($data).then(($res) => {
          this.fetchCampaignBanner()
        })
      }
    },
    onProjectThumbnailChange ($data) {
      if (!$data.id) {
        this.createCampaignProject($data).then(($res) => {
          this.fetchCampaignProject()
        })
      } else {
        this.updateCampaignProject($data).then(($res) => {
          this.fetchCampaignProject()
        })
      }
    },
    onDeleteThumbnail ($id) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($res) => {
          if ($res) {
            const params = {
              id: $id,
              campaign_id: this.form.id
            }

            this.deleteCampaignProject(params).then(($res) => {
              this.fetchCampaignProject()
            })
          }
        })
    },
    onThumbnailChangeSort ($data, $value) {
      const params = {
        campaign_id: this.form.id,
        id: $data.id,
        order_num: $value
      }

      this.sortCampaignProject(params).then(($res) => {
        this.fetchCampaignProject()
      })
    },
    onRegisterProjectChange ($data) {
      this.onSaveRegisterProject($data)
    },
    onSaveRegisterProject ($data) {
      if (!$data.id) {
        this.createCampaignRegisterProject($data).then(($res) => {
          this.fetchCampaignRegisterProject()
        }).finally(() => {
          this.$bvModal.hide('add-checkbox-campaign')
        })
      } else {
        this.updateCampaignRegisterProject($data).then(($res) => {
          this.fetchCampaignRegisterProject()
        }).finally(() => {
          this.$bvModal.hide('add-checkbox-campaign')
        })
      }
    },
    addCheckboxBrand ($data = null) {
      if (!$data) {
        this.modal.registerProject = {
          campaign_id: this.form.id,
          project_id: '',
          image: {
            filename: '',
            alt: ''
          },
          description: {
            th: '',
            en: ''
          },
          price: {
            th: '',
            en: ''
          },
          url: {
            th: '',
            en: ''
          }
        }
      } else {
        this.modal.registerProject = $data
      }

      this.$bvModal.show('add-checkbox-brand')
    },
    addCheckboxCampaign ($data = null) {
      if (!$data) {
        this.modal.registerProject = {
          campaign_id: this.form.id,
          project_id: '',
          image: {
            filename: '',
            alt: ''
          },
          description: {
            th: '',
            en: ''
          },
          price: {
            th: '',
            en: ''
          },
          url: {
            th: '',
            en: ''
          }
        }
      } else {
        this.modal.registerProject = $data
      }

      this.$bvModal.show('add-checkbox-campaign')
    },
    addProjectThumbnail ($data = null) {
      if (!$data) {
        this.modal.projectThumbnail = {
          campaign_id: this.form.id,
          project_id: '',
          description: {
            th: '',
            en: ''
          },
          published: false,
          thumbnail: {
            filename: '',
            alt: ''
          },
          title: {
            th: '',
            en: ''
          }
        }
      } else {
        this.modal.projectThumbnail = $data
      }

      this.$bvModal.show('add-project-thumbnail')
    },
    onUpdateContent ($value, $lang) {
      this.concept[$lang] = $value
    },
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : ''
    },
    onDeleteCampaignBanner ($data, $index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($res) => {
          if ($res) {
            if ($data.id) {
              const params = {
                campaign_id: this.form.id,
                id: $data.id
              }
              this.deleteCampaignBanner(params).then(($resDel) => {
                this.fetchCampaignBanner()
              })
            } else {
              this.campaignBanner.splice($index, 1)
            }
          }
        })
    },
    onDeleteCampaignRegisterProject ($data, $index) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($res) => {
          if ($res) {
            if ($data.id) {
              const params = {
                campaign_id: this.form.id,
                id: $data.id
              }

              this.deleteCampaignRegisterProject(params).then(($resDel) => {
                this.fetchCampaignRegisterProject()
              })
            } else {
              this.campaignRegisterProject.splice($index, 1)
            }
          }
        })
    },
    copy (url, target, $lang) {
      const inputDestination = document.createElement('input')

      inputDestination.value = `${this.getDefaultUrl($lang)}${url}`

      document.getElementById(target).appendChild(inputDestination)
      inputDestination.focus()
      inputDestination.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.getElementById(target).innerHTML = ''
      }, 1000)
    },
    fetchCampaignRegisterProject () {
      const params = {
        campaignID: +this.form.id,
        orderBy: 'order_num',
        direction: 'ASC'
      }

      if (+this.form.id) {
        this.getCampaignRegisterProject(params).then(($res) => {
          this.campaignRegisterProject = $res.data ? $res.data : []
        })
      }
    },
    fetchCategory () {
      this.getCategoryList({ limit: 0 }).then(($res) => {
        this.categoryList = $res.data
      })
    },
    fetchCampaignProject () {
      const params = {
        campaignID: +this.form.id,
        orderBy: 'order_num',
        direction: 'ASC'
      }

      if (this.form.id) {
        this.getCampaignProject(params).then(($res) => {
          this.campaignProject = $res.data ? $res.data : []
        })
      }
    },
    fetchTitileThumbnail () {
      const params = {
        campaign_id: +this.form.id
      }

      if (+this.form.id) {
        this.getCampaignTitleThumbnail(params).then(($res) => {
          this.titleThumbnail = $res.title_campaign_project
        })
      }
    },
    fetchCampaignBanner () {
      const params = {
        campaignID: +this.form.id,
        orderBy: 'order_num',
        direction: 'ASC'
      }

      if (+this.form.id) {
        this.getCampaignBanner(params).then(($res) => {
          this.campaignBanner = $res.data ? $res.data : []
        })
      }
    },
    fetchInfo () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(params).then(($res) => {
          this.form = $res
        })

        this.getConcept(params).then(($res) => {
          this.concept = $res.concept
        })

        this.getSeoContent(params).then(($res) => {
          Object.assign(this.seo.google.content, { ...$res, ref_id: +this.$route.params.id })
        })

        this.getSeoThankyou(params).then(($res) => {
          Object.assign(this.seo.google.thankyou, { ...$res, ref_id: +this.$route.params.id })
        })

        this.getSeoContentFb(params).then(($res) => {
          Object.assign(this.seo.fb.content, { ...$res, ref_id: +this.$route.params.id })
        })

        this.getSeoThankyouFb(params).then(($res) => {
          Object.assign(this.seo.fb.thankyou, { ...$res, ref_id: +this.$route.params.id })
        })
      }
    },
    onAddNewGtm ($type, $lang = 'th') {
      this.seo.google[$type].gtm_id[$lang].push(this.gtm[$type][$lang])

      this.gtm[$type][$lang] = ''
    },
    onUpdateGtmId ($value, $index, $type, $lang = 'th') {
      this.seo.google[$type].gtm_id[$lang][$index] = $value
    },
    onDeleteGtmId ($index, $type, $lang) {
      this.seo.google[$type].gtm_id[$lang].splice($index, 1)
    },
    getDefaultUrl ($lang) {
      if (this.form.template === 'brand') {
        return `${process.env.VUE_APP_DOMAIN}/${$lang}/`
      } else {
        return `${process.env.VUE_APP_DOMAIN}/${$lang}/campaign/`
      }
    },
    onUpdatePageUrl ($data, $lang) {
      const regexMatchPattern = this.form.template === 'campaign' ? /^[a-zก-๙0-9-]+$/ : /^[a-zก-๙0-9]+$/
      const regexReplacePattern = this.form.template === 'campaign' ? / /g : /[ -]/g
      const regexReplaceTarget = this.form.template === 'campaign' ? '-' : ''

      if ($data.target.value) {
        this.form.slug[$lang] = $data.target.value
          .replace(regexReplacePattern, regexReplaceTarget)
          .toLowerCase()

        this.urlVerify[$lang] = !regexMatchPattern.test(this.form.slug[$lang])
      }
    },
    onUpdateSortBanner ($data, $value) {
      const params = {
        campaign_id: this.form.id,
        id: $data.id,
        order_num: $value
      }

      this.sortCampaignBanner(params).then(($res) => {
        this.fetchCampaignBanner()
      })
    },
    onSortCampaignRegisterProject ($data, $value) {
      const params = {
        campaign_id: this.form.id,
        id: $data.id,
        order_num: $value
      }
      this.sortCampaignRegisterProject(params).then(($res) => {
        this.fetchCampaignRegisterProject()
      })
    },
    onSaveBanner ($banner) {
      this.updateCampaignBanner($banner)
    },
    onSubmitConcept () {
      if (!this.concept.th) {
        this.$bvToast.toast(`Please input thai concept`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.concept.en) {
        this.$bvToast.toast(`Please input english concept`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        this.updateConcept({ ...this.concept, id: this.form.id }).then(
          ($res) => {
            this.$bvToast.toast(`Update successful`, {
              title: 'Brand & Campaign update',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          }
        )
      }
    },
    onSubmit () {
      const formSave = { ...this.form }
      let canSave = false

      if (formSave.template === 'brand') {
        const category = this.categoryList.find(($category) => {
          return $category.id === formSave.project_category_id
        })

        if (category) {
          formSave.name.th = category.title.th
          this.form.name.th = category.title.th
        }
      }

      if (!formSave.name.th) {
        this.$bvToast.toast(`Please input title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!formSave.slug.th) {
        this.$bvToast.toast(`Please input thai url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!formSave.slug.en) {
        this.$bvToast.toast(`Please input english url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        canSave = true
      }

      if (canSave) {
        if (!formSave.id) {
          // NOTE: INSERT
          this.createData(formSave).then(async ($res) => {
            this.form.id = $res.id

            this.seo.google.content = {
              ...this.seo.google.content,
              ref_id: $res.id
            }

            this.seo.google.thankyou = {
              ...this.seo.google.thankyou,
              ref_id: $res.id
            }
            this.seo.fb.content = { ...this.seo.fb.content, ref_id: $res.id }
            this.seo.fb.thankyou = { ...this.seo.fb.thankyou, ref_id: $res.id }

            this.createSeoContent(this.seo.google.content)
            this.createSeoThankyou(this.seo.google.thankyou)
            this.createSeoContentFb(this.seo.fb.content)
            this.createSeoThankyouFb(this.seo.fb.thankyou)

            this.$router.replace(`/project/brand-campaign/${this.form.id}/edit`)

            this.$bvToast.toast(`Create successful`, {
              title: 'Brand & Campaign created',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          })
        } else {
          // NOTE: UPDATE

          this.updateData(formSave).then(($res) => {
            this.$bvToast.toast(`Update successful`, {
              title: 'Brand & Campaign update',
              autoHideDelay: 2000,
              appendToast: true,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              solid: true
            })
          })
        }
      }
    },
    onUpdateToggle () {
      this.updateData(this.form)
    },
    onSubmitSeo () {
      this.updateSeoContent(this.seo.google.content)
      this.updateSeoThankyou(this.seo.google.thankyou)
      this.updateSeoContentFb(this.seo.fb.content)
      this.updateSeoThankyouFb(this.seo.fb.thankyou)
    }
  },
  watch: {
    campaignBanner: function (value) {
      this.orderList.campaignBanner = []

      for (let ls = 1; ls <= value.length; ls++) {
        this.orderList.campaignBanner.push(ls)
      }
    },
    campaignRegisterProject: function (value) {
      this.orderList.registerProject = []

      for (let ls = 1; ls <= value.length; ls++) {
        this.orderList.registerProject.push(ls)
      }
    },
    campaignProject: function (value) {
      this.orderList.campaignProject = []

      for (let ls = 1; ls <= value.length; ls++) {
        this.orderList.campaignProject.push(ls)
      }
    },
    'form.id': function (value) {
      this.fetchCampaignBanner()
      this.fetchCampaignRegisterProject()
      this.fetchCampaignProject()
      this.fetchTitileThumbnail()
    }
  },
  created () {
    this.fetchCategory()
    this.fetchInfo()
  }
}
</script>

<style>
.inline-zone {
  margin-right: 10px;
  font-weight: 600;
  min-width: 25%;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
  min-width: 120px;
  max-width: 200px;
}

.table.custom-news td {
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  vertical-align: middle;
}

.table.custom-news tr.footer td {
  vertical-align: top;
}

.add-u-btn {
  margin-top: 20px;
}

.alt-thumb {
  margin: auto;
}

.url-error {
  border-color: #ff0000;
}

.url-error:focus {
  border-color: #ff0000;
  box-shadow: 0 0 0 0rem rgba(255, 0, 0, 0.25);
}

.hidden {
  opacity: 0;
}
.w-70px {
  width: 70px;
}
td {
  vertical-align: middle !important;
}
.gallery-img-container {
  max-width: 250px;
}
.orderBox {
  width: 80px;
}

.page-title-bg {
  background: #f1f0f0;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.img-campaign {
  max-width: 10vw;
}
</style>

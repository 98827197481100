<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Article</div>
    </div>
    <div class="page-container">
      <form @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Add/Edit Article List</h5>
            </div>
          </div>
          <hr />
          <div class="form-group form-group-row">
            <b-form-group
              class="mb-0 col-12"
              label="Content Type (Select Content Template)"
              label-for="input-formatter"
            >
              <b-form-select
                v-model="form.type"
                class="col-8 col-lg-6 col-xl-4"
                :options="optionArticleType"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <hr />
          <div class="form-group form-group-row">
            <b-form-group
              class="mb-0 col-12"
              label="Project Microsite"
              label-for="input-formatter"
            >
              <multiselect
                v-model="form.projectId"
                tag-placeholder=""
                placeholder="Search or add a tag"
                label="name"
                track-by="id"
                :options="projectOption"
                :multiple="true"
                :taggable="true"
                required
              ></multiselect>
            </b-form-group>
          </div>
          <div id="thumbnail-section">
            <div id="thumbs-1" class="thumb-item">
              <div class="flex-row">
                <div class="thumbnail-picker-wrapper">
                  <ImageUploadNew
                    :dataImage="{ ...articleThumb }"
                    :showError="uploadThumbnailError"
                    :isRequired="false"
                    :maxFile="1"
                    :uploadSetting="{ ...uploadSetting }"
                    :onFileUploaded="onThumbnailUploaded"
                  />
                </div>

                <div class="flex-1">
                  <b-form-group
                    style="font-weight: bold; color: #666"
                    label="Thumbnail (500 x 280 pixel)"
                  >
                    <div class="flex-row col-10 no-padding">
                      <p
                        style="
                          margin-right: 5px;
                          flex: 1 15%;
                          line-height: 30px;
                        "
                      >
                        ALT Text
                      </p>
                      <b-form-input
                        class=""
                        v-model="articleThumb.name"
                        type="text"
                        placeholder="Typing Something..."
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div>
            <hr />
            <div class="form-group form-group-row">
              <span style="font-weight: 600; margin-right: 10px"
                >Post Date:</span
              >
              <date-picker
                lang="en"
                placeholder="Select Date"
                class="date-picker"
                v-model="form.showDate"
                required
              />
            </div>
            <hr />
            <div class="row" style="text-align: left">
              <div class="col-md-6">
                <div class="form-group form-group-row">
                  <span class="inline-zone">Published Date:</span>
                  <span style="font-weight: 600; margin-right: 10px"
                    >Date Start</span
                  >
                  <date-picker
                    v-model="form.startDate"
                    lang="en"
                    placeholder="Select Date"
                    class="date-picker"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group form-group-row">
                  <span style="font-weight: 600; margin-right: 10px"
                    >Date Stop</span
                  >
                  <date-picker
                    v-model="form.endDate"
                    lang="en"
                    placeholder="Select Date"
                    class="date-picker"
                    :not-before="renderDate(form.startDate)"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div v-if="form.type == 'Link_out'">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group form-group-row">
                    <span style="font-weight: 600">Thai title</span>
                    <b-form-input
                      v-model="form.title.th"
                      type="text"
                      placeholder="Typing Something..."
                      required
                    ></b-form-input>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group form-group-row">
                    <span style="font-weight: 600; margin-right: 10px"
                      >Eng title</span
                    >
                    <b-form-input
                      v-model="form.title.en"
                      type="text"
                      placeholder="Typing Something..."
                    ></b-form-input>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-12">
                  <div class="form-group form-group-row">
                    <b-form-group
                      class="mb-0 col-12"
                      label="Content Type (Select Content Template)"
                      label-for="input-formatter"
                    >
                      <b-form-input
                        required
                        v-model="form.url.th"
                        type="text"
                        placeholder="URL External"
                        @change="(e) => onUpdateExternalURL(e)"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="form.type == 'Internal'">
              <b-tabs content-class="mt-3">
                <b-tab
                  title="ภาษาไทย"
                  @click="tab = 'th'"
                  :active="tab === 'th'"
                >
                  <b-form-group>
                    <label for class="label">
                      <span style="font-weight: 600; margin-right: 10px"
                        >Page URL</span
                      >
                      <span class="red"
                        >(use only non-capital letters, no space )</span
                      >
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          {{ getDefaultUrl("th") }}
                        </div>
                      </div>
                      <b-form-input
                        class="col-lg-4"
                        type="text"
                        placeholder="detail"
                        v-model="form.url.th"
                        @keyup="(e) => onUpdatePageUrl(e, 'th')"
                      ></b-form-input>

                      <a
                        @click="copy(form.url.th, 'urlThCpyZone', 'th')"
                        class="btn-copy"
                        v-b-tooltip.hover.bottom
                        title="Copy URL"
                        v-if="form.id"
                      >
                        <i class="fas fa-clipboard"></i>
                      </a>
                      <div id="urlThCpyZone" class="hidden"></div>
                    </div>
                  </b-form-group>

                  <b-form-group style="font-weight: 600" label="Article Title">
                    <b-form-input
                      v-model="form.title.th"
                      type="text"
                      placeholder="Input Article Title"
                    ></b-form-input>
                  </b-form-group>
                  <TextEditor
                    :content="form.content.th"
                    :onUpdateInfo="onUpdateContentTH"
                  />
                </b-tab>
                <b-tab
                  title="English"
                  @click="tab = 'en'"
                  :active="tab === 'en'"
                >
                  <b-form-group>
                    <label for class="label">
                      <span style="font-weight: 600; margin-right: 10px"
                        >Page URL</span
                      >
                      <span class="red"
                        >(use only non-capital letters, no space )</span
                      >
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          {{ getDefaultUrl("en") }}
                        </div>
                      </div>
                      <b-form-input
                        class="col-lg-4"
                        type="text"
                        placeholder="detail"
                        v-model="form.url.en"
                        @keyup="(e) => onUpdatePageUrl(e, 'en')"
                      ></b-form-input>

                      <a
                        @click="copy(form.url.en, 'urlEnCpyZone', 'en')"
                        class="btn-copy"
                        v-b-tooltip.hover.bottom
                        title="Copy URL"
                        v-if="form.id"
                      >
                        <i class="fas fa-clipboard"></i>
                      </a>
                      <div id="urlEnCpyZone" class="hidden"></div>
                    </div>
                  </b-form-group>

                  <b-form-group style="font-weight: 600" label="Article Title">
                    <b-form-input
                      v-model="form.title.en"
                      type="text"
                      placeholder="Input Article Title"
                    ></b-form-input>
                  </b-form-group>
                  <TextEditor
                    :content="form.content.en"
                    :onUpdateInfo="onUpdateContentEN"
                  />
                </b-tab>
              </b-tabs>

              <hr />
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <th width="120">Sort</th>
                    <th>Images</th>
                  </thead>
                  <tbody>
                    <tr v-for="(img, index) in galleryCurrent" :key="index">
                      <td width="120">
                        <b-form-select
                          class="orderBox"
                          name
                          id
                          v-model="img.orderNum"
                          @change="(value) => onSwapImg(value, index)"
                        >
                          <option
                            v-for="i in galleryCurrent.length"
                            :value="i - 1"
                            :key="i"
                          >
                            {{ i }}
                          </option>
                        </b-form-select>
                      </td>
                      <td>
                        <div class="gallery-img-container">
                          <ImageUploadNew
                            :dataImage="{ ...img }"
                            :uploadSetting="{ ...uploadSettingGellery }"
                            :onFileUploaded="
                              (e) => onUploadGallery(e, img, index)
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="galleryCurrent.length === 0">
                      <td colspan="5">
                        <div class="text-center">
                          <i>Empty data.</i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <FileUploadMulti
                :multiple="true"
                :title="'Upload Files'"
                :instantUpload="true"
                :onFileUploaded="onUploaded"
                :files="articleGallery"
                :className="'wrapper-files file-upload-gallery'"
                :allowImagePreview="false"
              />
              <hr />
              <div class="form-group form-group-row">
                <b-form-group
                  class="mb-0 col-12"
                  label="Video (Youtube)"
                  label-for="input-formatter"
                >
                </b-form-group>
              </div>
              <table class="table custom-news">
                <tbody>
                  <tr v-for="(item, index) in form.youtube" :key="index">
                    <td width="120">
                      <span v-if="onCheckEditMode(index)">Youtube Link</span>
                      <b-form-select
                        class="w-70px"
                        name
                        id
                        v-model="item.orderNum"
                        v-if="!onCheckEditMode(index)"
                        @change="(e) => onSwapIndex(e, index)"
                      >
                        <option
                          v-for="(orderList, orderIndex) in form.youtube.length"
                          :key="orderIndex"
                          :value="orderIndex"
                        >
                          {{ orderList }}
                        </option>
                      </b-form-select>
                    </td>
                    <td>
                      <span v-if="!onCheckEditMode(index)">{{ item.url }}</span>
                      <input
                        :value="item.url"
                        class="form-control"
                        placeholder="Youtube URL"
                        required
                        type="text"
                        v-on:keyup="(e) => onEditYoutubeUrl(e, index)"
                        v-if="onCheckEditMode(index)"
                      />
                    </td>
                    <td>
                      <b-button-group>
                        <b-btn
                          size="sm"
                          variant="primary"
                          class="mx-1"
                          v-if="onCheckEditMode(index)"
                          @click="saveEditMode(index)"
                        >
                          Save
                        </b-btn>
                        <b-btn
                          size="sm"
                          variant="outline-primary"
                          class="mx-1"
                          v-if="!onCheckEditMode(index)"
                          @click="openEditMode(index, item.url)"
                        >
                          Edit
                        </b-btn>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          v-if="!onCheckEditMode(index)"
                          @click="onDeleteYoutube(index)"
                          >Delete</b-button
                        >
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          class="btn-group-space"
                          v-if="onCheckEditMode(index)"
                          @click="saveEditMode(index, false)"
                          >Cancel</b-button
                        >
                      </b-button-group>
                    </td>
                  </tr>
                  <tr class="footer">
                    <td colspan="2">
                      <b-btn
                        size="sm"
                        variant="primary"
                        class="mx-1 add-u-btn"
                        @click="onAddYoutube"
                        >ADD</b-btn
                      >
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>

            <div v-if="form.type != 'Link_out'">
              <b-tabs content-class="mt-3">
                <b-tab
                  title="ภาษาไทย"
                  @click="tab = 'th'"
                  :active="tab === 'th'"
                >
                  <div class="flex-col" style="flex: 1">
                    <h6 class="page-title-bg">SEO</h6>
                  </div>
                  <b-form-group
                    style="font-weight: 600"
                    label="Meta tag keywords"
                  >
                    <b-form-input
                      v-model="form.seoMetaKeyword.th"
                      type="text"
                      placeholder="Input meta tag keywords"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    style="font-weight: 600"
                    label="Meta tag description"
                  >
                    <b-form-input
                      v-model="form.seoMetaDescription.th"
                      type="text"
                      placeholder="Input meta tag description"
                    ></b-form-input>
                  </b-form-group>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Header"
                      >
                        <b-form-textarea
                          v-model="form.seoTagInHeader.th"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Body"
                      >
                        <b-form-textarea
                          v-model="form.seoTagInBody.th"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="flex-col" style="flex: 1">
                    <h6 class="page-title-bg">Facebook</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Header"
                      >
                        <b-form-textarea
                          v-model="form.facebookTagInHeader.th"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Body"
                      >
                        <b-form-textarea
                          v-model="form.facebookTagInBody.th"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-tab>
                <b-tab
                  title="English"
                  @click="tab = 'en'"
                  :active="tab === 'en'"
                >
                  <div class="flex-col" style="flex: 1">
                    <h6 class="page-title-bg">SEO</h6>
                  </div>
                  <b-form-group
                    style="font-weight: 600"
                    label="Meta tag keywords"
                  >
                    <b-form-input
                      v-model="form.seoMetaKeyword.en"
                      type="text"
                      placeholder="Input meta tag keywords"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    style="font-weight: 600"
                    label="Meta tag description"
                  >
                    <b-form-input
                      v-model="form.seoMetaDescription.en"
                      type="text"
                      placeholder="Input meta tag description"
                    ></b-form-input>
                  </b-form-group>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Header"
                      >
                        <b-form-textarea
                          v-model="form.seoTagInHeader.en"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Body"
                      >
                        <b-form-textarea
                          v-model="form.seoTagInBody.en"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="flex-col" style="flex: 1">
                    <h6 class="page-title-bg">Facebook</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Header"
                      >
                        <b-form-textarea
                          v-model="form.facebookTagInHeader.en"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        style="font-weight: 600"
                        label="Tag in Body"
                      >
                        <b-form-textarea
                          v-model="form.facebookTagInBody.en"
                          placeholder="HTML/Javascript code"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>

            <div>
              <b-btn size="sm" variant="primary" class="mx-1" type="submit"
                >Save</b-btn
              >
            </div>
          </div>
        </b-card>
        <br />
      </form>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '@/components/ImageUploadNew'
import FileUploadMulti from '@/components/FileUploadMulti'
import TextEditor from '@/components/text-editor/TextEditor'

export default {
  data: () => ({
    tab: 'th',
    value: null,
    optionArticleType: [
      {
        text: 'Link out content',
        value: 'Link_out'
      },
      {
        text: 'Internal content',
        value: 'Internal'
      }
    ],
    urlVerify: {
      th: false,
      en: false
    },
    editModeList: [],
    galleryCurrent: [],
    projectOption: [],
    articleGallery: [],
    form: {
      projectId: [],
      type: '',
      externalLink: '',
      thumb: [],
      url: {
        en: '',
        th: ''
      },
      fullUrl: {
        th: '',
        en: ''
      },
      title: {
        en: '',
        th: ''
      },
      content: {
        en: '',
        th: ''
      },
      gallery: [],
      youtube: [],
      showDate: '',
      seoMetaKeyword: {
        th: '',
        en: ''
      },
      seoMetaDescription: {
        th: '',
        en: ''
      },
      seoTagInHeader: {
        th: '',
        en: ''
      },
      seoTagInBody: {
        th: '',
        en: ''
      },
      facebookTagInHeader: {
        th: '',
        en: ''
      },
      facebookTagInBody: {
        th: '',
        en: ''
      },
      startDate: null,
      endDate: null,
      status: 'Inactive'
    },
    uploadSetting: {
      showPreview: true,
      showDelete: true,
      showAlt: false
    },
    uploadSettingGellery: {
      showPreview: true,
      showDelete: true,
      showAlt: true
    },
    articleThumb: {
      source: '',
      name: ''
    },
    uploadThumbnailError: false
  }),
  components: {
    ImageUploadNew,
    TextEditor,
    FileUploadMulti
  },
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.REGISTERSETTING.PROJECTLIST,
      getInfo: ActionFetch.PROJECT_ARTICLE.DETAIL,
      createArticle: ActionFetch.PROJECT_ARTICLE.CREATE,
      updateArticle: ActionFetch.PROJECT_ARTICLE.UPDATE
    }),
    renderDate ($date) {
      return format($date, 'YYYY-MM-DD')
    },
    getDefaultUrl ($lang) {
      return `${process.env.VUE_APP_DOMAIN}/${$lang}/article`
    },
    onUpdateExternalURL ($value) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      if (pattern.test($value)) {
      } else {
        this.form.url.th = ''
        this.form.url.en = ''
        this.$bvToast.toast(`Invalid`, {
          title: 'URL link invalid format',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }
    },
    onSelectImage (id, url) {
      this.form[id] = url
    },
    copy (url, target, $lang) {
      const inputDestination = document.createElement('input')

      inputDestination.value = `${this.getDefaultUrl($lang)}/${url}/${
        this.form.id
      }`

      document.getElementById(target).appendChild(inputDestination)
      inputDestination.focus()
      inputDestination.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.getElementById(target).innerHTML = ''
      }, 1000)
    },
    onUploaded ($data) {
      let articleUploadList = $data
        .filter(($upload) => {
          const imgGallery = this.galleryCurrent.find(($gallery) => {
            return $gallery.source === $upload.source
          })
          return !imgGallery
        })
        .map(($upload, $index) => {
          return { ...$upload, orderNum: $index + this.galleryCurrent.length }
        })

      setTimeout(() => {
        this.articleGallery = []
      }, 1000)

      this.galleryCurrent = this.galleryCurrent.concat(articleUploadList)
    },
    onUploadGallery ($event, $img, $index) {
      if ($event.source === '') {
        this.galleryCurrent.splice($index, 1)
        this.galleryCurrent = this.galleryCurrent.map(($gallery, $index) => {
          $gallery.orderNum = $index
          return $gallery
        })
      } else {
        $img.source = $event.source
        $img.name = $event.name
      }
    },
    saveEditMode ($index, $isSave = true) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
      const indexEditMode = this.editModeList.findIndex(($data) => {
        return $data.listIndex === $index
      })

      if (indexEditMode >= 0) {
        var match = this.editModeList[indexEditMode].data.match(regExp)

        if ((match && match[2].length === 11) || !$isSave) {
          if ($isSave) {
            this.form.youtube[$index].url = this.editModeList[indexEditMode].data
          }

          this.editModeList.splice(indexEditMode, 1)

          if (!this.form.youtube[$index].url) {
            this.form.youtube.splice($index, 1)
          }
        } else {
          this.$bvToast.toast(`Invalid`, {
            title: 'Youtube link invalid format',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        }
      }
    },
    onAddYoutube () {
      this.editModeList.push({
        listIndex: this.form.youtube.length,
        data: ''
      })

      this.form.youtube.push({
        name: '',
        url: '',
        status: 'Active',
        orderNum: this.form.youtube.length
      })
    },
    onEditYoutubeUrl ($event, $index) {
      let indexEditMode = this.editModeList.findIndex(($data) => {
        return $data.listIndex === $index
      })

      if (indexEditMode >= 0) {
        this.editModeList[indexEditMode].data = $event.target.value
      }
    },
    onUpdateYoutube ($event, $index) {
      this.form.youtube[$index].url = $event.target.value
    },
    openEditMode ($index, $data) {
      this.editModeList.push({
        listIndex: $index,
        data: $data
      })
    },
    onSwapImg ($nextIndex, $index) {
      let tempDataIndex = {
        source: this.galleryCurrent[$index].source,
        name: this.galleryCurrent[$index].name
      }

      this.galleryCurrent[$index].source = this.galleryCurrent[$nextIndex].source
      this.galleryCurrent[$index].name = this.galleryCurrent[$nextIndex].name
      this.galleryCurrent[$nextIndex].source = tempDataIndex.source
      this.galleryCurrent[$nextIndex].name = tempDataIndex.name
      this.galleryCurrent[$index].orderNum = $index
    },
    onDeleteYoutube ($index) {
      this.form.youtube.splice($index, 1)
      this.form.youtube = this.form.youtube.map(($youtube, $index) => {
        $youtube.orderNum = $index
        return $youtube
      })
    },
    onCheckEditMode ($index) {
      let editModeIndex = this.editModeList.find(($data) => {
        return $data.listIndex === $index
      })

      return !!editModeIndex
    },
    onSwapIndex ($nextIndex, $index) {
      let tempUrlIndex = this.form.youtube[$index].url

      this.form.youtube[$index].url = this.form.youtube[$nextIndex].url
      this.form.youtube[$nextIndex].url = tempUrlIndex
      this.form.youtube[$index].orderNum = $index
    },
    onThumbnailUploaded ($data) {
      this.articleThumb.source = $data.source
    },
    onUpdateContentTH ($data) {
      this.form.content.th = $data
    },
    onUpdateContentEN ($data) {
      this.form.content.en = $data
    },
    fetchProjectList () {
      this.getProjectList().then(($res) => {
        if ($res.success) {
          this.projectOption = $res.data.map(($data) => {
            return {
              id: $data.id,
              name: $data.name.th,
              projectName: {
                th: $data.name.th,
                en: $data.name.en
              }
            }
          })
        }
      })
    },
    onUpdatePageUrl ($data, $lang) {
      let regCharactor = /^[a-zก-๙0-9-]+$/

      if (this.form.type === 'Internal' && !!$data.target.value) {
        this.form.url[$lang] = $data.target.value
          .replace(/ /g, '-')
          .toLowerCase()
        this.urlVerify[$lang] = !regCharactor.test(this.form.url[$lang])
      }
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (this.articleThumb.source) {
        this.uploadThumbnailError = false
        this.form.thumb[0] = {
          filename: this.articleThumb.source,
          alt: this.articleThumb.name
        }
      } else {
        this.uploadThumbnailError = true
      }

      this.form.gallery = this.galleryCurrent.map(($gallery) => {
        return {
          filename: $gallery.source,
          alt: $gallery.name
        }
      })

      const formSave = { ...this.form }

      if (!!this.form.startDate && !!this.form.endDate) {
        formSave.status = 'Inactive'
      }

      formSave.showDate = this.form.showDate
        ? format(this.form.showDate, 'YYYY-MM-DD')
        : null
      formSave.startDate = this.form.startDate
        ? format(this.form.startDate, 'YYYY-MM-DD')
        : null
      formSave.endDate = this.form.endDate
        ? format(this.form.endDate, 'YYYY-MM-DD')
        : null
      let canSave = false

      if (!this.form.startDate && this.form.endDate) {
        this.$bvToast.toast(`Please input start date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.endDate && this.form.startDate) {
        this.$bvToast.toast(`Please input end date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (this.uploadThumbnailError) {
        this.$bvToast.toast(`Please upload thumbnail`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!formSave.showDate) {
        this.$bvToast.toast(`Please input post date`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (this.urlVerify.th) {
        this.$bvToast.toast(`Invalid url thai`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (this.urlVerify.en) {
        this.$bvToast.toast(`Invalid url english`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!formSave.title.th && !formSave.title.en) {
        this.$bvToast.toast(`Please input title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (formSave.type === 'Internal') {
        if (!formSave.content.th) {
          this.$bvToast.toast(`Please input thai content`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!formSave.title.th) {
          this.$bvToast.toast(`Please input thai title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!formSave.url.th) {
          this.$bvToast.toast(`Please input thai url`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!formSave.title.en) {
          this.$bvToast.toast(`Please input english title`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!formSave.url.en) {
          this.$bvToast.toast(`Please input english url`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else if (!formSave.content.en) {
          this.$bvToast.toast(`Please input english content`, {
            title: 'Form Invalid',
            autoHideDelay: 2000,
            appendToast: true,
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            solid: true
          })
        } else {
          canSave = true
        }
      } else if (formSave.type === 'Link_out' && !formSave.url.th) {
        this.$bvToast.toast(`Please input external url`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        canSave = true
      }

      if (canSave) {
        if (formSave.type === 'Link_out') {
          formSave.url.en = formSave.url.th
          formSave.externalLink = formSave.url.th
        }

        if (!this.form.id) {
          this.createArticle(formSave).then(($res) => {
            if ($res.success) {
              this.form.id = $res.data.id
              formSave.id = $res.data.id

              let formUpdate = formSave
              if (formUpdate.type === 'Internal') {
                if (formUpdate.content.th) {
                  formUpdate.fullUrl.th = `${this.getDefaultUrl('th')}/${
                    formUpdate.url.th
                  }/${$res.data.id}`
                } else {
                  formUpdate.fullUrl.th = null
                }

                if (formUpdate.content.en) {
                  formUpdate.fullUrl.en = `${this.getDefaultUrl('en')}/${
                    formUpdate.url.en
                  }/${$res.data.id}`
                } else {
                  formUpdate.fullUrl.en = null
                }
              }

              this.updateArticle(formUpdate).then(($resUpdate) => {
                if ($resUpdate.success) {
                  this.$bvToast.toast(`Create article successful`, {
                    title: 'News created',
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right',
                    solid: true
                  })

                  setTimeout(() => {
                    this.$router.push(`/newsandarticles/articles/`)
                  }, 1000)
                }
              })
            }
          })
        } else {
          delete formSave.prev
          delete formSave.next

          if (formSave.type === 'Internal') {
            if (!formSave.fullUrl) {
              formSave.fullUrl = {
                th: null,
                en: null
              }
            }

            if (formSave.content.th) {
              formSave.fullUrl.th = `${this.getDefaultUrl('th')}/${
                formSave.url.th
              }/${formSave.id}`
            } else {
              formSave.fullUrl.th = null
            }

            if (formSave.content.en) {
              formSave.fullUrl.en = `${this.getDefaultUrl('en')}/${
                formSave.url.en
              }/${formSave.id}`
            } else {
              formSave.fullUrl.en = null
            }
          } else {
            formSave.fullUrl = {
              th: null,
              en: null
            }
          }

          this.updateArticle(formSave).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Update news successful`, {
                title: 'News updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.$router.push(`/newsandarticles/articles/`)
              }, 1000)
            }
          })
        }
      }
    },
    loadArticle () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(params).then(($res) => {
          if ($res.success) {
            this.form = { ...$res.data }

            if (!$res.data.fullUrl) {
              this.form.fullUrl = {
                th: '',
                en: ''
              }
            }

            if ($res.data.thumb.length > 0) {
              this.articleThumb.source = $res.data.thumb[0].filename
              this.articleThumb.name = $res.data.thumb[0].alt
            }

            this.galleryCurrent = $res.data.gallery.map(($gallery, $index) => {
              return {
                source: $gallery.filename,
                name: $gallery.alt,
                orderNum: $index
              }
            })
          }
        })
      }
    }
  },
  created () {
    this.fetchProjectList()
    this.loadArticle()
  },
  watch: {
    'form.sub_domain': function (val) {
      let newVal = val.toLowerCase()
      newVal = newVal.replace(/ /g, '-')
      this.form.sub_domain = newVal
    }
  }
}
</script>
<style>
.text {
  margin-left: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.tab {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}
.checkbox-wrapper {
  flex: 1;
}
.thumbnail-picker-wrapper {
  margin-right: 30px;
}
.content {
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}
.thumb-item {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}
.form-group {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.inline-zone {
  min-width: 25%;
}
.maxWidth {
  max-width: 250px;
  min-width: 120px;
}
.orderBox {
  width: 80px;
}
.hidden {
  opacity: 0;
}

.page-title-bg {
  background: #f1f0f0;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>

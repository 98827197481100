<template>
  <div class="p-3">
    <b-form-group label="App ID" class="mb-4">
      <b-form-input v-model="form.apiKey" required placeholder="Input App ID" />
    </b-form-group>

    <b-form-group label="Facebook Picture (1600 x 1040 pixel)">
      <div class="facebook-cover">
        <ImageUploadNew
          :dataImage="{ ...imageList }"
          :uploadSetting="{ ...uploadSetting }"
          :isRequired="false"
          :maxFile="1"
          :onFileUploaded="uploadFile"
        />
      </div>
    </b-form-group>
    <b-tabs class="mt-3">
      <b-tab key="0" title="ภาษาไทย">
        <b-form-group label="og:url">
          <b-form-input
            v-model="form.url.th"
            required
            placeholder="Input og url"
          />
        </b-form-group>
        <b-form-group label="og:title">
          <b-form-input
            v-model="form.metaKeyword.th"
            required
            placeholder="Input og title"
          />
        </b-form-group>
        <b-form-group label="og:description">
          <b-form-input
            v-model="form.metaDescription.th"
            required
            placeholder="Input og description"
          />
        </b-form-group>
        <b-form-group label="Tag In Header">
          <b-form-textarea
            v-model="form.tagInHeader.th"
            required
            placeholder="HTML/Javascript code"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
        <b-form-group label="Tag In Body">
          <b-form-textarea
            v-model="form.tagInBody.th"
            required
            placeholder="HTML/Javascript code"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-tab>
      <b-tab key="1" title="English">
        <b-form-group label="og:url">
          <b-form-input
            v-model="form.url.en"
            required
            placeholder="Input og url"
          />
        </b-form-group>
        <b-form-group label="og:title">
          <b-form-input
            v-model="form.metaKeyword.en"
            required
            placeholder="Input og title"
          />
        </b-form-group>
        <b-form-group label="og:description">
          <b-form-input
            v-model="form.metaDescription.en"
            required
            placeholder="Input og description"
          />
        </b-form-group>
        <b-form-group label="Tag In Header">
          <b-form-textarea
            v-model="form.tagInHeader.en"
            required
            placeholder="HTML/Javascript code"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
        <b-form-group label="Tag In Body">
          <b-form-textarea
            v-model="form.tagInBody.en"
            required
            placeholder="HTML/Javascript code"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>
    <br />
    <label class="label">*Basic Facebook Config for all pages in Website</label>
    <br />
    <b-button variant="primary" v-on:click="update">Update</b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'
import ImageUploadNew from '../ImageUploadNew'

export default {
  components: {
    ImageUploadNew
  },
  data: () => ({
    form: {
      email: ''
    },
    imageList: {},
    uploadSetting: {
      showPreview: false,
      showDelete: true,
      showAlt: false
    }
  }),
  methods: {
    ...mapActions({
      getConfig: ActionFetch.SEO_FACEBOOK.GET_CONFIG,
      saveConfig: ActionFetch.SEO_FACEBOOK.SAVE_CONFIG,
      upload: ActionFetch.SEO_FACEBOOK.UPLOAD
    }),
    loadConfig () {
      this.getConfig().then(($res) => {
        if ($res.success) {
          this.form = $res.data
          this.imageList = {}

          if ($res.data && $res.data.pictures.filename) {
            this.imageList = {
              source: $res.data.pictures.filename,
              name: ''
            }
          } else {
            this.imageList = {
              source: '',
              name: ''
            }
          }
        }
      })
    },
    uploadFile ($value) {
      this.form.pictures = {
        filename: $value.source,
        alt: '',
        status: true
      }
    },
    update () {
      this.saveConfig(this.form).then(($res) => {
        if ($res.success) {
          this.loadConfig()
        }
      })
    }
  },
  created () {
    this.loadConfig()
  }
}
</script>

<template>
  <div>
    <div class="table-responsive">
      <div class="text-left"></div>
      <div class="text-right mb-3">
        <h5 style="display: inline; float: left">
          Setting Location - Subcategory
        </h5>
        <b-button
          size="md"
          v-b-modal.edit-advance-search
          variant="primary"
          v-on:click="newList"
          >Add New Item</b-button
        >
      </div>
      <table class="table">
        <thead>
          <tr>
            <th width="80">Sort</th>
            <th>EN Name/TH Name</th>
            <th>Last Update</th>
            <th>Published</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list in items()" :key="list.id">
            <td>
              <b-form-select
                :value="list.orderNum"
                :sync="true"
                v-on:change="updateSorting($event, list)"
              >
                <option
                  v-for="(ls, index) in orderList"
                  :value="ls"
                  :key="index + 1"
                >
                  {{ index + 1 }}
                </option>
              </b-form-select>
            </td>
            <td>{{ list.title.en }} / {{ list.title.th }}</td>
            <td>{{ renderDate(list.updatedAt) }}</td>

            <td>
              <toggle-button
                v-model="list.status"
                :sync="true"
                :labels="true"
                v-on:change="saveLocation(list)"
              />
            </td>

            <td>
              <b-button-group>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="btn-group-space"
                  @click="edit(list)"
                  >Edit</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-group-space"
                  v-on:click="DeleteUser(list)"
                  >Delete</b-button
                >
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="table-pagination">
      <b-pagination
        v-model="paginate.page"
        :total-rows="paginate.totalRow"
        :per-page="paginate.limit"
        v-on:change="onPaginateChange"
      ></b-pagination>
      <b-form-select
        class="col-2 mx-2"
        v-model="paginate.limit"
        :options="perPageOptions"
        v-on:change="onLimitChange"
      ></b-form-select>
    </div>

    <EditAdvanceSearchModal
      :data="editData"
      :onSave="saveLocation"
      :title="'Setting Location'"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'

import EditAdvanceSearchModal from '../modals/EditAdvanceSearch.modal'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    paginate: Object,
    onCreateLocation: Function,
    onUpdateLocation: Function,
    onDeleteLocation: Function,
    onPaginateChange: Function,
    onLimitChange: Function,
    onUpdateSortLocation: Function
  },
  components: {
    EditAdvanceSearchModal
  },
  data: () => ({
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    editData: null,
    orderList: []
  }),
  methods: {
    edit (data) {
      this.editData = Object.assign({}, data)
      this.$bvModal.show('edit-advance-search')
    },
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    items () {
      return this.data
    },
    DeleteUser ($data) {
      if (confirm('Are you sure?')) {
        this.onDeleteLocation($data.id)
      }
    },
    newList () {
      this.edit({
        title: {
          th: '',
          en: ''
        },
        status: false,
        visitor: '127.0.0.1',
        createdBy: 1,
        updatedAt: 1
      })
    },
    saveLocation ($data) {
      if (!$data.id) {
        this.onCreateLocation($data)
      } else {
        this.onUpdateLocation($data)
      }
    },
    updateSorting ($newVal, $data) {
      let dataSort = {
        id: $data.id,
        orderNew: $newVal,
        orderOld: $data.orderNum
      }

      this.onUpdateSortLocation(dataSort)
    }
  },
  watch: {
    'paginate.totalRow': function (newVal, oldVal) {
      this.orderList = []
      for (let ls = 1; ls <= newVal; ls++) {
        this.orderList.push(ls)
      }
    }
  },
  computed: {
    rows () {
      return this.data.length
    }
  }
}
</script>

<style>
.yellobutton {
  background-color: rgb(0, 204, 0);
  border: 1px solid rgb(0, 204, 0);
}
.btn-group-space.yellobutton:hover {
  background-color: rgb(19, 173, 96);
  border: 1px solid rgb(19, 173, 96);
}
.redbutton {
  background-color: rgb(233, 0, 0);
  border: 1px solid rgb(233, 0, 0);
}
.redbutton:hover {
  background-color: rgb(165, 0, 0);
  border: 1px solid rgb(165, 0, 0);
}
.banner {
  max-width: 200px;
  max-height: 50px;
}
.start_stop_date {
  font-size: 12px;
  margin: 0px;
  color: #000;
}
td {
  vertical-align: middle !important;
  font-size: 14px;
}
#table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-group-space {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
}

/* th {
  text-align: center;
}
td {
  text-align: center;
} */
</style>

<template>
  <b-modal id="edit-color-line" size="lg" centered title="Add New List">
    <form id="edit-color-line-form" @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="TH Name">
            <b-form-input v-model="form.title.th"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="EN Name">
            <b-form-input v-model="form.title.en"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Type">
            <b-form-select v-model="form.type">
              <option class="d-block" value="bts">BTS / บีทีเอส</option>
              <option class="d-block" value="mrt">MRT</option>
              <option class="d-block" value="airport">Airport Link</option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Color Line">
            <custom-color
              :color="form.color"
              :onUpdateColor="onUpdateColor"
            ></custom-color>
          </b-form-group>
        </div>
      </div>
    </form>
    <template slot="modal-footer">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="edit-color-line-form"
      >
        Save
      </b-button>
      <b-button size="sm" variant="primary" @click="onCloseCancel">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CustomColor from '@/components/CustomColorNew'

export default {
  props: {
    data: Object,
    onSave: Function,
    onRefresh: Function
  },
  components: {
    CustomColor
  },
  data: () => ({
    form: {
      title: {
        th: '',
        en: ''
      },
      color: '',
      status: 'Active',
      visitor: '127.0.0.1'
    },
    myInterval: null
  }),
  methods: {
    onUpdateColor ($data) {
      this.form.color = $data
    },
    onCloseCancel () {
      this.onRefresh()
      this.$bvModal.hide('edit-color-line')
    },
    onSetActive ($data) {
      this.form.status = $data.value ? 'Active' : 'Inactive'
    },
    onSubmit ($e) {
      $e.preventDefault()

      if (!this.form.title.th) {
        this.$bvToast.toast(`Please input thai title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.title.en) {
        this.$bvToast.toast(`Please input english title`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.color) {
        this.$bvToast.toast(`Please input color`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!this.form.type) {
        this.$bvToast.toast(`Please select type`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        this.onSave(this.form)
        this.$bvModal.hide('edit-color-line')
      }
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.originValue = Object.assign({}, newVal)
      this.form = Object.assign({}, newVal)
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

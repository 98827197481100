const MOCK_DATA = []

for (let i = 0; i <= 121; i++) {
  MOCK_DATA[i] = {
    no: i + 1,
    date: new Date(),
    name: 'John Doe',
    email: 'John Doe@mail.com',
    mobilePhone: '081-882-9293',
    project: 'Ant Man',
    utm: '/fb'
  }
}

const list = () => {
  return MOCK_DATA
}

export default {
  list
}

<template>
  <b-modal
    id="edit-transport-link"
    size="lg"
    centered
    title="Setting Transport Link"
  >
    <form id="edit-transport-link-form" @submit.prevent="submit">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="TH Name">
            <b-form-input
              required
              v-model="form.title.th"
              :state="!!form.title.th"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="EN Name">
            <b-form-input
              required
              v-model="form.title.en"
              :state="!!form.title.en"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Type">
            <b-form-select v-model="form.type" :state="!!form.type" required>
              <option class="d-block" value="bts">bts</option>
              <option class="d-block" value="mrt">mrt</option>
              <option class="d-block" value="airport">airport</option>
            </b-form-select>
          </b-form-group>
        </div>

        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Color Line">
            <b-form-select
              v-model="form.category"
              :state="!!form.category || form.type == 'airport'"
              :required="form.type != 'airport'"
            >
              <option
                v-for="(list, index) in getOptionCategoryList()"
                :key="index"
                :value="list.value"
                class="d-block"
              >
                {{ list.title.en }} / {{ list.title.th }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <b-form-group label="Published">
            <toggle-button :labels="true" v-model="form.status" :sync="true" />
          </b-form-group>
        </div>
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="edit-transport-link-form"
        >Save</b-button
      >
      <b-button size="sm" variant="primary" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    data: Object,
    onSave: Function
  },
  data: () => ({
    form: {
      registerOption: []
    },
    optionCategory: []
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.BTSCATEGORY.LIST
    }),
    submit (e) {
      e.preventDefault()
      this.onSave(this.form)
      this.$bvModal.hide('edit-transport-link')
    },
    fetchCategory () {
      this.getList().then(($res) => {
        this.optionCategory = $res.data.map(($data) => {
          return { value: $data.id, title: $data.title, type: $data.type }
        })
      })
    },
    getOptionCategoryList () {
      const result = this.optionCategory.filter(($data) => {
        return $data.type === this.form.type
      })

      return result
    }
  },
  created () {
    this.fetchCategory()
    this.form = {
      title: {
        th: '',
        en: ''
      },
      status: false,
      type: '',
      category: null
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      this.form = Object.assign({}, newVal)

      if (this.form.category && this.form.category.id) {
        this.form.category = this.form.category.id
      }
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

<template>
  <b-modal
    id="add-project"
    size="lg"
    centered
    no-close-on-backdrop
    @hide="hide"
    title="Add New Microsite Project"
  >
    <form id="add-project-form" @submit.prevent="submit">
      <b-form-group label="Project Category">
        <b-form-select
          :required="true"
          v-model="form.projectCategoryId"
          class="select"
        >
          <template v-slot:first>
            <option :value="null" disabled>
              -- Please select project category --
            </option>
          </template>
          <option
            v-for="(selectOption, indexOpt) in categories"
            :key="indexOpt"
            :value="selectOption.id"
          >
            {{ selectOption.title.en }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Thai project name">
        <b-form-input
          required
          v-model="form.name.th"
          type="text"
          placeholder="Thai project name"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="English project name">
        <b-form-input
          required
          v-model="form.name.en"
          type="text"
          placeholder="English project name"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Project URL" style="margin-bottom: 5px">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">{{ this.getEndpoint() }}</div>
          </div>
          <b-form-input
            required
            v-model="form.slug"
            type="text"
            placeholder="project url"
          ></b-form-input>
        </div>
      </b-form-group>
      <p id="sub-domain-result" v-if="form.slug && form.slug.length > 0">
        URL :
        <a :href="this.form.url" target="_blank">{{ this.form.url }}</a>
      </p>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="add-project-form"
        >Add New Project</b-button
      >
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    categories: Array,
    onSubmit: Function,
    hide: {
      type: Function,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => ({
        projectCategoryId: null,
        name: { th: '', en: '' },
        url: ''
      })
    }
  },
  data: () => ({
    url: '',
    domain: process.env.VUE_APP_DOMAIN,
    lang: process.env.VUE_APP_LANG
  }),
  methods: {
    submit (e) {
      e.preventDefault()
      const d = { ...this.form }
      Object.defineProperty(d, 'slug', {
        enumerable: false
      })
      this.onSubmit(d)
    },
    getEndpoint () {
      const category =
        this.categories &&
        this.categories.find((each) => each.id === this.form.projectCategoryId)
      const subDomain = category ? `${category.subDomain}` : ''
      return `${process.env.VUE_APP_DOMAIN}/${process.env.VUE_APP_LANG}/${subDomain}`
    },
    getURL () {
      const endpoint = this.getEndpoint()
      return `${endpoint}${this.url ? `/${this.url}` : ''}`
    }
  },
  watch: {
    'form.slug': function (val) {
      let newVal = val.toLowerCase()
      newVal = newVal.replace(/[^A-Za-z0-9-]+/g, '-')
      this.form.slug = newVal
      this.form.url = `${this.getEndpoint()}${newVal ? `-${newVal}` : ''}`
    },
    'form.projectCategoryId': function (val) {
      this.form.url = `${this.getEndpoint()}-${this.form.slug}`
    }
  }
}
</script>

<style scoped>
#sub-domain-result {
  font-size: 14px;
  color: #000;
}
</style>

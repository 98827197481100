<template>
  <div id="register-setting-page">
    <div class="page-name">
      <div class="page-title">Project</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="flex-row align-center justify-between">
          <div class="flex-col" style="flex: 1">
            <span class="table-title">Project List</span>
          </div>
          <b-button size="sm" @click="addNewProject" variant="primary">
            Add New Project
          </b-button>
        </div>
        <br />
        <data-table
          :data.sync="projects"
          :confirm.sync="confirm"
          :onFetchList="fetchProjectList"
        />
      </b-card>
      <br />
    </div>
    <AddProject
      :categories="categories"
      :form="form"
      :hide="onHideModal"
      :onSubmit="onCreateProject"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import DataTable from '@/components/tables/ProjectTable'
import AddProject from '@/components/modals/AddProject.modal'

const defaultForm = {
  projectCategoryId: null,
  name: { th: '', en: '' },
  url: '',
  slug: '',
  isUseTheme: 'Y',
  primaryColorTheme: '#ffffff',
  secondaryTheme: '#ffffff',
  subColorTheme1: '#ffffff',
  subColorTheme2: '#ffffff',
  subColorTheme3: '#ffffff',
  projectLogo: { source: null },
  projectThumb: { source: null },
  projectIcon1: { source: null, title: { th: '', en: '' } },
  projectIcon2: { source: null, title: { th: '', en: '' } },
  projectIcon3: { source: null, title: { th: '', en: '' } },
  status: 'InActive'
}
export default {
  components: {
    DataTable,
    AddProject
  },
  data: () => ({
    enable: true,
    projects: [],
    categories: null,
    limit: 0,
    form: JSON.parse(JSON.stringify(defaultForm)),
    confirm: {}
  }),
  methods: {
    ...mapActions({
      getProjectList: ActionFetch.PROJECTS.MAIN.INDEX,
      addProject: ActionFetch.PROJECTS.MAIN.ADD,
      updateProject: ActionFetch.PROJECTS.MAIN.UPDATE,
      getCategoryList: ActionFetch.PROJECTS.CATEGORIES.INDEX
    }),
    fetchProjectCategories () {
      const payload = {
        limit: this.limit
      }
      this.getCategoryList(payload).then(($res) => {
        if (this.$store.state.project && this.$store.state.project.categories) {
          this.subDomains = this.$store.state.project.categories
        }
      })
    },
    fetchProjectList () {
      const condition = {
        apiVersion: '/v2'
      }

      this.getProjectList(condition).then((resp) => {
        if (this.$store.state.projects && this.$store.state.projects.main) {
          this.projects = this.$store.state.projects.main
          this.prepareConfirm()
        }
      })
    },
    handleList () {
      if (this.$store.state.projects && this.$store.state.projects.main) {
        this.projects = this.$store.state.projects.main
        this.prepareConfirm()
      } else {
        this.fetchProjectList()
      }
    },
    addNewProject () {
      this.$bvModal.show('add-project')
    },
    prepareConfirm () {
      this.projects.forEach((each) => {
        this.confirm[each.id] = true
      })
    },
    onCreateProject (data) {
      const val = this.projects.find((each) => data.url === each.url)

      if (val) {
        this.$bvModal.msgBoxOk(`URL "${data.url}" is duplicated.`)
      } else {
        this.addProject(data).then((resp) => {
          if (resp.success) {
            this.fetchProjectList()
            this.form = JSON.parse(JSON.stringify(defaultForm))
            this.$bvModal.hide('add-project')
          }
        })
      }
    },
    onHideModal () {
      this.form = JSON.parse(JSON.stringify(defaultForm))
    }
  },
  created () {
    this.handleList()
    this.fetchProjectList()

    const payload = {
      limit: this.limit
    }
    this.getCategoryList(payload).then(($res) => {
      if (this.$store.state.project && this.$store.state.project.categories) {
        this.categories = this.$store.state.project.categories
      }
    })

    // if (this.$store.state.project && this.$store.state.project.categories) {
    //   this.categories = this.$store.state.project.categories
    // } else {
    //   this.getCategoryList(limit).then(resp => {
    //     if (
    //       this.$store.state.project &&
    //       this.$store.state.project.categories
    //     ) {
    //       this.categories = this.$store.state.project.categories
    //     }
    //   })
    // }
  },
  mounted () {
    this.fetchProjectCategories()
  }
}
</script>

export const getDefaultValue = (projectID) => ({
  projectId: projectID,
  title: {
    th: '',
    en: ''
  },
  content: {
    th: '',
    en: ''
  },
  designLayout: 1,
  pcImages: { source: null },
  mobileImages: { source: null },
  visitor: null,
  status: 'Active',
  orderNum: 0
})

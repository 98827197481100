<template>
  <b-modal id="add-blog" size="xl" centered title="Add/Edit Job List">
    <form id="add-blog-form" @submit.prevent="submit">
      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">
            Title
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>
      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">
            Number of Position
            <input type="number" class="form-control" />
          </div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">
            Join US (HR Link)
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">
            Work Place
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">Job Responsibility</div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <froala
            :tag="'textarea'"
            :config="editorConfig"
            v-model="form.detail"
          ></froala>
        </div>
      </div>
      <hr />

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">Basic Qualification</div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <froala
            :tag="'textarea'"
            :config="editorConfig"
            v-model="form.detail"
          ></froala>
        </div>
      </div>

      <hr />

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">Benefits</div>
        </div>
      </div>

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <froala
            :tag="'textarea'"
            :config="editorConfig"
            v-model="form.detail"
          ></froala>
        </div>
      </div>

      <hr />
      <br />

      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="form-group form-group-row">Job Status</div>
        </div>
      </div>
      <div class="row" style="text-align: left">
        <div class="col-md-12">
          <div class="flex-row align-center">
            <div class="checkbox-wrapper">
              <p-check
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                :checked="form.file_type === 'url'"
                style="margin-right: 20px"
                @change="(e) => swithType(e, 'url')"
              >
                <i slot="extra" class="icon fa fa-check"></i> URGENT
              </p-check>
            </div>
          </div>
          <br />
          <div class="flex-row align-center">
            <div class="checkbox-wrapper">
              <p-check
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                :checked="form.file_type === 'youtube'"
                style="margin-right: 20px"
                @change="(e) => swithType(e, 'youtube')"
              >
                <i slot="extra" class="icon fa fa-check"></i> Not urgent
              </p-check>
            </div>
          </div>

          <!-- <image-uploader size="lg" title="Picture (1920 x 800 Pixel)" :multiple="false"></image-uploader> -->
        </div>
      </div>
    </form>
    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button size="sm" variant="primary" type="submit" form="add-blog-form">
        Save
      </b-button>
      <b-button size="sm" variant="primary" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import froalaConfig from '@/services/froalaConfig'

export default {
  data: () => ({
    editorConfig: Object.assign({}, froalaConfig, window.editorConfig || {}),
    editor_mode: 'editor',
    form: {
      file_type: '',
      start_date: null,
      end_date: null,
      template: '1'
    }
  }),
  methods: {
    uploadTrigger () {
      this.$refs.fileIinput.click()
    },
    upload (e) {
      this.form.file = e.target.files[0]
    },
    submit (e) {
      e.preventDefault()
      this.$bvModal.hide('add-banner')
    },
    swithType (value, type) {
      /* eslint-disable camelcase */
      const {
        form: { file_type }
      } = this
      const inverseType = type === 'youtube' ? 'url' : 'youtube'
      this.form.file_type = file_type === type ? inverseType : type
      /* eslint-enable camelcase */
    }
  }
}
</script>

<style scoped>
.file-name {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 10px;
}
</style>
